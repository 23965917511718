import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon } from "@mui/material";
import CheckIcon from "../../resources/images/listing/check_icon.svg";

export default function BasicMenu({
  anchorEl,
  setAnchorEl,
  menuItems,
  selectedItems,
}: {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  menuItems: { title: string; onClick: () => void }[];
  selectedItems: string[];
}) {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "17.063px",
            bgcolor: "white",
          },
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={item.onClick}
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "12px",
                marginRight: "9px",
              },
              color: "#000",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "Lato",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "11.189px",
              letterSpacing: "-0.219px",
              py: "10px",
              mx: "6px",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "#E9E9E9",
              },
            }}
          >
            <ListItemIcon>
              {selectedItems.includes(item.title) && (
                <img src={CheckIcon} alt="check_icon" />
              )}
            </ListItemIcon>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
