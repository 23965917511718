import React, { useState } from "react";
import "./CreateEvent.css";
import { insightDivider } from "./Insights";
import InviteIcon from "../../resources/images/create_event/invite_icon.svg";
import ShareRedIcon from "../../resources/images/create_event/share_red_icon.svg";
import BlastIcon from "../../resources/images/create_event/blast_icon.svg";
import FbIcon from "../../resources/images/create_event/fb_icon.svg";
import TwIcon from "../../resources/images/create_event/tw_icon.svg";
import LiIcon from "../../resources/images/create_event/li_icon.svg";
import WaIcon from "../../resources/images/create_event/wa_icon.svg";
import MsgIcon from "../../resources/images/create_event/msg_icon.svg";
import MerIcon from "../../resources/images/create_event/mer_icon.svg";
import LocationIcon from "../../resources/images/create_event/location_icon.svg";
import ScanIcon from "../../resources/images/create_event/scan_icon.svg";
import PlusIcon from "../../resources/images/create_event/plus_icon.svg";
import ExpandIcon from "../../resources/images/create_event/expand_icon.svg";
import ArrowRightIcon from "../../resources/images/create_event/arrow_right_icon.svg";
import AmanProfile from "../../resources/images/create_event/aman_profile.jpg";
import EditIcon from "../../resources/images/create_event/edit_icon.svg";
import UKProfile from "../../resources/images/create_event/uk_profile.jpeg";
import AsthaProfile from "../../resources/images/create_event/astha_profile.svg";
import MaterialModal from "./MaterialModal";
// import CustomTagsInput from "./MultiInput";
import {
  CustomQuestionInput,
  CustomQuestionRequiredBlock,
  CustomQuestionTitle,
  ModalButtonPrimary,
  ModalTextPrimary,
  ModalTitle,
} from "./ModalComponent";
import { ModalIcon } from "./ModalComponent";
import { UserIcon } from "./ModalComponent";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import CopyIcon from "../../resources/images/create_event/copy_icon.svg";
import ModalChevronIcon from "../../resources/images/create_event/modal_chevron_icon.svg";
import AddHostIcon from "../../resources/images/create_event/add_host_icon_1.svg";
import CheckboxCheckedIconBlack from "../../resources/images/eventv2/checkbox_checked_icon_black.svg";
import { useMediaQuery } from "@mui/material";

// import GuestListIcon from "../../resources/images/create_event/guest_list_icon.svg";

export default function Guests() {
  const isLaptop = useMediaQuery("(min-width: 600px)");
  const [hostModalOpen, setHostModalOpen] = useState(false);
  const guestOptions = () => {
    const opts = [
      {
        title: "Invite Guests",
        icon: InviteIcon,
        background: "#E2E7FE",
        onClick: () => {},
      },
      {
        title: "Send an Echo",
        icon: BlastIcon,
        background: "#F2E5FA",
        onClick: () => {},
      },
      {
        title: "Share Event",
        icon: ShareRedIcon,
        background: "#FEE1EC",
        onClick: () => {
          // setGuestListModalOpen(true);
        },
      },
    ];
    return (
      <div className="guest_options_container">
        {opts.map((opt, index) => (
          <div className="guest_options_buttons" onClick={opt.onClick}>
            <div
              className="guest_options_buttons_icon_container"
              style={{ background: opt.background }}
            >
              <img src={opt.icon} alt="invite_icon" />
            </div>
            <p className="guest_options_buttons_text">{opt.title}</p>
          </div>
        ))}
      </div>
    );
  };
  const topSection = () => {
    return (
      <div className="guests_top_section">
        {guestOptions()}
        <div className="event_preview">
          <div className="event_preview_container" style={{ display: "none" }}>
            <div className="event_copy_container">
              <p className="event_copy_link">thrivia.io/duhu38n</p>
              <p className="event_copy_link">COPY</p>
            </div>
          </div>
          <div className="event_details">
            <div className="event_share_container" style={{ display: "none" }}>
              <p className="event_share_text">Event Details</p>
              <div className="event_share_icon_container">
                <img src={FbIcon} alt="fb_icon" />
                <img src={TwIcon} alt="tw_icon" />
                <img src={LiIcon} alt="li_icon" />
                <img src={WaIcon} alt="wa_icon" />
                <img src={MsgIcon} alt="msg_icon" />
                <img src={MerIcon} alt="mer_icon" />
              </div>
            </div>
            <div className="event_details_container">
              <p className="event_details_text">When and Where</p>
              <div className="event_details_container_inner">
                <div className="event_details_container_inner_left">
                  <p className="event_details_container_inner_left_text">FEB</p>
                  <p className="event_details_container_inner_left_text_primary">
                    15
                  </p>
                </div>
                <div className="event_details_container_inner_right">
                  <p className="event_details_container_inner_right_text_primary">
                    Saturday 15 Feb
                  </p>
                  <p className="event_details_container_inner_right_text">
                    19:30 - 18 Feb, 10:30 GMT+5:30
                  </p>
                </div>
              </div>
              <div className="event_details_container_inner">
                <div className="event_details_container_inner_left">
                  <img src={LocationIcon} alt="location_icon" />
                </div>
                <div className="event_details_container_inner_right">
                  <p className="event_details_container_inner_right_text_primary">
                    Spiti
                  </p>
                  <p className="event_details_container_inner_right_text">
                    Himachal Pradesh, India
                  </p>
                </div>
              </div>
              <p
                className="event_details_container_inner_right_text"
                style={{ fontWeight: 700, marginTop: 20 }}
              >
                The address is shown publicly on the event page.
              </p>
              <div style={{ marginTop: 18 }}>
                <button className="checkin_button">
                  <img src={ScanIcon} alt="scan_icon" />
                  <p className="checkin_button_text">Check In Guests</p>
                </button>
              </div>
              <div className="checkin_button_container">
                <button className="checkin_button">
                  <p className="checkin_button_text">Edit Event</p>
                </button>
                <button className="checkin_button">
                  <p className="checkin_button_text">Delete Event</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const invitations = () => {
    const recentGuests = [
      {
        name: "Utkarsh Koushik",
        email: "utkarshk@gmail.com",
        icon: UKProfile,
      },
      {
        name: "Astha Agarwal",
        email: "asthaagarwal@gmail.com",
        icon: AsthaProfile,
      },
    ];
    return (
      <div className="invitations_container">
        <div className="invitations_container_heading">
          <p className="invitations_container_heading_text">Invitations</p>
          <div className="tickets_container_header_button">
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">
              Invite Guests
            </p>
          </div>
        </div>
        <div className="invitations_details_container">
          <div>
            <p className="number_of_guests" style={{ marginLeft: 8 }}>
              2<span className="number_of_guests_subtext">/2</span>
            </p>
            <p className="number_of_guests_text">Invitation Accepted </p>
          </div>
          <div className="email_opened_container">
            <img
              src={ExpandIcon}
              alt="expand_icon"
              className="email_opened_icon"
            />
            <p className="email_opened_text">
              2 Email Opened <br />0 Declined
            </p>
          </div>
        </div>
        <div className="recent_guests_container">
          <div className="recent_guests_container_inner">
            <p className="recent_guests_container_text">RECENTLY ACCEPTED</p>
          </div>
          <div className="recent_guests_divider" />
          <div className="recent_guests_container_inner_2">
            {recentGuests.map((guest, index) => (
              <div
                className="recent_guests_container_inner_guest"
                key={index + "recent"}
              >
                <img
                  src={guest.icon}
                  alt="guest_icon"
                  className="recent_guests_container_inner_guest_icon"
                />
                <p
                  className="recent_guests_container_inner_guest_text"
                  style={{ flex: 1 }}
                >
                  {guest.name}
                </p>
                <p className="recent_guests_container_inner_guest_email">
                  {guest.email}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const guests = () => {
    const glance = () => {
      return (
        <div style={{ marginTop: 20 }}>
          <div className="glance_container_content">
            <div className="glance_container_content_item">
              <p
                className="glance_container_content_item_header"
                style={{ color: "#3CBD2D" }}
              >
                2{" "}
                <span
                  className="glance_container_content_item_header_secondary"
                  style={{ color: "#3CBD2D" }}
                >
                  guest
                </span>
              </p>
              <p className="glance_container_content_item_secondary">
                cap{" "}
                <span className="glance_container_content_item_secondary_secondary">
                  20
                </span>
              </p>
            </div>
            <div className="glance_container_content_line">
              <div
                className="glance_container_content_line_item"
                style={{
                  width: "calc(100/20 * 2%)",
                  backgroundColor: "#3CBD2D",
                }}
              ></div>
            </div>
            <div className="glance_container_going_text_container">
              <div
                className="glance_container_going_text_dot"
                style={{ backgroundColor: "#3CBD2D" }}
              />
              <p
                className="glance_container_going_text_primary"
                style={{ color: "#3CBD2D" }}
              >
                2 going
              </p>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div>
        <p className="invitations_container_heading_text">Guests</p>
        {glance()}
      </div>
    );
  };

  const recentRegistrations = () => {
    const guestListFilter = () => {
      const guests = [
        {
          name: "Utkarsh Koushik",
          registerTime: new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          }),
          status: "Going",
          email: "utkarshk@gmail.com",
          profilePic: UKProfile,
        },
        {
          name: "Astha Agarwal",
          registerTime: new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          }),
          status: "Going",
          email: "asthaagarwal@gmail.com",
          profilePic: AsthaProfile,
        },
      ];
      return (
        <div className="recent_registrations_container_inner">
          {guests.map((guest, index) => (
            <div className="guest_list_filter_item_container">
              <img
                src={guest.profilePic}
                alt="guest_profile_pic"
                style={{ borderRadius: "100%" }}
                className="guest_list_filter_item_container_profile_pic"
              />
              <div className="guest_list_filter_item_container_right">
                <div className="guest_list_filter_item_container_right_top">
                  <div>
                    <p className="guest_list_filter_item_container_right_top_name">
                      {guest.name}
                    </p>
                    <p className="guest_list_filter_item_container_right_email">
                      {guest.email}
                    </p>
                  </div>
                  <div className="guest_list_filter_item_container_right_top_right">
                    <p className="recent_registrations_container_inner_time">
                      {guest.registerTime}
                    </p>
                    <div className="guest_list_filter_item_container_right_top_status">
                      <p className="guest_list_filter_item_container_right_top_status_text">
                        {guest.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    };
    return (
      <div className="recent_registrations_container">
        <div className="invitations_container_heading">
          <p className="invitations_container_heading_text">
            Recent Registrations
          </p>
          <div className="tickets_container_header_button">
            <p className="tickets_container_header_button_text">All Guests</p>
            <img src={ArrowRightIcon} alt="arrow_right_icon" />
          </div>
        </div>
        {guestListFilter()}
      </div>
    );
  };

  const hostList = () => {
    const hosts = [
      {
        id: "host-1",
        name: "Aman Agarwal",
        type: "Creator",
        profilePic: AmanProfile,
      },
    ];
    return (
      <div className="host_list_container">
        <div className="invitations_container_heading">
          <p className="invitations_container_heading_text">Hosts</p>
          <div
            className="tickets_container_header_button"
            onClick={() => {
              setHostModalOpen(true);
            }}
          >
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">Add Host</p>
          </div>
        </div>
        <p className="host_list_container_text">
          Add hosts, special guests, and event managers.
        </p>
        <div className="host_list">
          {hosts.map((host, index) => (
            <div
              className="host_list_item"
              key={index + "host"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setUpdateHostModalOpen(true);
              }}
            >
              <img
                src={host.profilePic}
                alt="host_profile_pic"
                className="host_list_item_profile_pic"
              />
              <p className="host_list_item_name">{host.name}</p>
              <div className="guest_list_filter_item_container_right_top_status">
                <p className="guest_list_filter_item_container_right_top_status_text">
                  {host.type}
                </p>
              </div>
              <div className="host_list_item_edit_container">
                <img src={EditIcon} alt="edit_icon" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const [hostEmail, setHostEmail] = useState("");
  const [hostAddState, setHostAddState] = useState(false);
  const [shouldShowOnEventPage, setShouldShowOnEventPage] = useState(true);
  const [activeHostAccessControl, setActiveHostAccessControl] =
    useState("Manager");
  const [updateHostModalOpen, setUpdateHostModalOpen] = useState(false);
  const hostModal = () => {
    const addHostEmail = () => {
      return (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ModalIcon icon={CopyIcon} width={32} padding={12} />

            <ModalIcon
              icon={CrossModalIcon}
              width={20}
              padding={4}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setHostModalOpen(false);
              }}
            />
          </div>
          <ModalTitle title="Add Host" marginTop={12} />
          <ModalTextPrimary
            text="Add a host to highlight them on the event page or to get help managing the event."
            marginTop={8}
          />
          <CustomQuestionTitle title="Enter Email or Search" marginTop={12} />
          <CustomQuestionInput
            placeholder=""
            marginTop={6}
            props={{
              value: hostEmail,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setHostEmail(e.target.value),
            }}
          />
          {hostEmail !== "" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 12,
                  gap: 12,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setHostAddState(true);
                }}
              >
                <UserIcon />
                <div>
                  <ModalTextPrimary
                    text={hostEmail}
                    style={{ color: "#000" }}
                  />
                  <ModalTextPrimary
                    text="Add Host via email"
                    style={{ fontSize: 12, fontWeight: 400, marginTop: 4 }}
                  />
                </div>
              </div>
            </>
          )}
          {hostEmail === "" && (
            <>
              <div style={{ padding: "44px 32px" }}>
                <ModalTextPrimary
                  text="No Suggestions Found"
                  marginTop={0}
                  style={{
                    textAlign: "center",
                    color: "#1315175C",
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                />
                <ModalTextPrimary
                  text="You can invite hosts by entering their email address."
                  marginTop={4}
                  style={{
                    textAlign: "center",
                    color: "#1315175C",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                />
              </div>
            </>
          )}
        </div>
      );
    };

    const addHost = () => {
      return (
        <div>
          <div className="question_header_container">
            <ModalIcon
              icon={ModalChevronIcon}
              width={14}
              padding={7}
              onClick={() => setHostAddState(false)}
            />
            <ModalTitle title="Configure Host" />
            <ModalIcon
              icon={CrossModalIcon}
              width={24}
              padding={4}
              onClick={() => setHostModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: 6,
              alignItems: "center",
              marginTop: 16,
            }}
          >
            <UserIcon width={16} height={16} padding={4} />
            {/* <ModalTitle title={"Utkarsh"} /> */}
            <ModalTextPrimary text={hostEmail} />
          </div>
          <CustomQuestionRequiredBlock
            isRequired={shouldShowOnEventPage}
            setIsRequired={() => {
              setShouldShowOnEventPage(!shouldShowOnEventPage);
            }}
            marginTop={16}
            text="Show on Event Page"
          />
          <ModalTextPrimary text="Access Control" marginTop={16} />
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Manager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Manager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary text="Manager" style={{ color: "#000" }} />
                <ModalTextPrimary text="Manage the event and guests" />
              </div>
              {activeHostAccessControl === "Manager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Checkin"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Checkin");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Checkin Only"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="Allow guests to check in only" />
              </div>
              {activeHostAccessControl === "Checkin" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "nonManager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("nonManager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Non Manager"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="No access to manage events" />
              </div>
              {activeHostAccessControl === "nonManager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 12,
            }}
          >
            <ModalButtonPrimary
              title="Add Host"
              onClick={() => {
                setHostModalOpen(false);
              }}
              marginTop={16}
              styles={{
                flex: 1,
              }}
            />
            <ModalButtonPrimary
              title="Cancel"
              onClick={() => {
                setHostModalOpen(false);
              }}
              marginTop={16}
              styles={{
                border: "1px solid red",
                color: "red",
                background: "transparent",
                flex: 1,
              }}
            />
          </div>
        </div>
      );
    };
    return (
      <MaterialModal
        isOpen={hostModalOpen}
        onClose={() => {
          setHostModalOpen(false);
        }}
      >
        <div
          style={{
            padding: "16px 20px",
            width: isLaptop ? "auto" : "calc(100vw - 64px)",
            boxSizing: "border-box",
            background: "rgba(255, 255, 255, 0.867)",
          }}
        >
          {!hostAddState ? addHostEmail() : addHost()}
        </div>
      </MaterialModal>
    );
  };

  const updateHost = () => {
    const updateModal = () => {
      return (
        <div>
          <div className="question_header_container">
            {/* <ModalIcon
              icon={ModalChevronIcon}
              width={14}
              padding={7}
              onClick={() => setUpdateHostModalOpen(false)}
            /> */}
            <ModalTitle title="Configure Host" />
            <ModalIcon
              icon={CrossModalIcon}
              width={24}
              padding={4}
              onClick={() => setUpdateHostModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: 6,
              alignItems: "center",
              marginTop: 16,
            }}
          >
            <UserIcon width={16} height={16} padding={4} />
            {/* <ModalTitle title={"Utkarsh"} /> */}
            <ModalTextPrimary text={hostEmail || "amanagarwal@gmail.com"} />
          </div>
          <CustomQuestionRequiredBlock
            isRequired={shouldShowOnEventPage}
            setIsRequired={() => {
              setShouldShowOnEventPage(!shouldShowOnEventPage);
            }}
            marginTop={16}
            text="Show on Event Page"
          />
          <ModalTextPrimary text="Access Control" marginTop={16} />
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Manager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Manager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary text="Manager" style={{ color: "#000" }} />
                <ModalTextPrimary text="Manage the event and guests" />
              </div>
              {activeHostAccessControl === "Manager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Checkin"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Checkin");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Checkin Only"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="Allow guests to check in only" />
              </div>
              {activeHostAccessControl === "Checkin" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "nonManager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("nonManager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Non Manager"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="No access to manage events" />
              </div>
              {activeHostAccessControl === "nonManager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 12,
            }}
          >
            <ModalButtonPrimary
              title="Update Host"
              onClick={() => {
                setHostModalOpen(false);
              }}
              marginTop={16}
              styles={{
                flex: 1,
              }}
            />
            <ModalButtonPrimary
              title="Remove"
              onClick={() => {
                setHostModalOpen(false);
              }}
              marginTop={16}
              styles={{
                border: "1px solid red",
                color: "red",
                background: "transparent",
                flex: 1,
              }}
            />
          </div>
        </div>
      );
    };
    return (
      <MaterialModal
        isOpen={updateHostModalOpen}
        onClose={() => {
          setUpdateHostModalOpen(false);
        }}
      >
        <div
          style={{
            padding: "16px 20px",
            width: isLaptop ? "auto" : "calc(100vw - 64px)",
            boxSizing: "border-box",
            background: "rgba(255, 255, 255, 0.867)",
          }}
        >
          {updateModal()}
        </div>
      </MaterialModal>
    );
  };
  return (
    <div>
      {topSection()}
      {invitations()}
      {insightDivider(22, 22)}
      {guests()}
      {recentRegistrations()}
      {insightDivider(19, 23)}
      {hostList()}
      {hostModal()}
      {updateHost()}
      {/* {guestListModal()} */}
    </div>
  );
}
