import React, { useEffect, useRef, useState } from "react";
import "./InitialCreate.css";
import PlusIcon from "../../resources/images/create_event/plus_icon.svg";
import { dateComponent } from "../listing/EventCard";
// import DOMPurify from "dompurify";
import LocationIcon from "../../resources/images/create_event/location_icon.svg";
import EditIcon from "../../resources/images/create_event/edit_icon.svg";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { EventDetailsInterface } from "../../commons/Interfaces";
// import Placeholder from "../../resources/images/create_event/placeholder.jpg";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import MaterialModal from "./MaterialModal";
import Placeholder1 from "../../resources/images/create_event/placeholder_1.avif";
import Placeholder2 from "../../resources/images/create_event/placeholder_2.avif";
import Placeholder4 from "../../resources/images/create_event/placeholder_4.avif";

import {
  ModalTitle,
  CustomQuestionInput,
  ModalButtonPrimary,
  ModalIcon,
  ModalSelect,
  CustomQuestionTitle,
} from "./ModalComponent";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import MultiInput from "./MultiInput";
import { useMediaQuery } from "@mui/material";
interface ItineraryItem {
  photo: string;
  description: string;
  meal: string;
  date: Date;
  title: string;
}

interface IAccomodation {
  photos: string[];
  property_name: string;
  start_date: Date;
  end_date: Date;
  location: {
    city: string;
    address: string;
  };
}

interface IActivity {
  photo: string;
  title: string;
}

interface ITestimonial {
  name: string;
  description: string;
}

interface IAmenity {
  name: string;
  description: string[];
}

export default function InitialCreate({
  eventDetails,
}: {
  eventDetails: EventDetailsInterface | null;
}) {
  const isLaptop = useMediaQuery("(min-width: 600px)");
  const cardsRef = useRef<HTMLDivElement>(null);
  const [activeItnIndex, setActiveItnIndex] = useState(0);
  const [itinerary, setItinerary] = useState<ItineraryItem[]>([
    {
      photo: Placeholder2,
      description: "",
      meal: "",
      date: new Date(),
      title: "",
    },
  ]);

  const [openDatePicker, setOpenDatePicker] = useState<number | null>(null);

  const handleMealChange = (index: number, newMeal: string) => {
    const updatedItinerary = [...itinerary];
    updatedItinerary[index] = {
      ...updatedItinerary[index],
      meal: newMeal,
    };
    setItinerary(updatedItinerary);
  };

  const handleDescriptionChange = (index: number, newDescription: string) => {
    const updatedItinerary = [...itinerary];
    updatedItinerary[index] = {
      ...updatedItinerary[index],
      description: newDescription,
    };
    setItinerary(updatedItinerary);
  };

  const handleTitleChange = (index: number, newTitle: string) => {
    const updatedItinerary = [...itinerary];
    updatedItinerary[index] = {
      ...updatedItinerary[index],
      title: newTitle,
    };
    setItinerary(updatedItinerary);
  };

  const handleDateChange = (index: number, newDate: Date) => {
    const updatedItinerary = [...itinerary];
    updatedItinerary[index] = {
      ...updatedItinerary[index],
      date: newDate,
    };
    setItinerary(updatedItinerary);
  };

  const handlePhotoChange = (index: number, file: File) => {
    const updatedItinerary = [...itinerary];
    updatedItinerary[index] = {
      ...updatedItinerary[index],
      photo: URL.createObjectURL(file),
    };
    setItinerary(updatedItinerary);
  };

  const headingSection = (
    title: string,
    label: string,
    onClick: () => void
  ) => {
    return (
      <div className="initial_create_heading_container">
        <p className="initial_create_title">{title}</p>
        <div className="tickets_container_header_button" onClick={onClick}>
          <img src={PlusIcon} alt="plus_icon" />
          <p className="tickets_container_header_button_text">{label}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (cardsRef.current) {
        const scrollPosition = cardsRef.current.scrollLeft;
        const cardWidth = cardsRef.current.offsetWidth;
        const newIndex = Math.round(scrollPosition / cardWidth);
        setActiveItnIndex(newIndex);
      }
    };

    cardsRef.current?.addEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => cardsRef.current?.removeEventListener("scroll", handleScroll);
  }, []);
  const itinerarySection = () => {
    const scrollToCard = (index: number) => {
      if (cardsRef.current) {
        const cardWidth = cardsRef.current.offsetWidth;
        cardsRef.current.scrollTo({
          left: cardWidth * index,
          behavior: "smooth",
        });
        setActiveItnIndex(index);
      }
    };
    return (
      <div>
        {headingSection("Itinerary", "Add Day", () => {
          setItinerary([
            ...itinerary,
            {
              photo: Placeholder2,
              description: "",
              meal: "",
              date: new Date(),
              title: "",
            },
          ]);
          setTimeout(() => {
            if (cardsRef.current) {
              const cardWidth = cardsRef.current.offsetWidth;
              cardsRef.current.scrollTo({
                left: cardWidth * itinerary.length,
                behavior: "smooth",
              });
              setActiveItnIndex(itinerary.length);
            }
          }, 0);
        })}
        <div
          className="initial_create_itinerary_container"
          style={{
            display: "flex",
            overflow: "auto",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
          }}
          ref={cardsRef}
        >
          {itinerary.map((item, index) => {
            return (
              <div
                className="eventv3_itn_card_container"
                style={{
                  marginTop: 20,
                  minWidth: isLaptop ? "368px" : "calc(100vw - 48px)",
                  position: "static",
                  scrollSnapAlign: "center",
                  flexShrink: 0,
                }}
                key={index}
              >
                <div
                  style={{
                    backgroundImage: `url(${item.photo})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "20px 20px 0px 0px",
                    height: 335,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    console.log(e);
                    // Check if click originated from date picker section
                    if (!(e.target instanceof HTMLDivElement)) {
                      return;
                    }
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = "image/*";
                    input.onchange = (e) => {
                      const file = (e.target as HTMLInputElement).files?.[0];
                      if (file) {
                        handlePhotoChange(index, file);
                      }
                    };
                    input.click();
                  }}
                >
                  <div className="eventv3_itn_card_top_section">
                    <input
                      className="eventv3_itn_card_heading"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      value={item.title}
                      onChange={(e) => handleTitleChange(index, e.target.value)}
                      placeholder="Enter title"
                      style={{
                        border: "none",
                        background: "transparent",
                        width: "100%",
                        outline: "none",
                      }}
                    />
                    {eventDetails?.event_type === "Experience" && (
                      <div
                        className="eventv3_itn_card_date_picker"
                        style={{ cursor: "pointer" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            open={openDatePicker === index}
                            onClose={() => setOpenDatePicker(null)}
                            value={dayjs(item.date)}
                            onChange={(date: Dayjs | null) => {
                              handleDateChange(
                                index,
                                date?.toDate() || new Date()
                              );
                              setOpenDatePicker(null);
                            }}
                            slotProps={{
                              textField: { style: { display: "none" } },
                              popper: {
                                sx: {
                                  "& .MuiPaper-root": {
                                    borderRadius: "12px",
                                    boxShadow:
                                      "0px 4px 20px rgba(0, 0, 0, 0.1)",
                                  },
                                  "& .MuiPickersDay-root": {
                                    borderRadius: "8px",
                                    "&.Mui-selected": {
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      "&:hover": {
                                        backgroundColor: "#0056b3",
                                      },
                                    },
                                  },
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                    {eventDetails?.event_type === "Event" && (
                      <div
                        className="eventv3_itn_card_date_picker"
                        style={{ cursor: "pointer" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            open={openDatePicker === index}
                            onClose={() => setOpenDatePicker(null)}
                            value={dayjs(item.date)}
                            onChange={(date: Dayjs | null) => {
                              handleDateChange(
                                index,
                                date?.toDate() || new Date()
                              );
                              setOpenDatePicker(null);
                            }}
                            slotProps={{
                              textField: { style: { display: "none" } },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                    <div
                      className="eventv3_itn_card_date_picker"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenDatePicker(index);
                      }}
                    >
                      {dateComponent(
                        eventDetails?.event_type === "Event"
                          ? item.date
                              .toLocaleString("en-US", {
                                hour: "numeric",
                                hour12: true,
                              })
                              .split(" ")[1]
                          : item.date.toLocaleString("en-US", {
                              month: "short",
                            }),
                        eventDetails?.event_type === "Event"
                          ? item.date
                              .toLocaleString("en-US", {
                                hour: "numeric",
                                hour12: true,
                              })
                              .split(" ")[0]
                          : item.date.getDate().toString()
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="eventv3_itn_card_bottom_section"
                  style={{ alignItems: "flex-start", gap: 0 }}
                >
                  <p className="eventv3_itn_card_bottom_section_title">
                    Add Description...
                  </p>
                  <textarea
                    className="eventv3_itn_card_description"
                    value={item.description}
                    onChange={(e) =>
                      handleDescriptionChange(index, e.target.value)
                    }
                    placeholder="Arrive in Jaisalmer and settle into your stay. Visit the stunning Jaisalmer Fort and explore vibrant local markets. In the , enjoy a camel safari and watch a magical desert sunset. "
                    style={{
                      border: "none",
                      background: "transparent",
                      width: "100%",
                      outline: "none",
                      color: "rgb(0,0,0,0.8)",
                      padding: 0,
                    }}
                  />
                  <div className="eventv3_itn_card_bottom_section_bottom">
                    <div
                      className="eventv3_itn_card_bottom_section_bottom_left"
                      style={{ padding: 0 }}
                    >
                      {/* <img src={MealIcon} alt="meal_icon" /> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0,
                          marginTop: 12,
                        }}
                      >
                        <p className="eventv3_itn_card_bottom_section_title">
                          Add Meals
                        </p>
                        <input
                          className="eventv3_itn_card_description"
                          value={item.meal}
                          onChange={(e) =>
                            handleMealChange(index, e.target.value)
                          }
                          placeholder="Enter meal details"
                          style={{
                            border: "none",
                            background: "transparent",
                            width: "100%",
                            outline: "none",
                            color: "rgb(0,0,0,0.8)",
                            padding: 0,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="dot-navigation">
          {itinerary.length > 1 &&
            itinerary.map((_, index) => (
              <div
                key={index}
                className={`dot ${activeItnIndex === index ? "active" : ""}`}
                onClick={() => scrollToCard(index)}
              />
            ))}
        </div>
      </div>
    );
  };

  const [activeAccommodationIndex, setActiveAccommodationIndex] = useState(0);
  const accommodationCardsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (accommodationCardsRef.current) {
        const scrollPosition = accommodationCardsRef.current.scrollLeft;
        const cardWidth = accommodationCardsRef.current.offsetWidth;
        const newIndex = Math.round(scrollPosition / cardWidth);
        setActiveAccommodationIndex(newIndex);
      }
    };

    cardsRef.current?.addEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => cardsRef.current?.removeEventListener("scroll", handleScroll);
  }, []);

  const [accomodations, setAccomodations] = useState<IAccomodation[]>([
    {
      photos: [Placeholder1],
      property_name: "",
      start_date: new Date(),
      end_date: new Date(),
      location: {
        city: "",
        address: "",
      },
    },
  ]);

  const handlePropertyNameChange = (index: number, newName: string) => {
    const updatedAccomodations = [...accomodations];
    updatedAccomodations[index] = {
      ...updatedAccomodations[index],
      property_name: newName,
    };
    setAccomodations(updatedAccomodations);
  };

  const handleCityChange = (index: number, newCity: string) => {
    const updatedAccomodations = [...accomodations];
    updatedAccomodations[index] = {
      ...updatedAccomodations[index],
      location: {
        ...updatedAccomodations[index].location,
        city: newCity,
      },
    };
    setAccomodations(updatedAccomodations);
  };

  const handleAddressChange = (index: number, newAddress: string) => {
    const updatedAccomodations = [...accomodations];
    updatedAccomodations[index] = {
      ...updatedAccomodations[index],
      location: {
        ...updatedAccomodations[index].location,
        address: newAddress,
      },
    };
    setAccomodations(updatedAccomodations);
  };

  const handleDateRangeChange = (
    index: number,
    dates: [Date, Date] | null
  ): void => {
    if (dates) {
      const [startDate, endDate] = dates;
      const updatedAccomodations = [...accomodations];
      updatedAccomodations[index] = {
        ...updatedAccomodations[index],
        start_date: startDate,
        end_date: endDate,
      };
      setAccomodations(updatedAccomodations);
    }
  };

  // Add state for controlling date picker visibility
  const [openDateRangePicker, setOpenDateRangePicker] = useState<number | null>(
    null
  );

  const handleAccommodationPhotoChange = (index: number, file: File) => {
    const updatedAccommodations = [...accomodations];
    updatedAccommodations[index] = {
      ...updatedAccommodations[index],
      photos: [URL.createObjectURL(file)],
    };
    setAccomodations(updatedAccommodations);
  };

  const accomodationSection = () => {
    const scrollToCard = (index: number) => {
      if (accommodationCardsRef.current) {
        const cardWidth = accommodationCardsRef.current.offsetWidth;
        accommodationCardsRef.current.scrollTo({
          left: cardWidth * index,
          behavior: "smooth",
        });
        setActiveAccommodationIndex(index);
      }
    };

    return (
      <div className="eventv3_accomodation_container" style={{ marginTop: 24 }}>
        {headingSection("Accommodation", "Add", () => {
          setAccomodations([
            ...accomodations,
            {
              photos: [Placeholder1],
              property_name: "This is the property name",
              start_date: new Date(),
              end_date: new Date(),
              location: {
                city: "This is the city",
                address: "This is the address",
              },
            },
          ]);
          setTimeout(() => {
            if (accommodationCardsRef.current) {
              const cardWidth = accommodationCardsRef.current.offsetWidth;
              accommodationCardsRef.current.scrollTo({
                left: cardWidth * accomodations.length,
                behavior: "smooth",
              });
              setActiveAccommodationIndex(accomodations.length);
            }
          }, 0);
        })}
        <div
          className="eventv3_accomodation_container_cards"
          ref={accommodationCardsRef}
          style={{
            display: "flex",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {accomodations.map((item, index) => {
            return (
              <div className="eventv3_accomodation_container_card" key={index}>
                <img
                  src={item.photos[0]}
                  alt="accomodation_img"
                  style={{
                    width: "100%",
                    height: "260px",
                    objectFit: "cover",
                    borderRadius: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = "image/*";
                    input.onchange = (e) => {
                      const file = (e.target as HTMLInputElement).files?.[0];
                      if (file) {
                        handleAccommodationPhotoChange(index, file);
                      }
                    };
                    input.click();
                  }}
                />
                <div className="event_details_container">
                  <input
                    className="event_details_text"
                    value={item.property_name}
                    onChange={(e) =>
                      handlePropertyNameChange(index, e.target.value)
                    }
                    placeholder="Enter property name"
                    style={{
                      border: "none",
                      background: "transparent",
                      width: "100%",
                      outline: "none",
                    }}
                  />
                  <div className="event_details_container_inner">
                    <div
                      style={{
                        border: "1px solid #ededed",
                        borderRadius: "10px",
                      }}
                    >
                      {dateComponent(
                        item.start_date.toLocaleDateString("en-US", {
                          month: "short",
                        }),
                        item.start_date.toLocaleDateString("en-US", {
                          day: "numeric",
                        })
                      )}
                    </div>
                    <div
                      className="event_details_container_inner_right"
                      onClick={() => setOpenDateRangePicker(index)}
                      style={{ cursor: "pointer", position: "relative" }}
                    >
                      <p className="event_details_container_inner_right_text_primary">
                        {item.start_date.toLocaleDateString("en-US", {
                          weekday: "long",
                          month: "short",
                          day: "numeric",
                        })}
                      </p>
                      <p className="event_details_container_inner_right_text">
                        {`${item.start_date.toLocaleDateString()} - ${item.end_date.toLocaleDateString()}`}
                      </p>
                      {openDateRangePicker === index && (
                        <MaterialModal
                          isOpen={openDateRangePicker === index}
                          onClose={() => setOpenDateRangePicker(null)}
                        >
                          <DateRange
                            ranges={[
                              {
                                startDate: item.start_date,
                                endDate: item.end_date,
                                key: "selection",
                              },
                            ]}
                            onChange={(ranges) => {
                              const range = ranges.selection;
                              if (range.startDate && range.endDate) {
                                handleDateRangeChange(index, [
                                  range.startDate,
                                  range.endDate,
                                ]);
                              }
                              setOpenDateRangePicker(null);
                            }}
                          />
                        </MaterialModal>
                      )}
                    </div>
                  </div>
                  <div className="event_details_container_inner">
                    <div className="event_details_container_inner_left">
                      <img src={LocationIcon} alt="location_icon" />
                    </div>
                    <div className="event_details_container_inner_right">
                      <input
                        className="event_details_container_inner_right_text_primary"
                        value={item.location.city}
                        onChange={(e) =>
                          handleCityChange(index, e.target.value)
                        }
                        placeholder="Enter city"
                        style={{
                          border: "none",
                          background: "transparent",
                          width: "100%",
                          outline: "none",
                        }}
                      />
                      <input
                        className="event_details_container_inner_right_text"
                        value={item.location.address}
                        onChange={(e) =>
                          handleAddressChange(index, e.target.value)
                        }
                        placeholder="Enter address"
                        style={{
                          border: "none",
                          background: "transparent",
                          width: "100%",
                          outline: "none",
                        }}
                      />
                    </div>
                  </div>
                  {/* <div style={{ marginTop: 18 }}>
                    <button className="checkin_button">
                      <img src={ScanIcon} alt="scan_icon" />
                      <p className="checkin_button_text">Check Property</p>
                    </button>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="dot-navigation">
          {accomodations.length > 1 &&
            accomodations.map((_, index) => (
              <div
                key={index}
                className={`dot ${
                  activeAccommodationIndex === index ? "active" : ""
                }`}
                onClick={() => scrollToCard(index)}
              />
            ))}
        </div>
      </div>
    );
  };

  const [activities, setActivities] = useState<IActivity[]>([
    {
      photo: Placeholder4,
      title: "",
    },
  ]);

  const activitiesRef = useRef<HTMLDivElement>(null);

  const handleActivityTitleChange = (index: number, newTitle: string) => {
    const updatedActivities = [...activities];
    updatedActivities[index] = {
      ...updatedActivities[index],
      title: newTitle,
    };
    setActivities(updatedActivities);
  };

  const handleActivityPhotoChange = (index: number, file: File) => {
    const updatedActivities = [...activities];
    updatedActivities[index] = {
      ...updatedActivities[index],
      photo: URL.createObjectURL(file),
    };
    setActivities(updatedActivities);
  };

  const activitySection = () => {
    const activityCard = (item: any, index: number) => {
      return (
        <div className="eventv3_activities_container_card" key={index}>
          <img
            src={item.photo}
            alt="activity_img"
            className="eventv3_activities_image"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const input = document.createElement("input");
              input.type = "file";
              input.accept = "image/*";
              input.onchange = (e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file) {
                  handleActivityPhotoChange(index, file);
                }
              };
              input.click();
            }}
          />
          <div
            className="eventv3_activities_container_card_bottom"
            style={{ width: 172.922, boxSizing: "border-box" }}
          >
            <input
              className="eventv3_activities_container_card_bottom_title"
              style={{
                maxWidth: "172px",
                boxSizing: "border-box",
                border: "none",
                background: "transparent",
                width: "100%",
                outline: "none",
              }}
              value={item.title}
              onChange={(e) => handleActivityTitleChange(index, e.target.value)}
              placeholder="Enter activity title"
            />
          </div>
        </div>
      );
    };

    return (
      <div className="eventv3_activity_container" style={{ marginTop: 24 }}>
        {headingSection("Activity", "Add", () => {
          setActivities([...activities, { photo: Placeholder4, title: "" }]);
          setTimeout(() => {
            if (activitiesRef.current) {
              const cardWidth = activitiesRef.current.offsetWidth;
              activitiesRef.current.scrollTo({
                left: cardWidth * activities.length,
                behavior: "smooth",
              });
            }
          }, 0);
        })}
        <div
          className="eventv3_activities_container_cards"
          ref={activitiesRef}
          style={{
            display: "flex",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {activities.map((item, index) => activityCard(item, index))}
        </div>
      </div>
    );
  };

  const [testimonials, setTestimonials] = useState<ITestimonial[]>([
    {
      name: "",
      description: "",
    },
  ]);

  const [openTestimonialModal, setOpenTestimonialModal] = useState(false);
  const [editingTestimonialIndex, setEditingTestimonialIndex] = useState<
    number | null
  >(null);
  const [testimonialForm, setTestimonialForm] = useState<ITestimonial>({
    name: "",
    description: "",
  });

  const handleTestimonialSubmit = () => {
    if (editingTestimonialIndex !== null) {
      // Update existing testimonial
      const updatedTestimonials = [...testimonials];
      updatedTestimonials[editingTestimonialIndex] =
        testimonialForm as ITestimonial;
      setTestimonials(updatedTestimonials);
    } else {
      // Add new testimonial
      setTestimonials([...testimonials, testimonialForm as ITestimonial]);
    }

    // Reset form and close modal
    setTestimonialForm({ name: "", description: "" });
    setEditingTestimonialIndex(null);
    setOpenTestimonialModal(false);
  };

  const testimonialSection = () => {
    return (
      <div style={{ marginTop: 24 }}>
        {headingSection("Testimonials", "Add", () => {
          setEditingTestimonialIndex(null);
          setTestimonialForm({ name: "", description: "" });
          setOpenTestimonialModal(true);
        })}
        {testimonials.length > 0 &&
          testimonials.some(
            (testimonial) =>
              testimonial.name !== "" && testimonial.description !== ""
          ) && (
            <div className="intial_create_testimonial_container">
              {testimonials.map((item, index) => {
                if (item.name === "" || item.description === "") {
                  return null;
                }
                return (
                  <div
                    className="intial_create_testimonial_container_top"
                    key={index}
                    style={{
                      borderBottom:
                        index !== testimonials.length - 1
                          ? "0.333px solid var(--Separators-Non-opaque, rgba(84, 84, 86, 0.34))"
                          : "none",
                      paddingBottom: 4,
                    }}
                  >
                    <div>
                      <p className="intial_create_testimonial_container_top_title">
                        {item.name}
                      </p>
                      <p className="intial_create_testimonial_container_top_description">
                        {item.description}
                      </p>
                    </div>
                    <img
                      src={EditIcon}
                      alt="edit_icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditingTestimonialIndex(index);
                        setTestimonialForm(item);
                        setOpenTestimonialModal(true);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        <MaterialModal
          isOpen={openTestimonialModal}
          onClose={() => {
            setOpenTestimonialModal(false);
            setTestimonialForm({ name: "", description: "" });
            setEditingTestimonialIndex(null);
          }}
        >
          <div style={{ padding: "24px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ModalTitle
                title={
                  editingTestimonialIndex !== null
                    ? "Edit Testimonial"
                    : "Add Testimonial"
                }
              />
              <ModalIcon
                icon={CrossModalIcon}
                onClick={() => {
                  setOpenTestimonialModal(false);
                  setTestimonialForm({ name: "", description: "" });
                  setEditingTestimonialIndex(null);
                }}
                width={24}
                padding={4}
              />
            </div>
            <CustomQuestionInput
              placeholder="Enter name"
              marginTop={20}
              props={{
                value: testimonialForm?.name,
                onChange: (e: { target: { value: any } }) =>
                  setTestimonialForm((prev) => ({
                    ...prev,
                    name: e.target.value,
                  })),
              }}
            />
            <CustomQuestionInput
              placeholder="Enter testimonial"
              marginTop={16}
              props={{
                value: testimonialForm.description,
                onChange: (e: { target: { value: any } }) =>
                  setTestimonialForm((prev) => ({
                    ...prev,
                    description: e.target.value,
                  })),
              }}
            />
            <ModalButtonPrimary
              title={editingTestimonialIndex !== null ? "Update" : "Add"}
              marginTop={24}
              onClick={handleTestimonialSubmit}
            />
          </div>
        </MaterialModal>
      </div>
    );
  };

  const [amenities, setAmenities] = useState<IAmenity[]>([
    {
      name: "",
      description: [],
    },
  ]);

  const [openAmenityModal, setOpenAmenityModal] = useState(false);
  const [editingAmenityIndex, setEditingAmenityIndex] = useState<number | null>(
    null
  );
  const [amenityForm, setAmenityForm] = useState<IAmenity>({
    name: "",
    description: [],
  });

  const handleAmenitySubmit = () => {
    if (editingAmenityIndex !== null) {
      // Update existing amenity
      const updatedAmenities = [...amenities];
      updatedAmenities[editingAmenityIndex] = amenityForm as IAmenity;
      setAmenities(updatedAmenities);
    } else {
      // Add new amenity
      setAmenities([...amenities, amenityForm as IAmenity]);
    }

    // Reset form and close modal
    setAmenityForm({ name: "", description: [] });
    setEditingAmenityIndex(null);
    setOpenAmenityModal(false);
  };

  const amenitiesSection = () => {
    return (
      <div style={{ marginTop: 24 }}>
        {headingSection("Amenities", "Add", () => {
          setEditingAmenityIndex(null);
          setAmenityForm({ name: "", description: [] });
          setOpenAmenityModal(true);
        })}
        {amenities.length > 0 &&
          amenities.some(
            (amenity) => amenity.name !== "" && amenity.description.length > 0
          ) && (
            <div className="intial_create_testimonial_container">
              {amenities.map((item, index) => {
                if (item.name === "" || item.description.length === 0) {
                  return null;
                }
                return (
                  <div
                    className="intial_create_testimonial_container_top"
                    key={index}
                    style={{
                      borderBottom:
                        index !== amenities.length - 1
                          ? "0.333px solid var(--Separators-Non-opaque, rgba(84, 84, 86, 0.34))"
                          : "none",
                      paddingBottom: 4,
                    }}
                  >
                    <div>
                      <p className="intial_create_testimonial_container_top_title">
                        {item.name}
                      </p>
                      <p className="intial_create_testimonial_container_top_description">
                        {item.description.join(", ")}
                      </p>
                    </div>
                    <img
                      src={EditIcon}
                      alt="edit_icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditingAmenityIndex(index);
                        setAmenityForm(item);
                        setOpenAmenityModal(true);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        <MaterialModal
          isOpen={openAmenityModal}
          onClose={() => {
            setOpenAmenityModal(false);
            setAmenityForm({ name: "", description: [] });
            setEditingAmenityIndex(null);
          }}
        >
          <div style={{ padding: "24px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ModalTitle
                title={
                  editingAmenityIndex !== null ? "Edit Amenity" : "Add Amenity"
                }
              />
              <ModalIcon
                icon={CrossModalIcon}
                onClick={() => {
                  setOpenAmenityModal(false);
                  setAmenityForm({ name: "", description: [] });
                  setEditingAmenityIndex(null);
                }}
                width={24}
                padding={4}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
                marginTop: 16,
              }}
            >
              <CustomQuestionTitle title="Description" />
            </div>
            <ModalSelect
              options={[
                {
                  value: "Inclusions",
                  label: "Inclusions",
                },
                {
                  value: "Exclusions",
                  label: "Exclusions",
                },
                {
                  value: "Add Ons",
                  label: "Add Ons",
                },
              ]}
              props={{
                value: amenityForm.name,
                onChange: (e: { target: { value: any } }) =>
                  setAmenityForm((prev) => ({
                    ...prev,
                    name: e.target.value,
                  })),
                styles: {
                  background: "#fff",
                },
              }}
            />

            <div style={{ marginTop: 16 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <CustomQuestionTitle title="Description" />
              </div>

              <MultiInput
                value={amenityForm.description}
                onChange={(value) =>
                  setAmenityForm((prev) => ({
                    ...prev,
                    description: value,
                  }))
                }
              />
            </div>
            <ModalButtonPrimary
              title={editingAmenityIndex !== null ? "Update" : "Add"}
              marginTop={24}
              onClick={handleAmenitySubmit}
            />
          </div>
        </MaterialModal>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          // marginTop: "var(--navbar-offset)",
          // transition: "all 0.3s ease",
          marginTop: 24,
        }}
      >
        {itinerarySection()}
        {accomodationSection()}
        {activitySection()}
        {testimonialSection()}
        {amenitiesSection()}
      </div>
    </div>
  );
}
