import React, { useEffect, useState, useRef } from "react";
import "./EventV3.css";
import Navbar from "./Navbar";
import EventCard, { dateComponent } from "../listing/EventCard";
import ColorBg from "../../resources/images/eventv2/colorbg.png";
import StayIcon from "../../resources/images/eventv2/stay_icon.svg";
import MealIcon from "../../resources/images/eventv2/meal_icon.svg";
import LocationIcon from "../../resources/images/create_event/location_icon.svg";
import ScanIcon from "../../resources/images/create_event/scan_icon.svg";
import MaterialModal from "../CreateEvent/MaterialModal";
import {
  CustomQuestionOptions,
  ModalIcon,
  ModalTitle,
} from "../CreateEvent/ModalComponent";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import styles from "../listing/EventCard.module.css";

import { Sheet } from "react-modal-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import CheckboxDisplayIcon from "../../resources/images/eventv2/checkbox_display_icon.svg";
import CheckboxEmptyIcon from "../../resources/images/eventv2/checkbox_empty_icon.svg";
import CheckboxCheckedIcon from "../../resources/images/eventv2/checkbox_checked_icon.svg";
import {
  EventDetailsInterface,
  EventInterface,
} from "../../commons/Interfaces";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../../commons/ApiHelper";
import LoginModal from "../authv2/LoginModal";
import { authenticatedRequest } from "../../commons/AuthenticatedRequest";

import TestimonialIcon1 from "../../resources/images/eventv2/testimonial_icon_1.svg";
import TestimonialIcon2 from "../../resources/images/eventv2/testimonial_icon_2.svg";
import TestimonialIcon3 from "../../resources/images/eventv2/testimonial_icon_3.svg";
import DOMPurify from "dompurify";
import InstagramIcon from "../../resources/images/eventv2/insta_white_icon.svg";
import YoutubeIcon from "../../resources/images/eventv2/youtube_white_icon.svg";
import { useMediaQuery } from "@mui/material";
import AnalyticsService from "../../services/AnalyticsService";

interface CustomQuestionResponse {
  [key: string]: string | boolean;
}

export default function EventV3() {
  const isLaptop = useMediaQuery("(min-width: 600px)");
  const { event_slug } = useParams();
  const [eventDetails, setEventDetails] =
    useState<EventDetailsInterface | null>(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [questionResponses, setQuestionResponses] =
    useState<CustomQuestionResponse>({});
  const [viewedSections, setViewedSections] = useState<Set<string>>(new Set());

  const sectionRefs = {
    itinerary: useRef<HTMLDivElement>(null),
    activities: useRef<HTMLDivElement>(null),
    accommodation: useRef<HTMLDivElement>(null),
    creator: useRef<HTMLDivElement>(null),
    testimonials: useRef<HTMLDivElement>(null),
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      axios
        .get(apiurl + `events/${event_slug}`)
        .then((res) => {
          console.log(res.data);
          setEventDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchEventDetails();
  }, [event_slug]);

  useEffect(() => {
    const initAnalytics = async () => {
      const userId = localStorage.getItem("user_id");
      const device = window.navigator.userAgent;
      const url = window.location.pathname;

      // Create analytics session
      await AnalyticsService.createSession(userId, url, device);

      // Track page view event
      await AnalyticsService.trackEvent(url, "page_view", "event_page", {
        event_slug,
        event_type: eventDetails?.event_type,
        event_id: eventDetails?._id,
        timestamp: new Date().toISOString(),
      });
    };

    if (eventDetails) {
      initAnalytics();
    }

    // Track active time
    let startTime = Date.now();
    const interval = setInterval(() => {
      const activeTime = Math.floor((Date.now() - startTime) / 1000); // Convert to seconds
      AnalyticsService.updateSessionActiveTime(activeTime);
    }, 30000); // Update every 30 seconds

    return () => {
      clearInterval(interval);
    };
  }, [event_slug, eventDetails]);

  // Track section views using Intersection Observer
  useEffect(() => {
    if (!eventDetails) return;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute("data-section-id");
          if (sectionId && !viewedSections.has(sectionId)) {
            setViewedSections(
              (prev) => new Set([...Array.from(prev), sectionId])
            );

            // Track section view in analytics
            AnalyticsService.trackEvent(
              window.location.pathname,
              "section_view",
              sectionId,
              {
                event_id: eventDetails._id,
                event_type: "scroll",
                section_name: sectionId,
                timestamp: new Date().toISOString(),
              }
            );
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5, // Fire when 50% of the section is visible
      rootMargin: "0px",
    });

    // Observe all section refs
    Object.entries(sectionRefs).forEach(([key, ref]) => {
      if (ref.current) {
        ref.current.setAttribute("data-section-id", key);
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, [eventDetails, viewedSections]);

  const eventDescription = () => {
    return (
      <div className="eventv3_description_container">
        <div
          className="evevtv3_description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(eventDetails?.description || ""),
          }}
        />
      </div>
    );
  };

  const itinerary = () => {
    return (
      <div className="eventv3_itinerary_container" ref={sectionRefs.itinerary}>
        <p
          className="eventv3_header"
          style={{
            position: "sticky",
            top: !isLaptop ? "calc(var(--navbar-offset) + 36px)" : "0px",
            transition: "top 0.3s ease-in-out",
          }}
        >
          Itinerary
        </p>
        <div className="eventv3_itinerary_container_cards">
          {eventDetails?.itinerary
            ?.sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )
            .map((item, index) => {
              const date = new Date(item.date);
              const accommodation = eventDetails?.accommodations?.find(
                (acc) => {
                  const accStartDate = new Date(acc.start_date);
                  const accEndDate = new Date(acc.end_date);

                  // Check if itinerary date falls between accommodation check-in and check-out
                  return date >= accStartDate && date <= accEndDate;
                }
              );
              return (
                <div className="eventv3_itn_card_container" key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${item.photos})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "20px 20px 0px 0px",
                      height: 335,
                    }}
                  >
                    <div className="eventv3_itn_card_top_section">
                      <p className="eventv3_itn_card_heading">{item.title}</p>
                      {dateComponent(
                        eventDetails?.event_type === "Event"
                          ? date
                              .toLocaleString("en-US", {
                                hour: "numeric",
                                hour12: true,
                              })
                              .split(" ")[1]
                          : date.toLocaleString("en-US", { month: "short" }),
                        eventDetails?.event_type === "Event"
                          ? date
                              .toLocaleString("en-US", {
                                hour: "numeric",
                                hour12: true,
                              })
                              .split(" ")[0]
                          : date.getDate().toString()
                      )}
                    </div>
                  </div>
                  <div className="eventv3_itn_card_bottom_section">
                    <div
                      className="eventv3_itn_card_description"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.description || ""),
                      }}
                    />
                    <div
                      className="eventv3_itn_card_bottom_section_bottom"
                      style={{ width: "100%" }}
                    >
                      <div className="eventv3_itn_card_bottom_section_bottom_left">
                        <img src={StayIcon} alt="stay_icon" />
                        <p className="eventv3_itn_card_description">
                          {accommodation?.property_name}
                        </p>
                      </div>
                      <div
                        style={{
                          height: 27,
                          width: 1,
                          background: "rgba(0, 0, 0, 0.19)",
                        }}
                      />
                      <div className="eventv3_itn_card_bottom_section_bottom_left">
                        <img src={MealIcon} alt="meal_icon" />
                        <p className="eventv3_itn_card_description">
                          {item.meal}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const activities = () => {
    const activityCard = (item: any, index: number) => {
      return (
        <div className="eventv3_activities_container_card" key={index}>
          <img
            src={item.image}
            alt="activity_img"
            className="eventv3_activities_image"
          />
          <div className="eventv3_activities_container_card_bottom">
            <p className="eventv3_activities_container_card_bottom_title">
              {item.title}
            </p>
          </div>
        </div>
      );
    };
    return (
      <div
        className="eventv3_activities_container"
        ref={sectionRefs.activities}
      >
        <p className="eventv3_header">Activities</p>
        <div className="eventv3_activities_container_cards">
          {eventDetails?.activities?.map((item, index) =>
            activityCard(item, index)
          )}
        </div>
      </div>
    );
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const cardsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (cardsRef.current) {
        const scrollPosition = cardsRef.current.scrollLeft;
        const cardWidth = cardsRef.current.offsetWidth;
        const newIndex = Math.round(scrollPosition / cardWidth);
        setActiveIndex(newIndex);
      }
    };

    cardsRef.current?.addEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => cardsRef.current?.removeEventListener("scroll", handleScroll);
  }, []);

  const accomodation = () => {
    const scrollToCard = (index: number) => {
      if (cardsRef.current) {
        const cardWidth = cardsRef.current.offsetWidth;
        cardsRef.current.scrollTo({
          left: cardWidth * index,
          behavior: "smooth",
        });
        setActiveIndex(index);
      }
    };

    return (
      <div
        className="eventv3_accomodation_container"
        ref={sectionRefs.accommodation}
      >
        <p className="eventv3_header">Accommodation</p>
        <div
          className="eventv3_accomodation_container_cards"
          ref={cardsRef}
          style={{
            display: "flex",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {eventDetails?.accommodations?.map((item, index) => {
            const date = new Date(eventDetails?.start_date);
            date.setDate(date.getDate() + index);
            const endDate = new Date(item.end_date);
            endDate.setDate(endDate.getDate() + 1);
            return (
              <div className="eventv3_accomodation_container_card" key={index}>
                <img
                  src={item.photos[0]}
                  alt="accomodation_img"
                  style={{
                    width: "100%",
                    height: "260px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
                <div className="event_details_container">
                  <p className="event_details_text">{item.property_name}</p>
                  <div className="event_details_container_inner">
                    <div
                      style={{
                        border: "1px solid #ededed",
                        borderRadius: "10px",
                      }}
                    >
                      {dateComponent(
                        date.toLocaleString("en-US", {
                          month: "short",
                        }),
                        date.getDate().toString()
                      )}
                    </div>
                    <div className="event_details_container_inner_right">
                      <p className="event_details_container_inner_right_text_primary">
                        {date.toLocaleDateString("en-US", {
                          weekday: "long",
                          day: "numeric",
                          month: "short",
                        })}
                      </p>
                      <p className="event_details_container_inner_right_text">
                        {date.toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        -{" "}
                        {endDate.toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                        })}
                        ,{" "}
                        {endDate.toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        GMT+5:30
                      </p>
                    </div>
                  </div>
                  <div className="event_details_container_inner">
                    <div className="event_details_container_inner_left">
                      <img src={LocationIcon} alt="location_icon" />
                    </div>
                    <div className="event_details_container_inner_right">
                      <p className="event_details_container_inner_right_text_primary">
                        {item.location.city}
                      </p>
                      <p className="event_details_container_inner_right_text">
                        {item.location.address}
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: 18 }}>
                    <button className="checkin_button">
                      <img src={ScanIcon} alt="scan_icon" />
                      <p className="checkin_button_text">Check Property</p>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="dot-navigation">
          {eventDetails?.accommodations &&
            eventDetails?.accommodations?.length > 1 &&
            eventDetails?.accommodations?.map((_, index) => (
              <div
                key={index}
                className={`dot ${activeIndex === index ? "active" : ""}`}
                onClick={() => scrollToCard(index)}
              />
            ))}
        </div>
      </div>
    );
  };

  const creator = () => {
    return (
      <div style={{ marginTop: 68 }} ref={sectionRefs.creator}>
        <p className="eventv3_header">Meet the Creator</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            borderRadius: "20px",
            backgroundImage: `url(${eventDetails?.host?.profile_pic})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: 360,
            flexDirection: "column",
            margin: "24px auto 16px",
            width: "307px",
          }}
        >
          <div
            style={{
              borderRadius: "0px 0px 20.997px 20.997px",
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
              padding: "24px 28px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <div>
              <p className="eventv3_creator_title">CREATOR</p>
              <p className="eventv3_creator_name">
                {eventDetails?.host?.first_name} {eventDetails?.host?.last_name}
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
              <img
                src={InstagramIcon}
                alt="instagram_icon"
                onClick={() =>
                  window.open(
                    eventDetails?.host?.social_media_urls?.instagram,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              />
              <img
                src={YoutubeIcon}
                alt="youtube_icon"
                onClick={() =>
                  window.open(
                    eventDetails?.host?.social_media_urls?.youtube,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              />
              <p className="eventv3_creator_title">2M+</p>
            </div>
          </div>
        </div>
        <div
          className="eventv3_creator_description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(eventDetails?.host?.description || ""),
          }}
        />
      </div>
    );
  };

  const testimonials = () => {
    const icons = [TestimonialIcon1, TestimonialIcon2, TestimonialIcon3];
    return (
      <div
        style={{ marginTop: 68, paddingBottom: 24 }}
        ref={sectionRefs.testimonials}
      >
        <p className="eventv3_header">Testimonials</p>
        <div
          style={{
            display: "flex",
            paddingTop: 24,
            paddingBottom: 2,
            overflowX: "scroll",
          }}
        >
          {eventDetails?.testimonials?.map((item, index) => (
            <div
              className="eventv3_testimonial_container"
              key={item._id}
              style={{
                marginLeft: index === 0 ? 2 : -24,
                marginTop: index % 2 !== 0 ? 24 : 0,
                width: "137.786px",
              }}
            >
              <div style={{ flex: 1 }}>
                <img
                  src={icons[index % 3]}
                  alt="testimonial_icon"
                  style={{ width: 28, height: 28, marginBottom: 6 }}
                />
                <div
                  className="eventv3_testimonial_description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.feedback_text || ""),
                  }}
                />
              </div>
              <p className="eventv3_testimonial_name">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const footer = () => {
    return (
      <div className="eventv3_footer_container">
        <p
          className="eventv3_footer_text"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsBottomSheetOpen(true);
            AnalyticsService.trackEvent(
              window.location.pathname,
              "explore_pricing_clicked",
              "pricing_button",
              {
                event_id: eventDetails?._id,
                event_type: eventDetails?.event_type,
                timestamp: new Date().toISOString(),
              }
            );
          }}
        >
          Explore Pricing
        </p>
        <button
          className="eventv3_footer_button"
          onClick={() => {
            setIsTicketTypeOpen(true);
            AnalyticsService.trackEvent(
              window.location.pathname,
              "view_ticket_types",
              "ticket_type_button",
              {
                event_id: eventDetails?._id,
                event_type: eventDetails?.event_type,
                timestamp: new Date().toISOString(),
              }
            );
          }}
        >
          Book Now
        </button>
      </div>
    );
  };

  const [isBookNowOpen, setIsBookNowOpen] = useState(false);

  const divider = (margin: string) => {
    return (
      <div style={{ height: 1, background: "#E3E4E5", margin: margin }}></div>
    );
  };

  const handleBookNow = () => {
    AnalyticsService.trackEvent(
      window.location.pathname,
      "booking_initiated",
      "book_now_button",
      {
        event_id: eventDetails?._id,
        event_type: eventDetails?.event_type,
        timestamp: new Date().toISOString(),
        email: email,
      }
    );

    const totalPriceBeforeTaxes = Object.keys(ticketCounts).reduce(
      (acc, key) => {
        const ticket = eventDetails?.ticketTypes.find(
          (ticket) => ticket._id === key
        );
        return acc + ticketCounts[key] * (ticket?.price || 0);
      },
      0
    );

    const totalTaxes = totalPriceBeforeTaxes * 0.18;
    const totalPrice = totalPriceBeforeTaxes + totalTaxes;
    const totalPlatformFee = totalPrice * 0.02;
    const totalPriceWithPlatformFee = totalPrice + totalPlatformFee;

    axios
      .post(apiurl + `payments/create-order`, {
        amount: totalPriceWithPlatformFee,
      })
      .then((res) => {
        const data = res.data;
        console.log(res);
        const options = {
          key: "rzp_test_f2jdX5aXSokGKe",
          amount: data.amount,
          currency: "INR",
          name: "Thrivia",
          description: "Test Transaction",
          order_id: data.id,
          handler: function (response: {
            razorpay_payment_id: string;
            razorpay_order_id: string;
            razorpay_signature: string;
          }) {
            console.log({ response });
            // Create booking first to get booking_id
            axios
              .post(apiurl + `events/${eventDetails?._id}/book`, {
                ticket_types: ticketCounts,
                total_price: totalPriceWithPlatformFee,
                responses: questionResponses,
                name: name,
                email: email,
                order_id: data.id,
              })
              .then((bookingRes) => {
                // Redirect to booking confirmed page
                window.location.href = `/booking_confirmed/${bookingRes.data.booking_id}`;
              })
              .catch((err) => {
                console.error("Booking creation failed:", err);
              });
          },
          prefill: {
            name: name,
            email: email,
          },
          theme: { color: "#3399cc" },
        };

        const razor = new (window as any).Razorpay(options);
        razor.open();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const bookNow = () => {
    const totalPriceBeforeTaxes = Object.keys(ticketCounts).reduce(
      (acc, key) => {
        const ticket = eventDetails?.ticketTypes.find(
          (ticket) => ticket._id === key
        );
        return acc + ticketCounts[key] * (ticket?.price || 0);
      },
      0
    );

    const totalTaxes = totalPriceBeforeTaxes * 0.18;
    const totalPrice = totalPriceBeforeTaxes + totalTaxes;
    const totalPlatformFee = totalPrice * 0.02;
    const totalPriceWithPlatformFee = totalPrice + totalPlatformFee;
    const numberOfPersons = Object.values(ticketCounts).reduce(
      (acc, count) => acc + count,
      0
    );
    const cancellationDate = new Date(eventDetails?.start_date || "");
    cancellationDate.setDate(cancellationDate.getDate() - 15);

    return (
      <>
        <MaterialModal
          isOpen={isBookNowOpen}
          onClose={() => {
            setIsBookNowOpen(false);
          }}
          fullScreen={true}
          paperStyles={{ maxWidth: "450px" }}
        >
          <div style={{ padding: 24 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ModalIcon
                icon={CrossModalIcon}
                width={24}
                padding={4}
                onClick={() => setIsBookNowOpen(false)}
              />
              <div
                style={{ display: "flex", justifyContent: "center", flex: 1 }}
              >
                <ModalTitle title="Confirm and pay" />
              </div>
            </div>
            {divider("24px 0")}
            <div className="book_now_event_detail_container">
              <img
                src={eventDetails?.cover_photo}
                alt="test_img"
                style={{
                  width: 118,
                  height: 118,
                  borderRadius: 20,
                  boxShadow: "0px 4.124px 4.124px 0px rgba(0, 0, 0, 0.25)",
                  objectFit: "cover",
                }}
              />
              <div className="book_now_event_detail_container_right">
                <p className="book_now_event_detail_container_right_title">
                  {eventDetails?.title}
                </p>
                <div style={{ marginTop: 12 }}>
                  <p className={styles.text_primary} style={{ color: "#000" }}>
                    {new Date(
                      eventDetails?.start_date || ""
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                  <p className={styles.text_primary} style={{ color: "#000" }}>
                    {eventDetails?.host?.first_name}{" "}
                    {eventDetails?.host?.last_name}
                  </p>
                  <p
                    className={styles.text_secondary}
                    style={{ marginTop: 0, color: "#000" }}
                  >
                    {eventDetails?.location.city}
                  </p>
                  <p
                    className={styles.text_secondary}
                    style={{ marginTop: 12, color: "#000" }}
                  >
                    {new Date(
                      eventDetails?.start_date || ""
                    ).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                    -{" "}
                    {new Date(eventDetails?.end_date || "").toLocaleDateString(
                      "en-US",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  </p>
                </div>
              </div>
            </div>
            {/* {divider("24px 0")}
            <p className="book_now_modal_text_secondary">
              Free cancellation before{" "}
              {eventDetails?.free_cancellation_upto
                ? new Date(
                    eventDetails?.free_cancellation_upto
                  ).toLocaleDateString("dd MMM")
                : "1 month before event."}{" "}
              Get a full refund if you change your mind.
            </p> */}
            {divider("24px 0")}
            <p className="book_now_modal_heading"> Your Booking</p>
            <p
              className="book_now_modal_text_primary"
              style={{ marginTop: 24 }}
            >
              Guests
            </p>
            <p
              className="book_now_modal_text_secondary"
              style={{ marginTop: 8 }}
            >
              {Object.values(ticketCounts).reduce(
                (acc, count) => acc + count,
                0
              )}{" "}
              Persons
            </p>
            <p
              className="book_now_modal_text_primary"
              style={{ marginTop: 16 }}
            >
              Ticket Type
            </p>
            <div
              className="book_now_modal_text_secondary"
              style={{ marginTop: 8 }}
            >
              {Object.keys(ticketCounts).map((key) => {
                const ticket = eventDetails?.ticketTypes.find(
                  (ticket) => ticket._id === key
                );
                if (ticketCounts[key] > 0) {
                  return (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ marginBottom: 8 }}>
                        {ticketCounts[key]}
                        {" x "}
                        {ticket?.name}
                      </p>
                      {/* <p style={{ marginBottom: 8 }}>
                        ₹{ticket?.price.toLocaleString()}
                      </p> */}
                    </div>
                  );
                }
                return null;
              })}
            </div>
            {divider("16px 0")}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="book_now_modal_text_primary">Total before taxes </p>
              <p className="book_now_modal_text_primary">
                ₹{" "}
                {Object.keys(ticketCounts)
                  .reduce((acc, key) => {
                    const ticket = eventDetails?.ticketTypes.find(
                      (ticket) => ticket._id === key
                    );
                    return acc + ticketCounts[key] * (ticket?.price || 0);
                  }, 0)
                  .toLocaleString()}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 24,
              }}
            >
              <p className="book_now_modal_text_secondary">
                {numberOfPersons} persons
              </p>
              <p className="book_now_modal_text_secondary">
                ₹{totalPriceBeforeTaxes.toLocaleString()}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 13.5,
              }}
            >
              <p className="book_now_modal_text_secondary">
                Thrivia platform fee (@2%){" "}
              </p>
              <p className="book_now_modal_text_secondary">
                ₹{totalPlatformFee.toLocaleString()}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 13.5,
              }}
            >
              <p className="book_now_modal_text_secondary">Taxes (@18%) </p>
              <p className="book_now_modal_text_secondary">
                ₹{totalTaxes.toLocaleString()}
              </p>
            </div>
            {divider("16px 0")}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="book_now_modal_text_primary">Total before taxes </p>
              <p className="book_now_modal_text_primary">
                ₹{totalPriceWithPlatformFee.toLocaleString()}
              </p>
            </div>
            {divider("24px 0")}
            <p className="book_now_modal_heading"> Cancellation Policy</p>
            <p
              className="book_now_modal_text_secondary"
              style={{ marginTop: 24 }}
            >
              Free cancellation for 48 hours. Cancel before{" "}
              {cancellationDate.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })}{" "}
              for a partial refund.{" "}
              <span
                style={{
                  color: "#000",
                  fontWeight: 700,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Learn more
              </span>
            </p>
            {divider("24px 0")}
            <p className="book_now_modal_heading">Ground Rules</p>
            <p
              className="book_now_modal_text_secondary"
              style={{ marginTop: 24 }}
            >
              We ask every guest to remember a few simple things about what
              makes a great guest. Treat your Host's home like your own
              <ul>
                <li>Follow the house rules</li>
                <li>Treat the property with care</li>
                <li>Be considerate of other guests</li>
                <li>
                  Leave the property in the same condition as you found it
                </li>
              </ul>
            </p>
            {divider("24px 0")}
            <p className="book_now_modal_text_secondary">
              By selecting the button below, I agree to the Host's House Rules,{" "}
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                Ground rules for guests
              </span>
              , Thrivia's Rebooking and Refund Policy and that Thrivia can
              charge my payment method if I'm responsible for damage.
            </p>
            {divider("24px 0")}
            <button className="book_now_modal_button" onClick={handleBookNow}>
              Book Now
            </button>
          </div>
        </MaterialModal>
      </>
    );
  };

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [activeOption, setActiveOption] = useState<string>("Inclusion");

  const bottomSheet = () => {
    return (
      <Sheet
        isOpen={isBottomSheetOpen}
        onClose={() => {
          setIsBottomSheetOpen(false);
        }}
        detent={"content-height"}
      >
        <Sheet.Container>
          {/* <Sheet.Header /> */}
          <Sheet.Content>
            <div
              style={{
                padding: 24,
                display: "flex",
                gap: 24,
                flexDirection: "column",
              }}
            >
              <ModalIcon
                icon={CrossModalIcon}
                width={24}
                padding={4}
                onClick={() => setIsBottomSheetOpen(false)}
              />
              <p className="price_details_header">Price Details </p>
              <div
                style={{
                  padding: "0px 8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                {eventDetails?.ticketTypes?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="book_now_modal_text_secondary">
                      {item.name}{" "}
                    </p>
                    <p className="book_now_modal_text_secondary">
                      ₹{item.price.toLocaleString()}
                    </p>
                  </div>
                ))}
              </div>
              {divider("0px 0px")}
              <CustomQuestionOptions
                options={[
                  { title: "Inclusion", id: "Inclusion" },
                  { title: "Exclusion", id: "Exclusion" },
                  { title: "Add Ons", id: "Add Ons" },
                ]}
                activeOption={activeOption}
                setActiveOption={setActiveOption}
              />
              <div
                style={{
                  marginLeft: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                {eventDetails?.inclusions
                  ?.filter((item: any) => item.type === activeOption)
                  .map((item: any, index: number) => (
                    <p className="book_now_modal_text_secondary" key={index}>
                      {item.text}
                    </p>
                  ))}
              </div>
              <button
                className="book_now_modal_button"
                onClick={() => {
                  setIsBottomSheetOpen(false);
                  setIsTicketTypeOpen(true);
                }}
              >
                Book Now
              </button>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  };

  const bottomModal = () => {
    return (
      <MaterialModal
        isOpen={isBottomSheetOpen}
        onClose={() => setIsBottomSheetOpen(false)}
        paperStyles={{ maxWidth: "450px" }}
      >
        <div
          style={{
            padding: 24,
            display: "flex",
            gap: 24,
            flexDirection: "column",
          }}
        >
          <ModalIcon
            icon={CrossModalIcon}
            width={24}
            padding={4}
            onClick={() => setIsBottomSheetOpen(false)}
          />
          <p className="price_details_header">Price Details </p>
          <div
            style={{
              padding: "0px 8px",
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            {eventDetails?.ticketTypes?.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="book_now_modal_text_secondary">{item.name} </p>
                <p className="book_now_modal_text_secondary">
                  ₹{item.price.toLocaleString()}
                </p>
              </div>
            ))}
          </div>
          {divider("0px 0px")}
          <CustomQuestionOptions
            options={[
              { title: "Inclusion", id: "Inclusion" },
              { title: "Exclusion", id: "Exclusion" },
              { title: "Add Ons", id: "Add Ons" },
            ]}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
          />
          <div
            style={{
              marginLeft: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {eventDetails?.inclusions
              ?.filter((item: any) => item.type === activeOption)
              .map((item: any, index: number) => (
                <p className="book_now_modal_text_secondary" key={index}>
                  {item.text}
                </p>
              ))}
          </div>
          <button
            className="book_now_modal_button"
            onClick={() => {
              setIsBottomSheetOpen(false);
              setIsTicketTypeOpen(true);
            }}
          >
            Book Now
          </button>
        </div>
      </MaterialModal>
    );
  };

  const [isTicketTypeOpen, setIsTicketTypeOpen] = useState(false);
  const [ticketCounts, setTicketCounts] = useState<{ [key: string]: number }>(
    {}
  );

  const ticketType = () => {
    const updateTicketCount = (ticketId: string, delta: number) => {
      console.log(ticketId, delta);
      setTicketCounts((prev) => ({
        ...prev,
        [ticketId]: Math.max(0, (prev[ticketId] || 1) + delta),
      }));
    };

    return (
      <MaterialModal
        isOpen={isTicketTypeOpen}
        onClose={() => setIsTicketTypeOpen(false)}
        fullScreen={true}
        paperStyles={{ maxWidth: "450px" }}
      >
        <div
          style={{
            background: `url(${ColorBg})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 24,
            padding: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 24,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ModalIcon
                icon={CrossModalIcon}
                width={24}
                padding={4}
                onClick={() => setIsTicketTypeOpen(false)}
              />
              <div
                style={{ display: "flex", justifyContent: "center", flex: 1 }}
              >
                <ModalTitle title="Choose Ticket Type" />
              </div>
            </div>
            <p className="book_now_modal_text_secondary">
              Free cancellation before{" "}
              {eventDetails?.free_cancellation_upto
                ? new Date(
                    eventDetails?.free_cancellation_upto
                  ).toLocaleDateString("dd MMM")
                : "1 month before event."}{" "}
              Get a full refund if you change your mind.
            </p>
            {divider("0px 0px")}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                minHeight: "calc(100vh - 263px)",
              }}
            >
              {eventDetails?.ticketTypes
                ?.sort((a: any, b: any) => a.price - b.price)
                .map((item: any, index: number) => (
                  <div key={item._id}>
                    <p className="book_now_modal_heading">{item.name}</p>
                    <div className="ticket_type_div_container">
                      <p className="book_now_modal_text_primary">
                        ₹
                        {(
                          item.price * (ticketCounts[item._id] || 1)
                        ).toLocaleString()}
                      </p>
                      <p className="book_now_modal_text_tertiary">
                        {item.description}
                      </p>
                      {ticketCounts[item._id] > 0 ? (
                        <div className="book_now_modal_selected_ticket_type_container">
                          <div className="book_now_modal_selected_ticket_type">
                            {ticketCounts[item._id]} Person
                          </div>
                          <div className="book_now_modal_selected_ticket_type_right">
                            <p
                              className="book_now_modal_selected_ticket_type_right_text"
                              onClick={() => updateTicketCount(item._id, -1)}
                            >
                              -
                            </p>
                            <div
                              style={{
                                width: 1,
                                height: 27,
                                background: "rgba(60, 60, 67, 0.30)",
                              }}
                            />
                            <p
                              className="book_now_modal_selected_ticket_type_right_text"
                              onClick={() => updateTicketCount(item._id, 1)}
                            >
                              +
                            </p>
                          </div>
                        </div>
                      ) : (
                        <button
                          className="book_now_modal_button_small"
                          onClick={() => {
                            setTicketCounts((prev) => ({
                              ...prev,
                              [item._id]: 1,
                            }));
                          }}
                        >
                          Choose
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              {divider("0px")}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="book_now_modal_text_primary">
                  Total before taxes{" "}
                </p>
                <p className="book_now_modal_text_primary">
                  ₹
                  {Object.entries(ticketCounts).reduce(
                    (total, [ticketId, count]) =>
                      total +
                      (eventDetails?.ticketTypes.find(
                        (t: any) => t._id === ticketId
                      )?.price ?? 0) *
                        count,
                    0
                  )}
                </p>
              </div>
            </div>
          </div>
          <button
            className="book_now_modal_button"
            onClick={() => {
              setIsTicketTypeOpen(false);
              AnalyticsService.trackEvent(
                window.location.pathname,
                "custom_questions_clicked",
                "custom_questions_button",
                {
                  event_id: eventDetails?._id,
                  event_type: eventDetails?.event_type,
                  timestamp: new Date().toISOString(),
                }
              );
              setIsCustomQuestionOpen(true);
            }}
            disabled={
              !Object.values(ticketCounts).some((count) => count > 0) ||
              Object.keys(ticketCounts).length === 0
            }
          >
            Proceed
          </button>
        </div>
      </MaterialModal>
    );
  };

  const [isCustomQuestionOpen, setIsCustomQuestionOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const customQuestion = () => {
    const handleTextResponse = (questionId: string, value: string) => {
      setQuestionResponses((prev) => ({
        ...prev,
        [questionId]: value,
      }));
    };

    const handleCheckboxResponse = (questionId: string) => {
      setQuestionResponses((prev) => ({
        ...prev,
        [questionId]: !(prev[questionId] || false),
      }));
    };

    return (
      <MaterialModal
        isOpen={isCustomQuestionOpen}
        onClose={() => setIsCustomQuestionOpen(false)}
        fullScreen={true}
        paperStyles={{ maxWidth: "450px" }}
      >
        <div
          style={{
            // minHeight: "calc(100vh - 48px)",
            background: `url(${ColorBg})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 24,
            padding: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 24,
              minHeight: "calc(100vh - 105px)",

              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ModalIcon
                icon={CrossModalIcon}
                width={24}
                padding={4}
                onClick={() => setIsCustomQuestionOpen(false)}
              />
              <div
                style={{ display: "flex", justifyContent: "center", flex: 1 }}
              >
                <ModalTitle title="Your Info" />
              </div>
            </div>
            {divider("0px")}
            <p className="book_now_modal_text_secondary">
              Just a few more questions before payment.
            </p>
            <div className="custom_question_container">
              <p
                className="book_now_modal_text_primary"
                style={{ marginBottom: 12 }}
              >
                Full Name
              </p>
              <input
                type="text"
                className="custom_question_input"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="custom_question_container">
              <p
                className="book_now_modal_text_primary"
                style={{ marginBottom: 12 }}
              >
                Email
              </p>
              <input
                type="text"
                className="custom_question_input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {eventDetails?.customQuestions?.map((question) => (
              <div className="custom_question_container" key={question._id}>
                <p
                  className="book_now_modal_text_primary"
                  style={{ marginBottom: 12 }}
                >
                  {question.question}
                </p>

                {question.question_type === "text" ? (
                  <input
                    type="text"
                    className="custom_question_input"
                    placeholder="Enter your answer"
                    value={(questionResponses[question._id] as string) || ""}
                    onChange={(e) =>
                      handleTextResponse(question._id, e.target.value)
                    }
                  />
                ) : question.question_type === "checkbox" ? (
                  <div className="custom_question_checkbox_container">
                    <div className="custom_question_checkbox_container_item">
                      <img
                        src={CheckboxDisplayIcon}
                        alt="checkbox_display_icon"
                      />
                      <p className="custom_question_checkbox_container_item_text">
                        {question.question}
                      </p>
                      <img
                        src={
                          questionResponses[question._id]
                            ? CheckboxCheckedIcon
                            : CheckboxEmptyIcon
                        }
                        style={{ cursor: "pointer" }}
                        alt="checkbox_checked_icon"
                        onClick={() => handleCheckboxResponse(question._id)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
            {divider("0px")}
          </div>
          <button
            className="book_now_modal_button"
            onClick={() => {
              setIsCustomQuestionOpen(false);
              setIsBookNowOpen(true);
            }}
            disabled={
              Object.keys(questionResponses).length !==
                eventDetails?.customQuestions?.length ||
              name === "" ||
              email === "" ||
              Object.values(questionResponses).some((value) => value === "")
            }
            style={{
              marginTop: "auto",
            }}
          >
            Pay Now
          </button>
        </div>
      </MaterialModal>
    );
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  const handleLoginSuccess = () => {
    authenticatedRequest("events/wishlist/add", {
      method: "POST",
      data: {
        eventId: eventDetails?._id,
      },
    })
      .then((res: any) => {
        if (eventDetails) {
          console.log({ res });
          setEventDetails({ ...eventDetails, is_wishlisted: true });
          setIsLoginModalOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoginModalOpen(true);
      });
  };

  const handleWishlistEvent = (e: React.MouseEvent, event: EventInterface) => {
    e.stopPropagation();
    console.log(event);
    const url = event?.is_wishlisted
      ? "events/wishlist/remove"
      : "events/wishlist/add";
    authenticatedRequest(url, {
      method: "POST",
      data: {
        eventId: event.eventId,
      },
    })
      .then((res) => {
        if (eventDetails) {
          setEventDetails({
            ...eventDetails,
            is_wishlisted: !event.is_wishlisted,
          });
        }
      })
      .catch((err) => {
        setIsLoginModalOpen(true);
      });
  };

  return (
    <div>
      <Navbar style={{ position: "sticky" }} />
      {/* <div
        style={{
          position: "sticky",
          top: "calc(var(--navbar-offset))",
          zIndex: 100,
        }}
      /> */}
      <div
        style={{
          padding: 24,
          paddingTop: 0,
          maxWidth: 800,
          marginTop: isLaptop ? 0 : 16,
        }}
        className="event_v3_container"
      >
        <div
          style={{
            position: isLaptop ? "sticky" : "static",
            zIndex: 100,
            top: "calc(var(--navbar-offset) + 36px)",
            transition: "top 0.3s ease-in-out",
            display: "flex",
            flexDirection: "column",
            gap: 24,
            maxWidth: isLaptop ? "var(--eventv3-card-width)" : "auto",
            height: isLaptop ? "calc(100vh - 140px)" : "auto",
            overflowY: isLaptop ? "auto" : "hidden",
          }}
        >
          <EventCard
            isNotLink={true}
            event={
              {
                title: eventDetails?.title,
                cover_photo: eventDetails?.cover_photo,
                start_date: eventDetails?.start_date,
                end_date: eventDetails?.end_date,
                city: eventDetails?.location?.city,
                event_slug: eventDetails?.event_slug,
                eventId: eventDetails?._id,
                host: {
                  name:
                    eventDetails?.host?.first_name +
                    " " +
                    eventDetails?.host?.last_name,
                  profile_picture: eventDetails?.host?.profile_pic,
                  // social_media_url: eventDetails?.host?.social_media_url,
                },
                is_wishlisted: eventDetails?.is_wishlisted,
                event_type: eventDetails?.event_type,
              } as EventInterface
            }
            style={{
              width: isLaptop ? "var(--eventv3-card-width)" : "",
              height: isLaptop ? "var(--eventv3-card-height)" : "",
            }}
            handleWishlistEvent={handleWishlistEvent}
          />
          {eventDescription()}
        </div>
        {/* <div></div> */}
        <div
          className="eventv3_container_content"
          id="eventv3_container_content"
        >
          {itinerary()}
          {activities()}
          {accomodation()}
          {creator()}
          {testimonials()}
        </div>
      </div>
      {footer()}
      {bookNow()}
      {!isLaptop ? bottomSheet() : bottomModal()}
      {ticketType()}
      {customQuestion()}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleLoginModalClose}
        title={"Login to continue"}
        text={"Please login to see your wishlist"}
        onLoginSuccess={handleLoginSuccess}
      />
    </div>
  );
}
