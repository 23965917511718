import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import GreenTickIcon from "../../resources/images/create_event/green_tick_icon.svg";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from "react";
import Usericon from "../../resources/images/create_event/user_icon.svg";

interface ModalIconProps {
  icon: string | undefined;
  width: number;
  padding: number;
  style?: React.CSSProperties;
  onClick?: () => void;
  iconStyles?: React.CSSProperties;
}

interface ModalTitleProps {
  title: string;
  marginTop?: number;
}

interface ModalSubTitleProps {
  title: string;
  marginTop?: number;
}

interface ModalTextPrimaryProps {
  text: string;
  marginTop?: number;
  style?: React.CSSProperties;
}

interface ModalSecondaryTitleProps {
  title: string;
  marginTop?: number;
}

interface ModalButtonSecondaryProps {
  title: string;
  marginTop?: number;
  icon?: string;
  onClick?: () => void;
  styles?: React.CSSProperties;
}

interface ModalButtonPrimaryProps {
  title: string;
  marginTop?: number;
  icon?: string;
  onClick?: () => void;
  styles?: React.CSSProperties;
}

interface ModalGuestListItemProps {
  image: string;
  name: string;
  email: string;
  status: string;
  labelIcon?: string;
  checkinTime?: string;
}

interface ModalSelectProps {
  options: {
    value: string | number | readonly string[] | undefined;
    label?:
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | Iterable<ReactNode>
      | ReactPortal
      | null
      | undefined;
    icon?: string;
  }[];
  marginTop?: number;
  props?: any;
}

interface CustomQuestionTitleProps {
  title: string;
  marginTop?: number;
}

interface CustomQuestionInputProps {
  placeholder: string;
  marginTop?: number;
  props?: any;
  error?: boolean;
  helperText?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CustomQuestionOptionsProps {
  options: {
    title: string;
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    id: string;
  }[];
  marginTop?: number;
  activeOption: string;
  setActiveOption: (option: string) => void;
}

interface CustomQuestionRequiredBlockProps {
  marginTop?: number;
  isRequired: boolean;
  setIsRequired: (isRequired: boolean) => void;
  text?: string;
}

interface UserIconProps {
  width?: number;
  height?: number;
  padding?: number;
}

export const ModalIcon: React.FC<ModalIconProps> = ({
  icon,
  width,
  padding,
  style,
  onClick,
  iconStyles,
}) => {
  return (
    <div
      className="modal_icon_container"
      style={{ padding, width, height: width, ...style }}
      onClick={onClick}
    >
      <img src={icon} alt="icon" style={{ ...iconStyles }} />
    </div>
  );
};

export const ModalTitle: React.FC<ModalTitleProps> = ({ title, marginTop }) => {
  return (
    <p style={{ marginTop }} className="modal_title">
      {title}
    </p>
  );
};

export const ModalSubTitle: React.FC<ModalSubTitleProps> = ({
  title,
  marginTop,
}) => {
  return (
    <p style={{ marginTop }} className="modal_subtitle">
      {title}
    </p>
  );
};

export const ModalTextPrimary: React.FC<ModalTextPrimaryProps> = ({
  text,
  marginTop,
  style,
}) => {
  return (
    <p style={{ marginTop, ...style }} className="modal_text_primary">
      {text}
    </p>
  );
};

export const ModalSecondaryTitle: React.FC<ModalSecondaryTitleProps> = ({
  title,
  marginTop,
}) => {
  return (
    <p style={{ marginTop }} className="modal_secondary_title">
      {title}
    </p>
  );
};

export const ModalSelect: React.FC<ModalSelectProps> = ({
  options,
  marginTop,
  props,
}) => {
  return (
    <Select
      style={{
        marginTop,
        height: 37,
        width: "100%",
        outline: "none",
        borderRadius: 10,
        border: "1px solid #EBECEC",
        background: "#FFFFFF",
        ...props?.style,
      }}
      {...props}
      classes={{ select: `modal_select ${props?.className || ""}` }}
      sx={{
        height: 37,
        lineHeight: "37px",
        backgroundColor: "#FFFFFF !important",
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
        },
      }}
    >
      {options.map(
        (option: {
          value: string | number | readonly string[] | undefined;
          label?:
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | Iterable<ReactNode>
            | ReactPortal
            | null
            | undefined;
          icon?: string;
        }) => (
          <MenuItem value={option.value}>
            <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
              {option.icon && <img src={option.icon} alt="icon" />}
              {option.label && (
                <p
                  className={`modal_select_option ${
                    props.selectClassName ? props.selectClassName : ""
                  }`}
                >
                  {option.label}
                </p>
              )}
            </div>
          </MenuItem>
        )
      )}
    </Select>
  );
};

export const ModalButtonSecondary: React.FC<ModalButtonSecondaryProps> = ({
  title,
  marginTop,
  icon,
  onClick,
  styles,
}) => {
  return (
    <div
      style={{ marginTop }}
      className="modal_button_secondary"
      onClick={onClick}
    >
      {icon && <img src={icon} alt="icon" style={{ ...styles }} />}
      {title}
    </div>
  );
};

export const ModalButtonPrimary: React.FC<ModalButtonPrimaryProps> = ({
  title,
  marginTop,
  icon,
  onClick,
  styles,
}) => {
  return (
    <div
      style={{ marginTop, ...styles }}
      className="modal_button_primary"
      onClick={onClick}
    >
      {icon && <img src={icon} alt="icon" />}
      {title}
    </div>
  );
};

export const ModalGuestListItem: React.FC<ModalGuestListItemProps> = ({
  image,
  name,
  email,
  status,
  labelIcon,
  checkinTime,
}) => {
  return (
    <div className="modal_guest_list_item_container">
      <img
        src={image}
        alt="avatar"
        style={{ width: 30, height: 30, borderRadius: "100%" }}
      />
      <div style={{ flex: 1 }}>
        <p className="modal_guest_list_item_name">{name}</p>
        <p className="modal_guest_list_item_email" style={{ marginTop: -4 }}>
          {email}
        </p>
      </div>
      <div>
        <div className="modal_guest_list_item_status_container">
          <p className="modal_guest_list_item_status">{status}</p>
          {labelIcon && <img src={labelIcon} alt="label_icon" />}
        </div>
        {checkinTime && (
          <p className="modal_guest_list_item_checkin_time">
            <img src={GreenTickIcon} alt="tick" style={{ marginRight: 4 }} />
            {checkinTime}
          </p>
        )}
      </div>
    </div>
  );
};

export const CustomQuestionTitle: React.FC<CustomQuestionTitleProps> = ({
  title,
  marginTop,
}) => {
  return (
    <p className="custom_question_top_body_title" style={{ marginTop }}>
      {title}
    </p>
  );
};

export const CustomQuestionInput: React.FC<CustomQuestionInputProps> = ({
  placeholder,
  marginTop,
  props,
  error,
  helperText,
}) => {
  return (
    <>
      <input
        className={`custom_question_input ${
          error ? "custom_question_input_error" : ""
        }`}
        placeholder={placeholder}
        style={{ marginTop, ...props?.style }}
        {...props}
      />
      {helperText && (
        <p className="custom_question_input_helper_text">{helperText}</p>
      )}
    </>
  );
};

export const CustomQuestionOptions: React.FC<CustomQuestionOptionsProps> = ({
  options,
  activeOption,
  setActiveOption,
  marginTop,
}) => {
  return (
    <div className="custom_question_options_container" style={{ marginTop }}>
      {options.map(
        (
          option: {
            title: string;
            icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
            id: string;
          },
          index: number
        ) => (
          <div
            className={`custom_question_option_container ${
              activeOption === option.id
                ? "custom_question_option_container_active"
                : ""
            }`}
            key={option.id}
            onClick={() => setActiveOption(option.id)}
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            {index === 0 && (
              <div
                className="sliding-background"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background: "#fff",
                  borderRadius: "8px",
                  transition: "transform 0.3s ease-in-out",
                  transform: `translateX(${
                    options.findIndex((opt) => opt.id === activeOption) * 100
                  }%)`,
                  zIndex: -1,
                }}
              />
            )}
            {option.icon && (
              <option.icon
                className={`custom_question_option_icon ${
                  activeOption === option.id
                    ? "custom_question_option_icon_active"
                    : ""
                }`}
                width={16}
                height={16}
              />
            )}
            <p
              className={`custom_question_option_title ${
                activeOption === option.id
                  ? "custom_question_option_title_active"
                  : ""
              }`}
            >
              {option.title}
            </p>
          </div>
        )
      )}
    </div>
  );
};

export const CustomQuestionRequiredBlock: React.FC<
  CustomQuestionRequiredBlockProps
> = ({ marginTop, isRequired, setIsRequired, text }) => {
  return (
    <div className="custom_question_required_block" style={{ marginTop }}>
      <p className="custom_question_required_block_text">
        {text || "Required"}
      </p>
      <div
        id="slidingToggle"
        className={`toggle-container ${isRequired ? "active" : ""}`}
        onClick={() => setIsRequired(!isRequired)}
      >
        <div className="slider"></div>
      </div>
    </div>
  );
};

export const UserIcon: React.FC<UserIconProps> = ({
  width,
  height,
  padding,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: padding || 8,
        borderRadius: "100%",
        background: "#F2F2F2",
      }}
    >
      <img src={Usericon} alt="user_icon" style={{ width, height }} />
    </div>
  );
};
