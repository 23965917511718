import React, { useState } from "react";
import "./CreateEvent.css";
import CopyIcon from "../../resources/images/create_event/copy_icon.svg";
import { insightDivider } from "./Insights";
import EmbedButtonIcon from "../../resources/images/create_event/embed_button_icon.svg";
import EmbedPageIcon from "../../resources/images/create_event/embed_page_icon.svg";
import PinkCheckIcon from "../../resources/images/create_event/pink_check_icon.svg";
import CircleCrossIcon from "../../resources/images/create_event/circle_cross_icon.svg";
import CopyModalIcon from "../../resources/images/create_event/copy_modal_icon.svg";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import CircleIcon from "../../resources/images/create_event/circle_icon.svg";
import PlusIconSecondary from "../../resources/images/create_event/plus_icon_secondary.svg";
import RecurrenceIcon from "../../resources/images/create_event/recurrence_icon.svg";
import CopyIconLight from "../../resources/images/create_event/copy_icon_light.svg";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  ModalButtonPrimary,
  ModalButtonSecondary,
  ModalIcon,
  ModalSecondaryTitle,
  ModalSelect,
  ModalSubTitle,
  ModalTextPrimary,
  ModalTitle,
} from "./ModalComponent";
import MaterialModal from "./MaterialModal";

export default function More() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const cloneEventModal = () => {
    return (
      <MaterialModal
        isOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
        props={{ style: { width: 326 } }}
        paperStyles={{ maxWidth: 326 }}
      >
        <div className="clone_event_modal_container">
          <div className="clone_event_modal_container_left">
            <ModalIcon icon={CopyModalIcon} width={54} padding={11} />
            <ModalIcon
              icon={CrossModalIcon}
              width={28}
              padding={4}
              style={{ cursor: "pointer" }}
              onClick={() => setModalIsOpen(false)}
            />
          </div>
          <ModalTitle title="Clone Event" marginTop={9} />
          <ModalSubTitle title="Spiti Overlanding" marginTop={2} />
          <ModalTextPrimary
            text="Everything except the guest list and event blasts will be copied over."
            marginTop={4}
          />
          <ModalSecondaryTitle title="Calendar" marginTop={13} />
          <div className="clone_event_modal_dropdown_container">
            <ModalSelect
              options={[
                { value: "1", label: "January", icon: CircleIcon },
                { value: "2", label: "February", icon: CircleIcon },
                { value: "3", label: "March", icon: CircleIcon },
              ]}
              props={{
                defaultValue: "1",
              }}
            />
            <ModalSelect
              options={Array.from({ length: 30 }, (_, i) => ({
                value: String(i + 1),
                label: String(i + 1),
              }))}
              props={{
                defaultValue: "1",
                style: {
                  width: "auto",
                },
              }}
            />
          </div>
          <ModalSecondaryTitle title="New Time" marginTop={13} />
          <div className="clone_event_modal_time_container">
            <p
              className="clone_event_modal_time_container_text"
              style={{ flex: 1 }}
            >
              GMT+5:30
            </p>
            <div
              className="clone_event_modal_time_container_line"
              style={{ marginRight: "12px" }}
            />
            <p className="clone_event_modal_time_container_text">07:30 PM</p>
          </div>
          <div className="clone_event_modal_button_container">
            <ModalButtonSecondary title="Add Time" icon={PlusIconSecondary} />
            <ModalButtonSecondary title="Recurrence" icon={RecurrenceIcon} />
          </div>
          <ModalButtonPrimary
            title="Clone Event"
            icon={CopyIconLight}
            onClick={() => setModalIsOpen(false)}
            marginTop={13}
          />
        </div>
      </MaterialModal>
    );
  };
  const moreHeader = () => {
    return (
      <div className="more_header_container">
        <div className="insight_header_container">
          <p className="insight_header_text">Clone Event</p>
        </div>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Create a new event with the same information as this one. Everything
          except the guest list and event blasts will be copied over.
        </p>
        <button
          className="more_button"
          style={{ marginTop: 21 }}
          onClick={() => setModalIsOpen(true)}
        >
          <img src={CopyIcon} alt="copy_icon" />
          Clone Event
        </button>
      </div>
    );
  };
  const [event_slug, setEventSlug] = useState("spiti-overlanding");

  const eventPageUrl = () => {
    return (
      <div className="event_page_url_container">
        <p className="insight_header_text">Event Page URL</p>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          When you choose a new URL, the current one will no longer work. Do not
          change your URL if you have already shared the event.
        </p>
        <div className="event_page_url_input_container">
          <div className="event_page_url_input_container_left">
            <p className="event_page_url_input_container_left_text">
              thrivia.io/
            </p>
          </div>
          <input
            className="event_page_url_input_container_right"
            value={event_slug}
            onChange={(e) => setEventSlug(e.target.value)}
          />
          <button className="event_page_url_input_container_right_button">
            Update
          </button>
        </div>
      </div>
    );
  };

  const embedEvent = () => {
    const embedButton = (top: number, icon: string, text: string) => {
      return (
        <div className="embed_button_container" style={{ marginTop: top }}>
          <img src={icon} alt="embed_icon" />
          <p className="embed_button_text">{text}</p>
          <img src={PinkCheckIcon} alt="pink_check_icon" />
        </div>
      );
    };
    return (
      <div className="embed_event_container">
        <p className="insight_header_text">Embed Event</p>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Have your own site? Embed the event to let visitors know about it.
        </p>
        {embedButton(19, EmbedButtonIcon, "Embed as button")}
        {/* {embedButton(5, EmbedPageIcon, "Embed Event Page")} */}
        <p
          className="embed_event_subtext"
          style={{ marginTop: 33, marginBottom: 19 }}
        >
          Paste the following HTML code snippet to your page:
        </p>
        <div className="embed_event_code_container">
          <SyntaxHighlighter
            language="html"
            style={solarizedlight}
            customStyle={{ backgroundColor: "#fff", margin: 0 }}
          >
            {`<a
  href="https://thrivia.io/${event_slug}"
  className="thrivia-button"
  data-thrivia-action="checkout"
  data-thrivia-event-id="evt-HWEqESKKCwbSH3E"
>
  Register for Event
</a>

<script id="thrivia-checkout" src="https://embed.thrivia.io/checkout-button.js"></script>`}
          </SyntaxHighlighter>
        </div>
        <p
          className="embed_event_subtext"
          style={{ marginTop: 15, marginBottom: 23 }}
        >
          This gives you the following button. Click it to see it in action!
        </p>
        <div className="preview_button_container">
          <button className="preview_button">Register for event</button>
        </div>
        <p className="preview_info_text">
          If you want to use your own styling for the button, simply remove the
          <span className="preview_info_text_span_1"> thrivia-button</span>{" "}
          class from the snippet above.
          <br /> <br />
          For advanced usage, check out our{" "}
          <span className="preview_info_text_span_2">
            {" "}
            example code and documentation.
          </span>
        </p>
      </div>
    );
  };

  const cancelEvent = () => {
    return (
      <div className="cancel_event_container">
        <p className="insight_header_text">Cancel Event</p>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Cancel and permanently delete this event. This operation cannot be
          undone. If there are any registered guests, we will notify them that
          the event has been cancelled.
        </p>
        <button className="cancel_event_button">
          <img src={CircleCrossIcon} alt="circle_cross_icon" />
          Cancel Event
        </button>
      </div>
    );
  };
  return (
    <div>
      {moreHeader()}
      {insightDivider(22, 21)}
      {eventPageUrl()}
      {insightDivider(28, 21)}
      {embedEvent()}
      {insightDivider(25, 21)}
      {cancelEvent()}
      {cloneEventModal()}
    </div>
  );
}
