import axios, { AxiosRequestConfig } from "axios";
import { refreshToken } from "./RefreshToken";
import { apiurl } from "./ApiHelper";

export async function authenticatedRequest<T>(
  endpoint: string,
  options: AxiosRequestConfig = {}
): Promise<T> {
  const token = localStorage.getItem("authToken");
  if (!token) {
    throw new Error("Unauthorized");
  }

  try {
    const response = await axios({
      ...options,
      url: `${apiurl}${endpoint}`,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      // Token is invalid, try to refresh
      try {
        await refreshToken();
        const newToken = localStorage.getItem("authToken");
        // Retry the request with new token
        const response = await axios({
          ...options,
          url: `${apiurl}${endpoint}`,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${newToken}`,
          },
        });
        return response.data;
      } catch (refreshError) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        throw refreshError;
      }
    }
    throw error;
  }
}
