import React, { useEffect, useState } from "react";
import styles from "./Auth.module.css";
import MaterialModal from "../CreateEvent/MaterialModal";
import LoginModalIcon from "../../resources/images/eventv2/login_modal_icon.svg";
import {
  CustomQuestionTitle,
  ModalButtonPrimary,
  ModalButtonSecondary,
  ModalIcon,
  ModalTextPrimary,
} from "../CreateEvent/ModalComponent";
import ModalChevronIcon from "../../resources/images/create_event/modal_chevron_icon.svg";
// import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import { CustomQuestionInput } from "../CreateEvent/ModalComponent";
import GoogleLoginIcon from "../../resources/images/eventv2/google_login_icon.svg";
import axios from "axios";
import { apiurl } from "../../commons/ApiHelper";
export default function LoginModal({
  isOpen,
  onClose,
  title,
  text,
  onLoginSuccess,
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  text: string;
  onLoginSuccess: () => void;
}) {
  const [currentScreen, setCurrentScreen] = useState("login");
  const [input, setInput] = useState({
    input: "",
    error: false,
    helperText: "",
  });

  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [seconds, setSeconds] = useState(60);
  const [canResend, setCanResend] = useState(false);

  const handleInputChange = (value: string) => {
    console.log(value);
    setInput({
      input: value,
      error: false,
      helperText: "",
    });
  };

  const isValidEmailTest = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const isValidPhoneTest = (value: string) => {
    const phoneRegex = /^[0-9]{10}$/; // Assumes 10-digit phone number
    return phoneRegex.test(value);
  };

  const sendOTP = async () => {
    axios
      .post(apiurl + "auth/send-otp", {
        input: input.input,
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setCurrentScreen("otp");
          setSeconds(60);
        }
      })
      .catch((err) => {
        console.log(err);
        setInput({
          input: input.input,
          error: true,
          helperText: "Something went wrong, please try again later",
        });
      });
  };

  const handleSendOTP = () => {
    const value = input.input;
    console.log(value);
    const isValidEmail: boolean = isValidEmailTest(value);
    const isValidPhone: boolean = isValidPhoneTest(value);

    if (value === "") {
      setInput({
        input: value,
        error: true,
        helperText: "Please enter a valid email or phone number",
      });
    } else if (!isValidEmail && !isValidPhone) {
      setInput({
        input: value,
        error: true,
        helperText: "Please enter a valid email or phone number",
      });
    } else {
      sendOTP();
    }
  };

  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState("Invalid code try again");
  const handleVerifyOTP = (otp: Array<string>) => {
    console.log(otp.join(""));
    axios
      .post(apiurl + "auth/verify-otp", {
        otp: otp.join(""),
        input: input.input,
      })
      .then((res) => {
        console.log("116", res.data);
        if (res.data.success) {
          localStorage.setItem("authToken", res.data.authToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("user_id", res.data.user_id);
          onLoginSuccess();
          onClose();
        } else {
          setOtpError(true);
          setOtpErrorText("Invalid code try again");
        }
      })
      .catch((err) => {
        console.log(err);
        setOtpError(true);
        setOtpErrorText("Something went wrong, please try again later");
      });
  };

  const loginScreen = () => {
    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        sendOTP();
      }
    };
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ModalIcon
            icon={LoginModalIcon}
            width={32}
            padding={16}
            iconStyles={{ height: 32, aspectRatio: 19 / 21, marginLeft: 4 }}
          />
        </div>
        <p className="modal_title" style={{ marginTop: 20, fontSize: 22 }}>
          {title}
        </p>
        <ModalTextPrimary text={text} marginTop={8} />
        <CustomQuestionTitle title="Phone or Email" marginTop={16} />
        <CustomQuestionInput
          placeholder="Enter your phone or email"
          marginTop={8}
          props={{
            value: input.input,
            onChange: (e: any) => handleInputChange(e.target.value),
            onKeyPress: (e: any) => handleKeyPress(e),
          }}
          error={input.error}
          helperText={input.helperText}
        />
        <ModalButtonPrimary
          title="Continue"
          marginTop={16}
          onClick={handleSendOTP}
        />
        <div
          className={styles.auth_or_block_line}
          style={{ marginTop: 16, marginBottom: 16 }}
        />
        <ModalButtonSecondary
          title="Continue with Google"
          onClick={() => {}}
          icon={GoogleLoginIcon}
          styles={{ width: 16 }}
        />
      </>
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (seconds > 0 && !canResend) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else if (seconds === 0) {
      setCanResend(true);
    }
    return () => clearInterval(timer);
  }, [seconds, canResend]);

  const handleResendOTP = () => {
    sendOTP();
    setSeconds(60);
    setCanResend(false);
  };
  const otpScreen = () => {
    // Add new handler for OTP input changes
    const handleOtpChange = (index: number, value: string) => {
      setOtpError(false);
      if (value.length > 1) return; // Only allow single digit
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);

      // Auto-focus next input if value is entered
      if (value !== "" && index < 5) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        nextInput?.focus();
      }
      if (value !== "" && index === 5) {
        const allFilled = newOtpValues.every((val) => val !== "");
        console.log(allFilled, newOtpValues);
        if (allFilled) {
          handleVerifyOTP(newOtpValues);
        }
      }
    };

    // Add handler for backspace
    const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
      if (e.key === "Backspace" && otpValues[index] === "" && index > 0) {
        const prevInput = document.getElementById(`otp-${index - 1}`);
        prevInput?.focus();
      }
    };
    const formatPhoneNumber = (phoneNumber: string) => {
      const cleaned = phoneNumber.replace(/\D/g, "");
      return `+91 ${cleaned.slice(0, 5)} ${cleaned.slice(5, 10)}`;
    };

    return (
      <>
        <ModalIcon
          icon={ModalChevronIcon}
          width={14}
          padding={7}
          onClick={() => setCurrentScreen("login")}
        />
        <p className="modal_title" style={{ marginTop: 24, fontSize: 22 }}>
          Enter Code
        </p>
        <p className="modal_text_primary" style={{ marginTop: 16 }}>
          Please enter the code sent to{" "}
          <span style={{ color: "#000" }}>
            {isValidEmailTest(input.input)
              ? input.input
              : formatPhoneNumber(input.input)}
          </span>
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
          }}
        >
          {otpValues.map((value, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="number"
              inputMode="numeric"
              pattern="\d*"
              maxLength={1}
              value={value}
              onChange={(e) => handleOtpChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={(e) => {
                e.preventDefault();
                const pastedData = e.clipboardData.getData("text");
                const numericData = pastedData.replace(/\D/g, "").slice(0, 6);
                if (numericData.length === 6) {
                  const otpArray = numericData.split("");
                  setOtpValues([...otpArray]);
                  document.getElementById(`otp-5`)?.focus();
                  handleVerifyOTP(otpArray);
                }
              }}
              className="otp_input"
            />
          ))}
        </div>
        {/* <ModalButtonPrimary title="Continue" marginTop={16} /> */}
        {otpError && (
          <p
            className="modal_text_primary"
            style={{ marginTop: 4, marginBottom: 8, color: "red" }}
          >
            {otpErrorText}
          </p>
        )}
        {canResend ? (
          <p
            className="modal_text_primary"
            style={{ marginTop: 16, cursor: "pointer", textAlign: "right" }}
            onClick={handleResendOTP}
          >
            Resend Code
          </p>
        ) : (
          <p
            className="modal_text_primary"
            style={{ marginTop: 16, cursor: "pointer", textAlign: "right" }}
            onClick={handleResendOTP}
          >
            Resend code in {seconds}s
          </p>
        )}
      </>
    );
  };

  return (
    <MaterialModal
      isOpen={isOpen}
      onClose={onClose}
      paperStyles={{ maxWidth: 450 }}
    >
      <div style={{ padding: 24 }}>
        {currentScreen === "login" ? loginScreen() : otpScreen()}
        {/* <SocialLoginReact /> */}
      </div>
    </MaterialModal>
  );
}
