import React from "react";
import MaterialModal from "./MaterialModal";
import { ModalGuestListItem } from "./ModalComponent";
import GreenEditIcon from "../../resources/images/create_event/green_edit_icon.svg";
import GreenTickIcon from "../../resources/images/create_event/green_tick_icon.svg";
import "./Modal.css";

interface Guest {
  image: string;
  name: string;
  email: string;
  status: string;
  checkinTime?: string;
  registeredTime?: string;
}

export default function GuestPopup({
  isOpen,
  onClose,
  guest,
}: {
  isOpen: boolean;
  onClose: () => void;
  guest: Guest;
}) {
  return (
    <div style={{ borderRadius: 21 }}>
      <MaterialModal isOpen={isOpen} onClose={onClose}>
        <div style={{ padding: "12px 20px" }}>
          <ModalGuestListItem
            image={guest.image}
            name={guest.name}
            email={guest.email}
            status={guest.status}
            checkinTime={guest.checkinTime}
            labelIcon={GreenEditIcon}
          />
        </div>
        <div className="guest_popup_body_container">
          <p className="guest_popup_body_title">Registered</p>
          <p className="guest_popup_body_subtitle">{guest.registeredTime}</p>
          <div className="guest_popup_checkin_container">
            <img
              src={GreenTickIcon}
              alt="tick"
              style={{ width: 16, height: 13 }}
            />
            <div>
              <p className="guest_popup_checkin_title">
                Checked in {guest.checkinTime}
              </p>
              <p className="guest_popup_checkin_title">
                {guest.registeredTime}
              </p>
            </div>
          </div>
        </div>
        <div className="guest_popup_footer_container">
          <button
            className="guest_popup_footer_cancel_button"
            onClick={onClose}
          >
            Cancel
          </button>
          {!guest.checkinTime && (
            <button className="guest_popup_footer_checkin_button">
              Check In
            </button>
          )}
          {guest.checkinTime && (
            <button className="guest_popup_footer_checkin_button">
              Undo checkin
            </button>
          )}
        </div>
      </MaterialModal>
    </div>
  );
}
