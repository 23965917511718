import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import styles from "./BookingConfirmed.module.css";
import "./EventV3.css";
import { ModalTitle } from "../CreateEvent/ModalComponent";
import axios from "axios";
import { apiurl } from "../../commons/ApiHelper";
import { useParams } from "react-router-dom";
import { IBookingDetails } from "../../commons/Interfaces";

const divider = (margin: string) => {
  return (
    <div style={{ height: 1, background: "#E3E4E5", margin: margin }}></div>
  );
};

export default function BookingConfirmed() {
  const [bookingDetails, setBookingDetails] = useState<IBookingDetails | null>(
    null
  );

  const { booking_id } = useParams();

  useEffect(() => {
    let pollCount = 0;
    let pollInterval: NodeJS.Timeout;

    // Initial fetch of booking details
    const fetchBookingDetails = async () => {
      try {
        const res = await axios.get(apiurl + `events/booking/${booking_id}`);
        console.log(res.data);
        setBookingDetails(res.data);
        return res.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    };

    // Fetch payment status
    const fetchPaymentStatus = async (orderId: string) => {
      try {
        const res = await axios.get(apiurl + `payments/status/${orderId}`);
        console.log(res.data);
        return res.data?.status || "pending";
      } catch (err) {
        console.log(err);
        return "pending";
      }
    };

    const startPolling = async () => {
      // First, get the initial booking details
      const details = await fetchBookingDetails();
      console.log(details);
      if (!details || !details.order_id) return;

      // Then start polling the payment status
      const checkPaymentStatus = async () => {
        const paymentStatus = await fetchPaymentStatus(details.order_id);

        if (paymentStatus === "pending" && pollCount < 120) {
          // 120 attempts * 5 seconds = 10 minutes
          pollCount++;
          pollInterval = setTimeout(checkPaymentStatus, 5000); // Poll every 5 seconds
        } else if (paymentStatus === "pending" && pollCount >= 120) {
          // If we've reached max attempts, set timeout flag
          setBookingDetails((prev) =>
            prev ? { ...prev, payment_status: "failed" } : null
          );
        } else {
          // If status is success or failed, update booking details
          setBookingDetails((prev) =>
            prev ? { ...prev, payment_status: paymentStatus } : null
          );
        }
      };

      // Start polling if initial payment status is pending
      if (details.payment_status === "pending") {
        checkPaymentStatus();
      }
    };

    startPolling();

    // Cleanup on unmount
    return () => {
      if (pollInterval) clearTimeout(pollInterval);
    };
  }, [booking_id]);

  const textPrimary = (style: React.CSSProperties, text: string) => {
    return (
      <p className={styles.text_primary} style={{ ...style }}>
        {text}
      </p>
    );
  };

  const pendingMessage = () => {
    return (
      <div className={styles.payment_pending_container}>
        <p className={styles.payment_pending_text}>
          Please wait while we confirm your payment. This page will update
          automatically.
        </p>
      </div>
    );
  };

  const failedMessage = () => {
    return (
      <div className={styles.payment_failed_container}>
        <p className={styles.payment_failed_text}>
          Payment verification timed out. If any amount was deducted, it will be
          refunded within 5-7 business days.
        </p>
      </div>
    );
  };

  const handleRetryPayment = () => {
    if (!bookingDetails) return;

    axios
      .post(apiurl + `payments/create-order`, {
        amount: bookingDetails.total_price,
      })
      .then((res) => {
        const data = res.data;
        const options = {
          key: "rzp_test_f2jdX5aXSokGKe",
          amount: data.amount,
          currency: "INR",
          name: "Thrivia",
          description: "Test Transaction",
          order_id: data.id,
          handler: function (response: {
            razorpay_payment_id: string;
            razorpay_order_id: string;
            razorpay_signature: string;
          }) {
            // Create a new booking with the same details but new order_id
            axios
              .post(apiurl + `events/${bookingDetails.eventId}/book`, {
                ticket_types: bookingDetails.ticket_types.reduce(
                  (acc, ticket) => {
                    acc[ticket.type] = ticket.count;
                    return acc;
                  },
                  {} as { [key: string]: number }
                ),
                total_price: bookingDetails.total_price,
                responses: bookingDetails.responses,
                name: bookingDetails.name,
                email: bookingDetails.email,
                order_id: data.id,
              })
              .then((bookingRes) => {
                // Redirect to the new booking confirmation page
                window.location.href = `/booking_confirmed/${bookingRes.data.booking_id}`;
              })
              .catch((err) => {
                console.error("Booking creation failed:", err);
                alert("Error creating booking. Please try again.");
              });
          },
          prefill: {
            name: bookingDetails.name,
            email: bookingDetails.email,
          },
          theme: { color: "#3399cc" },
        };

        const razor = new (window as any).Razorpay(options);
        razor.open();
      })
      .catch((err) => {
        console.log(err);
        alert("Error creating payment order. Please try again.");
      });
  };

  const topSection = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
        <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
          <ModalTitle
            title={
              bookingDetails?.payment_status === "success"
                ? "Booking Confirmed"
                : bookingDetails?.payment_status === "failed"
                ? "Payment Failed"
                : "Payment Pending"
            }
          />
        </div>
        {divider("0px")}
        <div className="book_now_event_detail_container">
          <img
            src={bookingDetails?.cover_photo}
            alt="test_img"
            style={{
              width: 118,
              height: 118,
              borderRadius: 20,
              boxShadow: "0px 4.124px 4.124px 0px rgba(0, 0, 0, 0.25)",
              objectFit: "cover",
            }}
          />
          <div className="book_now_event_detail_container_right">
            <p className="book_now_event_detail_container_right_title">
              {bookingDetails?.title}
            </p>

            <div style={{ marginTop: 8 }}>
              <p className={styles.text_primary_1} style={{ color: "#000" }}>
                {new Date(bookingDetails?.start_date || "").toLocaleDateString(
                  "en-US",
                  {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }
                )}
              </p>
              <p className={styles.text_primary_1} style={{ color: "#000" }}>
                {bookingDetails?.creator_name}
              </p>
              <p
                className={styles.text_secondary}
                style={{ marginTop: 0, color: "#000" }}
              >
                {bookingDetails?.location.city}
              </p>
              <p
                className={styles.text_secondary}
                style={{ marginTop: 8, color: "#000" }}
              >
                {new Date(bookingDetails?.start_date || "").toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )}{" "}
                -{" "}
                {new Date(bookingDetails?.end_date || "").toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )}
              </p>
            </div>
          </div>
        </div>
        {bookingDetails?.payment_status === "pending" && pendingMessage()}
        {bookingDetails?.payment_status === "failed" && failedMessage()}
        {bookingDetails?.payment_status === "success" ? (
          <button
            className={styles.button_primary}
            onClick={() => {
              window.location.href = `/event/${bookingDetails?.event_slug}`;
            }}
          >
            View Itinerary
          </button>
        ) : bookingDetails?.payment_status === "failed" ? (
          <button
            className={styles.button_primary}
            onClick={handleRetryPayment}
          >
            Try Booking Again
          </button>
        ) : null}
        {divider("0px")}
      </div>
    );
  };

  const flexDivs = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 24,
          marginTop: 24,
        }}
      >
        <div>
          <div className={styles.flex_div}>
            {textPrimary({ fontWeight: 700 }, "Location")}
            {textPrimary(
              {
                textDecoration: "underline",
                fontWeight: 700,
                cursor: "pointer",
              },
              "Get Directions"
            )}
          </div>
          {textPrimary(
            { marginTop: 12 },
            bookingDetails?.location.address +
              ", " +
              bookingDetails?.location.city
          )}
          {divider("24px 0px 16px")}
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <div className={styles.flex_div}>
              {textPrimary({ fontWeight: 700 }, "Guests")}
              {textPrimary(
                {},
                bookingDetails?.ticket_types.reduce(
                  (acc, curr) => acc + curr.count,
                  0
                ) + " Persons"
              )}
            </div>
            <div className={styles.flex_div}>
              {textPrimary({ fontWeight: 700 }, "Ticket Type")}
              <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                {bookingDetails?.ticket_types.map((ticket) => (
                  <div style={{ textAlign: "right" }}>
                    {textPrimary({}, ticket.type + " x " + ticket.count)}
                  </div>
                ))}
              </div>
            </div>
            {divider("0px")}
            <div className={styles.flex_div}>
              {textPrimary({ fontWeight: 700 }, "Amount Paid")}
              {textPrimary(
                { textDecoration: "underline", fontWeight: 700 },
                bookingDetails?.total_price?.toString() || "0"
              )}
            </div>
            {divider("0px")}
            <div className={styles.flex_div}>
              {textPrimary({ fontWeight: 700 }, "Booking Reference")}
              {textPrimary({}, bookingDetails?.booking_reference || "0")}
            </div>
            {divider("0px")}
          </div>
        </div>
        <p className="book_now_modal_heading"> Cancellation Policy</p>
        <p className="book_now_modal_text_secondary">
          Free cancellation for 48 hours. Cancel before{" "}
          {new Date(bookingDetails?.start_date || "").toLocaleDateString(
            "en-US",
            {
              month: "short",
              day: "numeric",
            }
          )}{" "}
          for a partial refund.{" "}
          <span
            style={{
              color: "#000",
              fontWeight: 700,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Learn more
          </span>
        </p>
        {divider("0px 0px")}
        <p className="book_now_modal_heading">Ground Rules</p>
        <p className="book_now_modal_text_secondary">
          We ask every guest to remember a few simple things about what makes a
          great guest. Treat your Host's home like your own
          <ul>
            <li>Follow the house rules</li>
            <li>Treat the property with care</li>
            <li>Be considerate of other guests</li>
            <li>Leave the property in the same condition as you found it</li>
          </ul>
        </p>
        {divider("0px 0px")}
      </div>
    );
  };
  return (
    <div>
      <Navbar />
      <div
        style={{
          padding: 24,
          maxWidth: 800,
          margin: "0px auto",
          paddingTop: "calc(var(--navbar-offset) + 24px)",
          transition: "all 0.3s ease-in-out",
        }}
      >
        {topSection()}
        {flexDivs()}
      </div>
    </div>
  );
}
