import React, { useState, useEffect } from "react";
import ThriviaLogo from "../../resources/images/eventv2/thrivia_logo.svg";
import ShareIconNew from "../../resources/images/eventv2/share_icon_new.svg";
// import ColorBg from "../../resources/images/eventv2/colorbg.png";
import styles from "./Navbar.module.css";
import LoginModal from "../authv2/LoginModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface CustomWindow extends Window {
  _lastScrollY: number;
  _ticking: boolean;
}
declare let window: CustomWindow;

window._lastScrollY = window.scrollY;
window._ticking = false;

export default function Navbar({
  style,
  navIcon,
  isCreate,
}: {
  style?: React.CSSProperties;
  navIcon?: () => React.ReactNode;
  isCreate?: boolean;
}) {
  const [visible, setVisible] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--navbar-offset",
      visible ? "48px" : "0px"
    );
  }, [visible]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (!window._ticking) {
        window.requestAnimationFrame(() => {
          if (currentScrollY <= 0) {
            setVisible(true);
          } else {
            setVisible(window._lastScrollY > currentScrollY);
          }
          window._lastScrollY = currentScrollY;
          window._ticking = false;
        });

        window._ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Thrivia",
          text: "Check out this event on Thrivia!",
          url: window.location.href,
        });
      } catch (error) {
        console.log("Error sharing:", error);
      }
    } else {
      // Fallback for browsers that don't support Web Share API
      console.log("Web Share API not supported");
    }
  };

  const handleCreateClick = () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      window.location.href = "/create";
    } else {
      setIsLoginModalOpen(true);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyEventsClick = () => {
    window.location.href = "/creator/events";
    handleMenuClose();
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user_id");
    window.location.href = "/";
    handleMenuClose();
  };

  return (
    <div
      className={`${styles.navbar_container} ${
        !visible ? styles.navbar_hidden : ""
      }`}
      style={{
        transform: visible ? "translateY(0)" : "translateY(-100%)",
        ...style,
      }}
    >
      <img src={ThriviaLogo} alt="thrivia_logo" style={{ cursor: "pointer" }} />
      <p
        className={styles.logo_text}
        onClick={() => {
          window.location.href = "/";
        }}
        style={{ cursor: "pointer", marginLeft: isCreate ? 10.6 : 0 }}
      >
        thrivia
      </p>
      {navIcon ? (
        <div
          onClick={handleMenuClick}
          style={{ cursor: "pointer", height: 24 }}
        >
          {navIcon()}
        </div>
      ) : isCreate ? (
        <p
          style={{
            fontSize: 14,
            textDecoration: "underline",
            cursor: "pointer",
            color: "#000",
            fontFamily: "Lato",
          }}
          onClick={handleCreateClick}
        >
          Create
        </p>
      ) : (
        <img
          src={ShareIconNew}
          alt="share_icon"
          style={{ cursor: "pointer" }}
          onClick={handleShare}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            bgcolor: "white",
            minWidth: "150px",
          },
        }}
      >
        <MenuItem
          onClick={handleMyEventsClick}
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          My Events
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleLoginModalClose}
        title="Login"
        text="Please login to continue"
        onLoginSuccess={() => {
          window.location.href = "/create";
        }}
      />
    </div>
  );
}
