import axios from "axios";

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const response = await axios.post(
    "http://localhost:5005/api/auth/refresh-token",
    {
      token: refreshToken,
    }
  );
  if (response.data.accessToken) {
    localStorage.setItem("authToken", response.data.accessToken);
    return true;
  }
};
