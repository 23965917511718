import React, { useRef, useState } from "react";
import "./CreateEvent.css";
import InviteIcon from "../../resources/images/create_event/invite_icon.svg";
import CheckinIcon from "../../resources/images/create_event/checkin_icon.svg";
import PeopleIcon from "../../resources/images/create_event/people_icon.svg";
import DownloadIcon from "../../resources/images/create_event/download_icon.svg";
import OpenIcon from "../../resources/images/create_event/open_icon.svg";
import SearchIcon from "../../resources/images/create_event/search_icon.svg";
import FilterIcon1 from "../../resources/images/create_event/filter_icon_1.svg";
import FilterIcon2 from "../../resources/images/create_event/filter_icon_2.svg";
import ChevronDown from "../../resources/images/create_event/chevron_down.svg";
import GuestScanIcon from "../../resources/images/create_event/guest_scan_icon.svg";
import NinjaStarIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import GuestSearchIcon from "../../resources/images/create_event/guest_search_icon.svg";
// import Cropper from "./Cropper";
import UKProfile from "../../resources/images/create_event/uk_profile.jpeg";
import AsthaProfile from "../../resources/images/create_event/astha_profile.svg";
import MaterialModal from "./MaterialModal";
import {
  ModalButtonPrimary,
  ModalGuestListItem,
  ModalIcon,
  ModalTextPrimary,
  ModalTitle,
} from "./ModalComponent";
import PeopleIconLight from "../../resources/images/create_event/people_icon_light.svg";
import GuestPopup from "./GuestPopup";

export default function OverView() {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [guestListModalOpen, setGuestListModalOpen] = useState(false);
  const [checkinModalOpen, setCheckinModalOpen] = useState(false);
  const [hideGuestListModalOpen, setHideGuestListModalOpen] = useState(false);
  const [modalGuestFilter, setModalGuestFilter] = useState("all_guests");
  const [guestPopupOpen, setGuestPopupOpen] = useState(false);
  const [guest, setGuest] = useState({
    name: "Utkarsh Koushik",
    email: "utkarshk@gmail.com",
    status: "Going",
    checkinTime: "this minute",
    image: UKProfile,
    registeredTime: new Date().toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }),
  });

  const hideGuestListModal = () => {
    return (
      <MaterialModal
        isOpen={hideGuestListModalOpen}
        onClose={() => {
          setHideGuestListModalOpen(false);
        }}
        paperStyles={{ maxWidth: 326 }}
      >
        <div style={{ padding: 18, maxWidth: 326 }}>
          <ModalIcon icon={PeopleIconLight} width={32} padding={12} />
          <ModalTitle title="Public Guest List" marginTop={8} />
          <ModalTextPrimary
            text="Display the guest count and a few guests on the event page."
            marginTop={6}
            style={{ fontWeight: 700 }}
          />
          <ModalTextPrimary
            text="Even when it is turned on, only registered guests can access the full list."
            marginTop={8}
            style={{ fontWeight: 700 }}
          />
          <ModalButtonPrimary
            title="Hide Guest List"
            marginTop={18}
            onClick={() => {
              setGuestListModalOpen(false);
            }}
          />
        </div>
      </MaterialModal>
    );
  };

  const checkinModal = () => {
    return (
      <MaterialModal
        isOpen={checkinModalOpen}
        onClose={() => {
          setCheckinModalOpen(false);
        }}
        paperStyles={{ maxWidth: 326 }}
      >
        <div style={{ padding: 18, maxWidth: 326 }}>
          <ModalIcon icon={PeopleIconLight} width={32} padding={12} />
          <ModalTitle title="Check In Guests" marginTop={8} />
          <ModalTextPrimary
            text="You can check in guests with our web scanner."
            marginTop={6}
            style={{ fontWeight: 700 }}
          />

          <ModalButtonPrimary
            title="Open Web Scanner"
            marginTop={18}
            onClick={() => {
              setGuestListModalOpen(false);
            }}
          />
        </div>
      </MaterialModal>
    );
  };

  const guestListModal = () => {
    const guestFilterOptions = [
      {
        id: "all_guests",
        title: "All Guests",
      },
      {
        id: "going",
        title: "Going",
      },
      {
        id: "checked_in",
        title: "Checked In",
      },
      {
        id: "not_going",
        title: "Not Going",
      },
      {
        id: "pending",
        title: "Pending",
      },
    ];

    const guests = [
      {
        name: "Utkarsh Koushik",
        email: "utkarshkoushik@gmail.com",
        status: "Going",
        checkinTime: "this minute",
        image: UKProfile,
      },
      {
        name: "Astha Agarwal",
        email: "asthaagarwal@gmail.com",
        status: "Going",
        checkinTime: "this minute",
        image: AsthaProfile,
      },
    ];
    return (
      <MaterialModal
        isOpen={guestListModalOpen}
        onClose={() => {
          setGuestListModalOpen(false);
        }}
        fullScreen={true}
      >
        <div>
          <div
            className="guest_list_modal_header"
            style={{ padding: "8px 16px" }}
          >
            {/* <img
              src={NinjaStarIcon}
              alt="ninja_star_icon"
              onClick={() => setGuestListModalOpen(false)}
            /> */}
            <ModalIcon
              icon={NinjaStarIcon}
              width={24}
              padding={4}
              onClick={() => setGuestListModalOpen(false)}
            />
            <div style={{ flex: 1 }}>
              <p className="guest_list_modal_header_title">Spiti Overlanding</p>
              <p className="guest_list_modal_header_subtitle">
                Started just now
              </p>
            </div>
            <button className="guest_list_modal_header_button">
              <img src={GuestScanIcon} alt="guest_scan_icon" />
              Scan
            </button>
          </div>
          <div className="modal_guest_list_search_container">
            <img src={GuestSearchIcon} alt="guest_search_icon" />
            <input
              type="text"
              className="modal_guest_list_search_input"
              placeholder=" Search for a Guest..."
            />
          </div>
          <div>
            <div className="modal_filter_guest_options">
              {guestFilterOptions.map((option, index) => (
                <div
                  className="modal_filter_guest_options_item"
                  key={"guest_list" + index}
                  style={{
                    borderBottom:
                      modalGuestFilter !== option.id
                        ? "none"
                        : "2px solid #000",
                  }}
                >
                  <p
                    className={`modal_filter_guest_options_item_text ${
                      modalGuestFilter === option.id
                        ? "modal_filter_guest_options_item_text_active"
                        : ""
                    }`}
                    onClick={() => setModalGuestFilter(option.id)}
                  >
                    {option.title}
                  </p>
                </div>
              ))}
            </div>
            <div className="modal_filter_guest_options_line" />
            <div
              style={{
                padding: "15px 16px 0px",
                display: "flex",
                flexDirection: "column",
                gap: 15,
              }}
            >
              {guests.map((guest, index) => (
                <div
                  onClick={() => setGuestPopupOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  <ModalGuestListItem
                    image={guest.image}
                    name={guest.name}
                    email={guest.email}
                    status={guest.status}
                    checkinTime={guest.checkinTime}
                  />
                  {index !== guests.length - 1 && (
                    <div
                      className="modal_filter_guest_options_line"
                      style={{ marginLeft: 49, marginRight: 49, marginTop: 12 }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </MaterialModal>
    );
  };
  const glance = () => {
    return (
      <div className="glance_container">
        <p className="glance_container_title_text">At a glance</p>
        <div className="glance_container_content">
          <div className="glance_container_content_item">
            <p className="glance_container_content_item_header">
              2{" "}
              <span className="glance_container_content_item_header_secondary">
                guest
              </span>
            </p>
            <p className="glance_container_content_item_secondary">
              cap{" "}
              <span className="glance_container_content_item_secondary_secondary">
                20
              </span>
            </p>
          </div>
          <div className="glance_container_content_line">
            <div
              className="glance_container_content_line_item"
              style={{ width: "calc(100/20 * 2%)" }}
            ></div>
          </div>
          <div className="glance_container_going_text_container">
            <div className="glance_container_going_text_dot" />
            <p className="glance_container_going_text_primary">2 going</p>
          </div>
        </div>
      </div>
    );
  };

  const guestOptions = () => {
    const opts = [
      {
        title: "Invite Guests",
        icon: InviteIcon,
        background: "#E2E7FE",
        onClick: () => {
          setGuestListModalOpen(true);
        },
      },
      {
        title: "Check-in Guests",
        icon: CheckinIcon,
        background: "#E5F4E4",
        onClick: () => {
          setCheckinModalOpen(true);
        },
      },
      {
        title: "Guest List",
        icon: PeopleIcon,
        background: "#F8EFE2",
        onClick: () => {
          setHideGuestListModalOpen(true);
        },
      },
    ];
    return (
      <div className="guest_options_container">
        {opts.map((opt, index) => (
          <div className="guest_options_buttons" onClick={opt.onClick}>
            <div
              className="guest_options_buttons_icon_container"
              style={{ background: opt.background }}
            >
              <img src={opt.icon} alt="invite_icon" />
            </div>
            <p className="guest_options_buttons_text">{opt.title}</p>
          </div>
        ))}
      </div>
    );
  };

  const guestList = () => {
    return (
      <div className="guest_list_container">
        <div className="guest_list_container_header">
          <p className="guest_list_container_header_title">Guest List</p>
          <div className="guest_list_container_header_button">
            <img src={DownloadIcon} alt="download_icon" />
          </div>
          <div
            className="guest_list_container_header_button"
            onClick={() => setGuestListModalOpen(true)}
          >
            <img src={OpenIcon} alt="open_icon" />
          </div>
        </div>
        <div className="guest_list_search_container">
          <img
            src={SearchIcon}
            alt="search_icon"
            style={{ cursor: "pointer" }}
            onClick={() => searchInputRef.current?.focus()}
          />
          <input
            type="text"
            placeholder="Search"
            className="guest_list_search_input"
            ref={searchInputRef}
          />
        </div>
        <div className="guest_list_filter_container">
          <div className="guest_list_filter_button">
            <img src={FilterIcon1} alt="filter_icon_1" />
            <p className="guest_list_filter_button_text">All Guests</p>
            <img src={ChevronDown} alt="chevron_down" />
          </div>
          <div className="guest_list_filter_button">
            <img src={FilterIcon2} alt="filter_icon_1" />
            <p className="guest_list_filter_button_text">Register Time</p>
            <img src={ChevronDown} alt="chevron_down" />
          </div>
        </div>
      </div>
    );
  };

  const guestListFilter = () => {
    const guests = [
      {
        name: "Utkarsh Koushik",
        registerTime: new Date().toLocaleString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }),
        status: "Going",
        email: "utkarshkoushik@gmail.com",
        profilePic: UKProfile,
      },
      {
        name: "Astha Agarwal",
        registerTime: new Date().toLocaleString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }),
        status: "Going",
        email: "asthaagarwal@gmail.com",
        profilePic: AsthaProfile,
      },
    ];
    return (
      <div className="filtered_guest_list_filter_container">
        {guests.map((guest, index) => (
          <div
            className="guest_list_filter_item_container"
            onClick={() => setGuestPopupOpen(true)}
          >
            <img
              src={guest.profilePic}
              alt="guest_profile_pic"
              style={{ borderRadius: "100%" }}
              className="guest_list_filter_item_container_profile_pic"
            />
            <div className="guest_list_filter_item_container_right">
              <div className="guest_list_filter_item_container_right_top">
                <p className="guest_list_filter_item_container_right_top_name">
                  {guest.name}
                </p>
                <p className="guest_list_filter_item_container_right_top_time">
                  {guest.registerTime}
                </p>
              </div>
              <p className="guest_list_filter_item_container_right_email">
                {guest.email}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {glance()}
      {guestOptions()}
      <div className="guest_options_container_line" />
      {guestList()}
      {guestListFilter()}
      {hideGuestListModal()}
      {checkinModal()}
      {guestListModal()}
      <GuestPopup
        isOpen={guestPopupOpen}
        onClose={() => setGuestPopupOpen(false)}
        guest={guest}
      />
      {/* <Cropper /> */}
    </div>
  );
}
