import React, { useState, useRef } from "react";
import Navbar from "../eventv2/Navbar";
import "./CreateEvent.css";
import LocationIcon from "../../resources/images/create_event/location_icon.svg";
import PlusIcon from "../../resources/images/create_event/plus_icon.svg";
import TripleDotIcon from "../../resources/images/create_event/triple_dot_icon.svg";
import TestIcon from "../../resources/images/create_event/test_icon.svg";
import EditIcon from "../../resources/images/create_event/edit_icon.svg";
import SelectImageIcon from "../../resources/images/create_event/select_image_icon.svg";
import GoogleInput from "./GoogleInput";
import { apiurl } from "../../commons/ApiHelper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import {
  CustomQuestionInput,
  CustomQuestionRequiredBlock,
  CustomQuestionTitle,
  ModalIcon,
  ModalTextPrimary,
  ModalTitle,
  UserIcon,
} from "./ModalComponent";
import MaterialModal from "./MaterialModal";
import { ModalButtonPrimary } from "./ModalComponent";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import CopyIcon from "../../resources/images/create_event/copy_icon.svg";
import LockIcon from "../../resources/images/create_event/lock_icon.svg";

import ModalChevronIcon from "../../resources/images/create_event/modal_chevron_icon.svg";
import AddHostIcon from "../../resources/images/create_event/add_host_icon_1.svg";
import CheckboxCheckedIconBlack from "../../resources/images/eventv2/checkbox_checked_icon_black.svg";
import axios from "axios";
import Placeholder1 from "../../resources/images/create_event/placeholder_1.avif";
import Placeholder2 from "../../resources/images/create_event/placeholder_2.avif";
import Placeholder3 from "../../resources/images/create_event/placeholder_3.avif";
import Placeholder4 from "../../resources/images/create_event/placeholder_4.avif";
import { useMediaQuery } from "@mui/material";
interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

interface ITicketType {
  id: string;
  name: string;
  description: string;
  // price: number;
  approval_required: boolean;
  sale_start_date?: string;
  sale_end_date?: string;
  capacity?: number;
}

interface IHost {
  id: string;
  name: string;
  email: string;
  type: string;
  profilePic: string;
  showOnEventPage: boolean;
  accessControl: "Manager" | "Checkin" | "Non Manager";
}

export default function Create() {
  const [googleValue, setGoogleValue] = React.useState<PlaceType | null>(null);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [startTime, setStartTime] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [endTime, setEndTime] = useState<dayjs.Dayjs | null>(null);
  const [saleStartDateTime, setSaleStartDateTime] =
    useState<dayjs.Dayjs | null>(startDate);
  const [saleEndDateTime, setSaleEndDateTime] = useState<dayjs.Dayjs | null>(
    null
  );

  const isLaptop = useMediaQuery("(min-width: 600px)");

  const inputBox = (
    placeholder: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value: string
  ) => {
    return (
      <input
        className="create_input_box"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    );
  };
  const capacityInputRef = useRef<HTMLInputElement>(null);
  const [sideImages, setSideImages] = useState([
    Placeholder1,
    Placeholder2,
    Placeholder3,
    Placeholder4,
  ]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [awsImages, setAwsImages] = useState<string[]>([]);
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files) return;

    // Convert FileList to Array and limit to first 4 files
    const fileArray = Array.from(files).slice(0, 4);

    // Create URLs for selected images
    const imageUrls = fileArray.map((file) => URL.createObjectURL(file));

    // Update state with new images
    setSideImages((prevImages) => {
      const newImages = [...prevImages];
      imageUrls.forEach((url, index) => {
        newImages[index] = url;
      });
      return newImages;
    });

    // Create FormData and append files
    const formData = new FormData();
    fileArray.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await fetch(apiurl + "events/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload images");
      }

      const data = await response.json();
      console.log("Upload successful:", data);
      setAwsImages([...data.files]);
    } catch (error) {
      console.error("Error uploading images:", error);
      // You might want to add error handling UI here
    }
  };

  const imageSection = () => {
    const handleSelectImage = () => {
      fileInputRef.current?.click();
    };
    return (
      <div className="image_section">
        <img
          src={sideImages[selectedImageIndex] || Placeholder1}
          alt="test_img"
          className="image_section_img"
        />
        <div className="image_section_left" style={{ position: "relative" }}>
          <img
            src={SelectImageIcon}
            alt="select_image_icon"
            onClick={handleSelectImage}
            style={{ position: "absolute", bottom: 4, right: 4 }}
          />
          {sideImages.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`side_image_${index}`}
              className="image_section_img_1"
              onClick={() => {
                setSelectedImageIndex(index);
              }}
            />
          ))}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            accept="image/*"
            multiple
            style={{ display: "none" }}
          />
        </div>
      </div>
    );
  };

  const [isMultiDay, setIsMultiDay] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const titleAndDescription = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {!isLaptop && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <p className="title_and_description_title">Experience Name</p>
            {inputBox(
              "Experience Name",
              (e) => {
                setTitle(e.target.value);
              },
              title
            )}
          </div>
        )}
        <p className="create_event_secondary_title">Description</p>
        {inputBox(
          "Description",
          (e) => {
            setDescription(e.target.value);
          },
          description
        )}
        <p className="create_event_secondary_title">Location</p>
        <div className="create_event_input_div">
          <img src={LocationIcon} alt="location_icon" />
          {/* <div>
            <p className="create_event_input_div_title">Hasanpur</p>
            <p className="create_event_input_div_description">
              Uttar Pradesh 244241, India
            </p>
          </div> */}
          <GoogleInput value={googleValue} setValue={setGoogleValue} />
        </div>
        <p className="create_event_secondary_title">Add Experience Dates</p>

        <div className="create_event_input_div_2">
          <p className="create_event_input_div_text">
            Is this a Multi Day Event?
          </p>
          <div
            id="slidingToggle"
            className={`toggle-container ${isMultiDay ? "active" : ""}`}
            onClick={() => setIsMultiDay(!isMultiDay)}
          >
            <div className="slider"></div>
          </div>
        </div>
        <div className="create_event_input_div_2" style={{ display: "block" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid #E0E0E0",
                paddingBottom: 8,
              }}
            >
              <DatePicker
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "22px",
                  },
                }}
              />
              <MobileTimePicker
                value={startTime}
                onChange={(newValue) => setStartTime(newValue)}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "22px",
                    width: 104,
                  },
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                paddingTop: 8,
              }}
            >
              <DatePicker
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "22px",
                  },
                }}
              />
              <MobileTimePicker
                value={endTime}
                onChange={(newValue) => setEndTime(newValue)}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "22px",
                    width: 104,
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>
    );
  };

  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isApprovalRequired, setIsApprovalRequired] = useState(false);
  const [isCapacityApplied, setIsCapacityApplied] = useState(false);
  const [isSaleStartApplied, setIsSaleStartApplied] = useState(false);
  const [isSaleEndApplied, setIsSaleEndApplied] = useState(false);
  const [ticketTypes, setTicketTypes] = useState<ITicketType[]>([]);
  const [newTicketName, setNewTicketName] = useState("");
  const [newTicketDescription, setNewTicketDescription] = useState("");
  const [newTicketCapacity, setNewTicketCapacity] = useState<string>("");

  const handleCreateTicket = () => {
    if (!newTicketName.trim()) return;

    const newTicket: ITicketType = {
      id: `ticket-${Date.now()}`,
      name: newTicketName,
      description: isDescription ? newTicketDescription : "",
      approval_required: isApprovalRequired,
      capacity: isCapacityApplied ? Number(newTicketCapacity) || 0 : 0,
      sale_start_date: isSaleStartApplied
        ? saleStartDateTime?.toISOString()
        : undefined,
      sale_end_date: isSaleEndApplied
        ? saleEndDateTime?.toISOString()
        : undefined,
    };

    setTicketTypes([...ticketTypes, newTicket]);

    // Reset modal state
    setNewTicketName("");
    setNewTicketDescription("");
    setNewTicketCapacity("");
    setIsDescription(false);
    setIsApprovalRequired(false);
    setIsCapacityApplied(false);
    setIsSaleStartApplied(false);
    setIsSaleEndApplied(false);
    setIsTicketModalOpen(false);
  };

  const ticketModal = () => {
    return (
      <div>
        <MaterialModal
          isOpen={isTicketModalOpen}
          onClose={() => {
            setIsTicketModalOpen(false);
          }}
          paperStyles={{ maxWidth: 450 }}
        >
          <div className="custom_modal_container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ModalIcon icon={CopyIcon} width={32} padding={12} />

              <ModalIcon
                icon={CrossModalIcon}
                width={20}
                padding={4}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsTicketModalOpen(false);
                }}
              />
            </div>
            <ModalTitle title="New Ticket Type" marginTop={12} />

            <CustomQuestionTitle title={"Ticket Name"} marginTop={16} />
            <CustomQuestionInput
              placeholder="Friends and Family"
              marginTop={6}
              props={{
                value: newTicketName,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewTicketName(e.target.value),
              }}
            />
            {!isDescription && (
              <p
                className="modal_text_secondary"
                style={{ cursor: "pointer", marginTop: 8 }}
                onClick={() => setIsDescription(true)}
              >
                + Add Description
              </p>
            )}
            {isDescription && (
              <div>
                <CustomQuestionTitle title={"Description"} marginTop={8} />
                <CustomQuestionInput
                  placeholder="Exclusively for Friends and Family"
                  marginTop={6}
                  props={{
                    value: newTicketDescription,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setNewTicketDescription(e.target.value),
                  }}
                />
              </div>
            )}
            <CustomQuestionRequiredBlock
              isRequired={isApprovalRequired}
              setIsRequired={setIsApprovalRequired}
              marginTop={16}
              text="Approval Required"
            />
            {!isCapacityApplied && (
              <div
                className="ticket_restriction_text_container"
                onClick={() => {
                  setIsCapacityApplied(true);
                }}
              >
                <img
                  src={LockIcon}
                  alt="lock_icon"
                  style={{ cursor: "pointer", width: 12.73, height: 12.73 }}
                />
                <p
                  className="modal_text_secondary"
                  onClick={() => setIsDescription(true)}
                  style={{ marginTop: 0 }}
                >
                  Restrict Dates / Capacity
                </p>
              </div>
            )}
            {isCapacityApplied && (
              <div>
                <CustomQuestionRequiredBlock
                  isRequired={isSaleStartApplied}
                  setIsRequired={setIsSaleStartApplied}
                  marginTop={28}
                  text="Sale Start"
                />
                {isSaleStartApplied && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={saleStartDateTime || startDate}
                      onChange={(newValue) => setSaleStartDateTime(newValue)}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          width: "100%",
                          marginTop: "12px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                <CustomQuestionRequiredBlock
                  isRequired={isSaleEndApplied}
                  setIsRequired={setIsSaleEndApplied}
                  marginTop={16}
                  text="Sale End"
                />
                {isSaleEndApplied && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={saleEndDateTime}
                      onChange={(newValue) => setSaleEndDateTime(newValue)}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          width: "100%",
                          marginTop: "12px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 12,
                  }}
                >
                  <p className="custom_question_required_block_text">
                    Total Tickets
                  </p>
                  <CustomQuestionInput
                    placeholder=""
                    props={{
                      style: { width: 80 },
                      value: newTicketCapacity,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewTicketCapacity(e.target.value),
                    }}
                  />
                </div>
              </div>
            )}
            <ModalButtonPrimary
              title="Create Ticket Type"
              marginTop={16}
              onClick={handleCreateTicket}
            />
          </div>
        </MaterialModal>
      </div>
    );
  };

  const [isUpdateTicketModalOpen, setIsUpdateTicketModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<ITicketType | null>(
    null
  );

  const handleUpdateTicket = () => {
    if (!selectedTicket || !newTicketName.trim()) return;

    const updatedTicket: ITicketType = {
      ...selectedTicket,
      name: newTicketName,
      description: isDescription ? newTicketDescription : "",
      approval_required: isApprovalRequired,
      capacity: isCapacityApplied ? Number(newTicketCapacity) || 0 : 0,
      sale_start_date: isSaleStartApplied
        ? saleStartDateTime?.toISOString()
        : undefined,
      sale_end_date: isSaleEndApplied
        ? saleEndDateTime?.toISOString()
        : undefined,
    };

    setTicketTypes(
      ticketTypes.map((t) => (t.id === selectedTicket.id ? updatedTicket : t))
    );

    handleCloseUpdateModal();
  };

  const handleDeleteTicket = () => {
    if (!selectedTicket) return;
    setTicketTypes(ticketTypes.filter((t) => t.id !== selectedTicket.id));
    handleCloseUpdateModal();
  };

  const handleCloseUpdateModal = () => {
    setSelectedTicket(null);
    setNewTicketName("");
    setNewTicketDescription("");
    setNewTicketCapacity("");
    setIsDescription(false);
    setIsApprovalRequired(false);
    setIsCapacityApplied(false);
    setIsSaleStartApplied(false);
    setIsSaleEndApplied(false);
    setIsUpdateTicketModalOpen(false);
  };

  const handleOpenUpdateModal = (ticket: ITicketType) => {
    console.log(ticket);
    setSelectedTicket(ticket);
    setNewTicketName(ticket.name);
    setNewTicketDescription(ticket.description || "");
    setNewTicketCapacity(ticket.capacity?.toString() || "");
    setIsDescription(!!ticket.description);
    setIsApprovalRequired(ticket.approval_required);
    setIsCapacityApplied(!!ticket.capacity);
    setIsSaleStartApplied(!!ticket.sale_start_date);
    setIsSaleEndApplied(!!ticket.sale_end_date);
    if (ticket.sale_start_date)
      setSaleStartDateTime(dayjs(ticket.sale_start_date));
    if (ticket.sale_end_date) setSaleEndDateTime(dayjs(ticket.sale_end_date));
    setIsUpdateTicketModalOpen(true);
  };

  const ticketType = () => {
    return (
      <div style={{ marginTop: 8 }}>
        <div className="tickets_container_header">
          <p className="tickets_container_header_text">Tickets</p>
          <div
            className="tickets_container_header_button"
            onClick={() => {
              setIsTicketModalOpen(true);
            }}
          >
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">
              New Ticket Type
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            marginTop: "12px",
            marginBottom: "18px",
          }}
        >
          {ticketTypes.map((ticket) => {
            return (
              <div
                className="ticket_type_container"
                key={ticket.id}
                onClick={() => handleOpenUpdateModal(ticket)}
                style={{ cursor: "pointer" }}
              >
                <div className="ticket_type_container_left">
                  <p className="ticket_type_container_name">{ticket.name}</p>
                  {/* <p className="ticket_type_container_type">Standard</p> */}
                </div>
                {/* <div className="ticket_type_container_tag_container">
                  <p className="ticket_type_container_tag">{ticket.tag}</p>
                </div> */}
                <div className="ticket_type_container_left">
                  <p className="ticket_type_container_number_of_registrations">
                    0 Registrations
                  </p>
                  <img src={TripleDotIcon} alt="triple_dot_icon" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const [hosts, setHosts] = useState<IHost[]>([
    {
      id: "host-1",
      name: "Aman Agarwal",
      email: "aman@thrivia.io",
      type: "Creator",
      profilePic: TestIcon,
      showOnEventPage: true,
      accessControl: "Manager",
    },
  ]);

  const [selectedHost, setSelectedHost] = useState<IHost | null>(null);

  const handleAddHost = () => {
    const newHost: IHost = {
      id: `host-${Date.now()}`,
      name: hostEmail.split("@")[0], // Simple name from email
      email: hostEmail,
      type: activeHostAccessControl,
      profilePic: TestIcon,
      showOnEventPage: shouldShowOnEventPage,
      accessControl: activeHostAccessControl as
        | "Manager"
        | "Checkin"
        | "Non Manager",
    };

    setHosts([...hosts, newHost]);
    resetHostModalState();
  };

  const handleUpdateHost = () => {
    if (!selectedHost) return;

    const updatedHost: IHost = {
      ...selectedHost,
      showOnEventPage: shouldShowOnEventPage,
      accessControl: activeHostAccessControl as
        | "Manager"
        | "Checkin"
        | "Non Manager",
    };

    setHosts(hosts.map((h) => (h.id === selectedHost.id ? updatedHost : h)));
    resetHostModalState();
  };

  const handleRemoveHost = () => {
    if (!selectedHost) return;
    setHosts(hosts.filter((h) => h.id !== selectedHost.id));
    resetHostModalState();
  };

  const resetHostModalState = () => {
    setHostEmail("");
    setHostAddState(false);
    setShouldShowOnEventPage(true);
    setActiveHostAccessControl("Manager");
    setSelectedHost(null);
    setHostModalOpen(false);
    setUpdateHostModalOpen(false);
  };

  const handleOpenUpdateHostModal = (host: IHost) => {
    setSelectedHost(host);
    setHostEmail(host.email);
    setShouldShowOnEventPage(host.showOnEventPage);
    setActiveHostAccessControl(host.accessControl);
    setUpdateHostModalOpen(true);
  };

  const hostList = () => {
    return (
      <div className="host_list_container">
        <div className="invitations_container_heading">
          <p className="invitations_container_heading_text">Hosts</p>
          <div
            className="tickets_container_header_button"
            onClick={() => setHostModalOpen(true)}
          >
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">Add Host</p>
          </div>
        </div>
        <p className="host_list_container_text">
          Add hosts, special guests, and event managers.
        </p>
        <div className="host_list">
          {hosts.map((host) => (
            <div
              className="host_list_item"
              key={host.id}
              onClick={() => handleOpenUpdateHostModal(host)}
            >
              <img
                src={host.profilePic}
                alt="host_profile_pic"
                className="host_list_item_profile_pic"
              />
              <p className="host_list_item_name">{host.name}</p>
              <div className="guest_list_filter_item_container_right_top_status">
                <p className="guest_list_filter_item_container_right_top_status_text">
                  {host.type}
                </p>
              </div>
              <div className="host_list_item_edit_container">
                <img src={EditIcon} alt="edit_icon" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const capacity = () => {
    return (
      <div className="create_event_input_div_2">
        <p
          className="host_list_item_name"
          style={{ fontSize: 16, lineHeight: "120%" }}
        >
          Capacity
        </p>
        <input
          className="create_event_capacity_input"
          placeholder="unlimited"
          type="number"
          ref={capacityInputRef}
          style={{ lineHeight: "20px", flex: 1 }}
        />
        <div
          className="host_list_item_edit_container"
          style={{ flex: 0 }}
          onClick={() => {
            if (capacityInputRef.current) {
              capacityInputRef.current.focus();
            }
          }}
        >
          <img src={EditIcon} alt="edit_icon" />
        </div>
      </div>
    );
  };

  const updateTicketModal = () => {
    return (
      <div>
        <MaterialModal
          isOpen={isUpdateTicketModalOpen}
          onClose={handleCloseUpdateModal}
          paperStyles={{ maxWidth: 450 }}
        >
          <div className="custom_modal_container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ModalIcon icon={CopyIcon} width={32} padding={12} />

              <ModalIcon
                icon={CrossModalIcon}
                width={20}
                padding={4}
                style={{ cursor: "pointer" }}
                onClick={handleCloseUpdateModal}
              />
            </div>
            <ModalTitle title="Update Ticket" marginTop={12} />

            <CustomQuestionTitle title={"Ticket Name"} marginTop={16} />
            <CustomQuestionInput
              placeholder="Friends and Family"
              marginTop={6}
              props={{
                value: newTicketName,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewTicketName(e.target.value),
              }}
            />
            {!isDescription && (
              <p
                className="modal_text_secondary"
                style={{ cursor: "pointer", marginTop: 8 }}
                onClick={() => setIsDescription(true)}
              >
                + Add Description
              </p>
            )}
            {newTicketDescription && (
              <div>
                <CustomQuestionTitle title={"Description"} marginTop={8} />
                <CustomQuestionInput
                  placeholder="Exclusively for Friends and Family"
                  marginTop={6}
                  props={{
                    value: newTicketDescription,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setNewTicketDescription(e.target.value),
                  }}
                />
              </div>
            )}
            <CustomQuestionRequiredBlock
              isRequired={isApprovalRequired}
              setIsRequired={setIsApprovalRequired}
              marginTop={16}
              text="Approval Required"
            />
            {!isCapacityApplied && (
              <div
                className="ticket_restriction_text_container"
                onClick={() => {
                  setIsCapacityApplied(true);
                }}
              >
                <img
                  src={LockIcon}
                  alt="lock_icon"
                  style={{ cursor: "pointer", width: 12.73, height: 12.73 }}
                />
                <p
                  className="modal_text_secondary"
                  onClick={() => setIsDescription(true)}
                  style={{ marginTop: 0 }}
                >
                  Restrict Dates / Capacity
                </p>
              </div>
            )}
            {isCapacityApplied && (
              <div>
                <CustomQuestionRequiredBlock
                  isRequired={isSaleStartApplied}
                  setIsRequired={setIsSaleStartApplied}
                  marginTop={28}
                  text="Sale Start"
                />
                {isSaleStartApplied && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={saleStartDateTime || startDate}
                      onChange={(newValue) => setSaleStartDateTime(newValue)}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          width: "100%",
                          marginTop: "12px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                <CustomQuestionRequiredBlock
                  isRequired={isSaleEndApplied}
                  setIsRequired={setIsSaleEndApplied}
                  marginTop={16}
                  text="Sale End"
                />
                {isSaleEndApplied && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={saleEndDateTime}
                      onChange={(newValue) => setSaleEndDateTime(newValue)}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          width: "100%",
                          marginTop: "12px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 12,
                  }}
                >
                  <p className="custom_question_required_block_text">
                    Total Tickets
                  </p>
                  <CustomQuestionInput
                    placeholder=""
                    props={{
                      style: { width: 80 },
                      value: newTicketCapacity,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewTicketCapacity(e.target.value),
                    }}
                  />
                </div>
              </div>
            )}
            <div style={{ display: "flex", gap: "12px", marginTop: "16px" }}>
              <ModalButtonPrimary
                title="Update"
                onClick={handleUpdateTicket}
                styles={{ flex: 1 }}
              />
              <ModalButtonPrimary
                title="Delete"
                onClick={handleDeleteTicket}
                styles={{
                  border: "1px solid red",
                  color: "red",
                  background: "transparent",
                  flex: 1,
                }}
              />
            </div>
          </div>
        </MaterialModal>
      </div>
    );
  };

  const [hostModalOpen, setHostModalOpen] = useState(false);
  const [updateHostModalOpen, setUpdateHostModalOpen] = useState(false);
  const [hostEmail, setHostEmail] = useState("");
  const [hostAddState, setHostAddState] = useState(false);
  const [shouldShowOnEventPage, setShouldShowOnEventPage] = useState(true);
  const [activeHostAccessControl, setActiveHostAccessControl] =
    useState("Manager");

  const hostModal = () => {
    const addHostEmail = () => {
      return (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ModalIcon icon={CopyIcon} width={32} padding={12} />

            <ModalIcon
              icon={CrossModalIcon}
              width={20}
              padding={4}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setHostModalOpen(false);
              }}
            />
          </div>
          <ModalTitle title="Add Host" marginTop={12} />
          <ModalTextPrimary
            text="Add a host to highlight them on the event page or to get help managing the event."
            marginTop={8}
          />
          <CustomQuestionTitle title="Enter Email or Search" marginTop={12} />
          <CustomQuestionInput
            placeholder=""
            marginTop={6}
            props={{
              value: hostEmail,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setHostEmail(e.target.value),
            }}
          />
          {hostEmail !== "" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 12,
                  gap: 12,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setHostAddState(true);
                }}
              >
                <UserIcon />
                <div>
                  <ModalTextPrimary
                    text={hostEmail}
                    style={{ color: "#000" }}
                  />
                  <ModalTextPrimary
                    text="Add Host via email"
                    style={{ fontSize: 12, fontWeight: 400, marginTop: 4 }}
                  />
                </div>
              </div>
            </>
          )}
          {hostEmail === "" && (
            <>
              <div style={{ padding: "44px 32px" }}>
                <ModalTextPrimary
                  text="No Suggestions Found"
                  marginTop={0}
                  style={{
                    textAlign: "center",
                    color: "#1315175C",
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                />
                <ModalTextPrimary
                  text="You can invite hosts by entering their email address."
                  marginTop={4}
                  style={{
                    textAlign: "center",
                    color: "#1315175C",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                />
              </div>
            </>
          )}
        </div>
      );
    };

    const addHost = () => {
      return (
        <div>
          <div className="question_header_container">
            <ModalIcon
              icon={ModalChevronIcon}
              width={14}
              padding={7}
              onClick={() => setHostAddState(false)}
            />
            <ModalTitle title="Configure Host" />
            <ModalIcon
              icon={CrossModalIcon}
              width={24}
              padding={4}
              onClick={() => setHostModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: 6,
              alignItems: "center",
              marginTop: 16,
            }}
          >
            <UserIcon width={16} height={16} padding={4} />
            <ModalTextPrimary text={hostEmail} />
          </div>
          <CustomQuestionRequiredBlock
            isRequired={shouldShowOnEventPage}
            setIsRequired={() => {
              setShouldShowOnEventPage(!shouldShowOnEventPage);
            }}
            marginTop={16}
            text="Show on Event Page"
          />
          <ModalTextPrimary text="Access Control" marginTop={16} />
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Manager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Manager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary text="Manager" style={{ color: "#000" }} />
                <ModalTextPrimary text="Manage the event and guests" />
              </div>
              {activeHostAccessControl === "Manager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Checkin"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Checkin");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Checkin Only"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="Allow guests to check in only" />
              </div>
              {activeHostAccessControl === "Checkin" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Non Manager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Non Manager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Non Manager"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="No access to manage events" />
              </div>
              {activeHostAccessControl === "Non Manager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 12,
            }}
          >
            <ModalButtonPrimary
              title="Add Host"
              onClick={handleAddHost}
              marginTop={16}
              styles={{
                flex: 1,
              }}
            />
            <ModalButtonPrimary
              title="Cancel"
              onClick={() => {
                setHostModalOpen(false);
              }}
              marginTop={16}
              styles={{
                border: "1px solid red",
                color: "red",
                background: "transparent",
                flex: 1,
              }}
            />
          </div>
        </div>
      );
    };
    return (
      <MaterialModal
        isOpen={hostModalOpen}
        onClose={() => {
          setHostModalOpen(false);
        }}
        paperStyles={{ maxWidth: 450 }}
      >
        <div className="custom_modal_container">
          {!hostAddState ? addHostEmail() : addHost()}
        </div>
      </MaterialModal>
    );
  };

  const updateHost = () => {
    const updateModal = () => {
      return (
        <div>
          <div className="question_header_container">
            <ModalTitle title="Configure Host" />
            <ModalIcon
              icon={CrossModalIcon}
              width={24}
              padding={4}
              onClick={() => setUpdateHostModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: 6,
              alignItems: "center",
              marginTop: 16,
            }}
          >
            <UserIcon width={16} height={16} padding={4} />
            <ModalTextPrimary text={hostEmail} />
          </div>
          <CustomQuestionRequiredBlock
            isRequired={shouldShowOnEventPage}
            setIsRequired={() => {
              setShouldShowOnEventPage(!shouldShowOnEventPage);
            }}
            marginTop={16}
            text="Show on Event Page"
          />
          <ModalTextPrimary text="Access Control" marginTop={16} />
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Manager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Manager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary text="Manager" style={{ color: "#000" }} />
                <ModalTextPrimary text="Manage the event and guests" />
              </div>
              {activeHostAccessControl === "Manager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Checkin"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Checkin");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Checkin Only"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="Allow guests to check in only" />
              </div>
              {activeHostAccessControl === "Checkin" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
            <div
              className={`host_modal_access_control_container ${
                activeHostAccessControl === "Non Manager"
                  ? "host_modal_access_control_container_active"
                  : ""
              }`}
              onClick={() => {
                setActiveHostAccessControl("Non Manager");
              }}
            >
              <img
                src={AddHostIcon}
                alt="add_host_icon"
                style={{ width: 18, aspectRatio: 1 / 1 }}
              />
              <div style={{ flex: 1 }}>
                <ModalTextPrimary
                  text="Non Manager"
                  style={{ color: "#000" }}
                />
                <ModalTextPrimary text="No access to manage events" />
              </div>
              {activeHostAccessControl === "Non Manager" && (
                <img
                  src={CheckboxCheckedIconBlack}
                  alt="checkbox_checked_icon"
                  style={{ width: 18, aspectRatio: 1 / 1 }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 12,
            }}
          >
            <ModalButtonPrimary
              title="Update Host"
              onClick={handleUpdateHost}
              marginTop={16}
              styles={{
                flex: 1,
              }}
            />
            <ModalButtonPrimary
              title="Remove"
              onClick={handleRemoveHost}
              marginTop={16}
              styles={{
                border: "1px solid red",
                color: "red",
                background: "transparent",
                flex: 1,
              }}
            />
          </div>
        </div>
      );
    };
    return (
      <MaterialModal
        isOpen={updateHostModalOpen}
        onClose={() => {
          setUpdateHostModalOpen(false);
        }}
        paperStyles={{ maxWidth: 450 }}
      >
        <div className="custom_modal_container">{updateModal()}</div>
      </MaterialModal>
    );
  };

  const handleCreateEvent = async () => {
    try {
      window.location.href = `/event/manage/679f261a2ad9d2d927f3c62d`;
      // return;
      console.log(
        awsImages,
        description,
        title,
        startDate,
        endDate,
        googleValue
      );
      // Validate required fields
      if (
        !title ||
        !description ||
        !startDate ||
        !endDate ||
        !googleValue ||
        awsImages.length === 0
      ) {
        // alert("Please fill in all required fields");

        return;
      }

      // Format dates
      const startDateTime =
        startDate && startTime
          ? dayjs(startDate)
              .hour(startTime.hour())
              .minute(startTime.minute())
              .second(0)
              .toDate()
          : null;

      const endDateTime =
        endDate && endTime
          ? dayjs(endDate)
              .hour(endTime.hour())
              .minute(endTime.minute())
              .second(0)
              .toDate()
          : null;

      // Prepare event data
      const eventData = {
        title,
        description,
        start_date: startDateTime,
        end_date: endDateTime,
        location: {
          address: googleValue.description,
          // You might want to parse city and country from googleValue.structured_formatting
        },
        capacity: capacityInputRef.current?.value
          ? parseInt(capacityInputRef.current.value)
          : undefined,
        cover_photo: awsImages[0], // First image as cover photo
        event_photos: awsImages.slice(1), // Rest of the images
        co_hosts: hosts.slice(1).map((host) => ({
          email: host.email,
          permission: host.accessControl,
        })), // Rest as co-hosts
        event_type: "Experience",
        theme: "Lifestyle",
        ticketTypes: ticketTypes,
      };

      console.log({ eventData });

      // Create event
      const authToken = localStorage.getItem("authToken");
      axios
        .post(`${apiurl}events/create`, eventData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          console.log(res);
          window.location.href = `/event/manage/${res.data._id}`;
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error creating event:", error);
      alert("Failed to create event. Please try again.");
    }
  };

  const navIcon = () => {
    return (
      <img
        src={TestIcon}
        alt="test_icon"
        style={{ width: 24, height: 24, borderRadius: 100 }}
      />
    );
  };

  return (
    <div>
      <Navbar navIcon={navIcon} />
      <div style={{}} className="create_event_container_1">
        <div
          style={{
            position: isLaptop ? "sticky" : "static",
            top: "calc(var(--navbar-offset) + 24px)",
            transition: "all 0.3s ease",
            height: "fit-content",
          }}
        >
          {imageSection()}
          {isLaptop && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                marginTop: 16,
              }}
            >
              {" "}
              <p className="title_and_description_title">Experience Name</p>
              {inputBox(
                "Experience Name",
                (e) => {
                  setTitle(e.target.value);
                },
                title
              )}
            </div>
          )}
        </div>
        <div className="create_event_container_right">
          {titleAndDescription()}
          {ticketType()}
          {hostList()}
          {capacity()}

          <button
            className="initial_button_primary"
            style={{ marginTop: 12 }}
            onClick={handleCreateEvent}
          >
            Create Event
          </button>
        </div>
      </div>
      {hostModal()}
      {updateHost()}
      {ticketModal()}
      {updateTicketModal()}
    </div>
  );
}
