import React, { useEffect, useState } from "react";
import styles from "./CreatorEvents.module.css";
import Navbar from "../eventv2/Navbar";
import { ReactComponent as EventIcon } from "../../resources/images/listing/event_icon.svg";
import SearchIcon from "../../resources/images/listing/search_icon.svg";
import EventCard from "../listing/EventCard";
import { EventInterface } from "../../commons/Interfaces";
import { authenticatedRequest } from "../../commons/AuthenticatedRequest";
import { useMediaQuery } from "@mui/material";

export default function CreatorEvents() {
  const isLaptop = useMediaQuery("(min-width: 600px)");
  const [events, setEvents] = useState<EventInterface[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventInterface[]>([]);
  const [activeTimeFilter, setActiveTimeFilter] = useState("upcoming");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<EventInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      const userId = localStorage.getItem("user_id");
      const token = localStorage.getItem("authToken");

      if (!userId || !token) {
        window.location.href = "/login";
        return;
      }

      try {
        setIsLoading(true);
        const res = await authenticatedRequest<EventInterface[]>(
          `events/events/host/${userId}`,
          {
            method: "GET",
          }
        );
        console.log(res);
        setEvents(res);
        setFilteredEvents(res);
      } catch (err) {
        console.log(err);
        if (err instanceof Error && err.message === "Unauthorized") {
          window.location.href = "/login";
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setSearchResults(
      events.filter(
        (event) =>
          event.title.toLowerCase().includes(query.toLowerCase()) ||
          event.city?.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  useEffect(() => {
    let newFilteredEvents = [...events];
    const now = new Date();

    if (activeTimeFilter === "upcoming") {
      newFilteredEvents = newFilteredEvents.filter(
        (event) => new Date(event.start_date) >= now
      );
    } else if (activeTimeFilter === "past") {
      newFilteredEvents = newFilteredEvents.filter(
        (event) => new Date(event.end_date) < now
      );
    }

    setFilteredEvents(newFilteredEvents);
  }, [activeTimeFilter, events]);

  const searchBar = () => {
    return (
      <div className={styles.search_bar_container}>
        <img src={SearchIcon} alt="search_icon" />
        <input
          type="text"
          placeholder="Search your events"
          className={styles.search_bar_input}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
    );
  };

  const timeFilterOptions = () => {
    const options = [
      {
        id: "upcoming",
        icon: EventIcon,
        title: "Upcoming",
      },
      {
        id: "past",
        icon: EventIcon,
        title: "Past",
      },
    ];

    return (
      <div className={styles.time_filter_container}>
        <div
          className={styles.sliding_background}
          style={{
            transform: `translateX(${
              activeTimeFilter === "past" ? "100%" : "0%"
            })`,
          }}
        />
        {options.map((option, index) => (
          <div
            className={`${styles.time_filter_option} ${
              activeTimeFilter === option.id
                ? styles.time_filter_option_active
                : ""
            }`}
            key={index + option.title}
            onClick={() => setActiveTimeFilter(option.id)}
          >
            <option.icon
              className={`${
                activeTimeFilter === option.id
                  ? styles.time_filter_option_icon_active
                  : styles.time_filter_option_icon
              }`}
            />
            <span
              className={`${styles.time_filter_option_title} ${
                activeTimeFilter === option.id
                  ? styles.time_filter_option_title_active
                  : ""
              }`}
            >
              {option.title}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const titleComponent = (title: string) => {
    return <p className={styles.title}>{title}</p>;
  };

  const eventsListComponent = () => {
    const displayEvents = searchQuery ? searchResults : filteredEvents;

    return (
      <div className={styles.all_experience_container}>
        {searchQuery ? (
          <p className={styles.search_results_title}>
            Search results for: {searchQuery}
          </p>
        ) : (
          titleComponent(
            `${activeTimeFilter === "upcoming" ? "Upcoming" : "Past"} Events`
          )
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={`${styles.all_experience_cards_container}`}>
            {displayEvents.map((event, index) => (
              <EventCard
                key={index}
                event={event}
                removeWishlistEvent={true}
                style={{
                  height: isLaptop ? "calc(100vh - 176px)" : "",
                  width: isLaptop ? "calc((100vh - 176px) * (358/534))" : "",
                }}
                url={`/event/manage/${event.id}`}
              />
            ))}
          </div>
        </div>
        {displayEvents.length === 0 && (
          <div className={styles.empty_container}>
            <p>No events found</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Navbar isCreate={true} />
      <div
        style={{
          padding: searchQuery === "" ? "24px" : "24px",
          position: "sticky",
          top: "var(--navbar-offset)",
          transition: "top 0.3s ease-in-out",
          zIndex: 48,
          background: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(4px)",
          backgroundSize: "cover",
          margin: "0px auto",
        }}
      >
        <div style={{ margin: "0px auto", maxWidth: 800 }}>
          {searchBar()}
          {timeFilterOptions()}
        </div>
      </div>
      <div style={{ padding: 24, maxWidth: "800px", margin: "0px auto" }}>
        {isLoading ? (
          <div className={styles.empty_container}>
            <p>Loading...</p>
          </div>
        ) : (
          eventsListComponent()
        )}
      </div>
    </div>
  );
}
