import React, { useState } from "react";
import MaterialModal from "./MaterialModal";
import {
  CustomQuestionTitle,
  ModalIcon,
  ModalTextPrimary,
  ModalTitle,
  CustomQuestionInput,
  CustomQuestionOptions,
  CustomQuestionRequiredBlock,
  ModalButtonPrimary,
  ModalSelect,
} from "./ModalComponent";
import CopyModalIcon from "../../resources/images/create_event/copy_modal_icon.svg";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import TextIcon from "../../resources/images/create_event/text_icon.svg";
import ModalChevronIcon from "../../resources/images/create_event/modal_chevron_icon.svg";
import { ReactComponent as TextIcon1 } from "../../resources/images/create_event/text_icon.svg";
import { useMediaQuery } from "@mui/material";

export default function CustomQuestion({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const isLaptop = useMediaQuery("(min-width: 600px)");
  const questionTypeList = [
    {
      id: "text",
      type: "Text",
      icon: TextIcon,
    },
    {
      id: "option",
      type: "Option",
      icon: TextIcon,
    },
    {
      id: "social_profile",
      type: "Social Profile",
      icon: TextIcon,
    },
    {
      id: "company",
      type: "Company",
      icon: TextIcon,
    },
    {
      id: "checkbox",
      type: "Checkbox",
      icon: TextIcon,
    },
    {
      id: "terms",
      type: "Terms",
      icon: TextIcon,
    },
    {
      id: "website",
      type: "Website",
      icon: TextIcon,
    },
  ];

  const [questionType, setQuestionType] = useState<string>("");
  const [activeTextOption, setActiveTextOption] = useState<string>("short");
  const [isTextQuestionRequired, setIsTextQuestionRequired] =
    useState<boolean>(false);
  const [activeOptionOption, setActiveOptionOption] =
    useState<string>("single");
  const [isOptionQuestionRequired, setIsOptionQuestionRequired] =
    useState<boolean>(false);
  const [isSocialProfileQuestionRequired, setIsSocialProfileQuestionRequired] =
    useState<boolean>(false);
  const [activeTermsOption, setActiveTermsOption] = useState<string>("text");
  const [isTermsQuestionRequired, setIsTermsQuestionRequired] =
    useState<boolean>(false);
  const [isSignatureRequired, setIsSignatureRequired] =
    useState<boolean>(false);
  const questionList = () => {
    return (
      <div>
        <div className="clone_event_modal_container_left">
          <ModalIcon icon={CopyModalIcon} width={32} padding={11} />
          <ModalIcon
            icon={CrossModalIcon}
            width={28}
            padding={4}
            style={{ cursor: "pointer" }}
            onClick={onClose}
          />
        </div>

        <ModalTitle title="Add Question" marginTop={12} />
        <ModalTextPrimary
          text="Ask guests custom questions when they register."
          marginTop={8}
        />
        <div className="custom_question_list_container">
          {questionTypeList.map((item, index) => (
            <div
              className="custom_question_list_container_item"
              key={item.id + index}
              onClick={() => setQuestionType(item.id)}
            >
              <img
                src={item.icon}
                alt="question_type_icon"
                style={{ width: 16, height: 16 }}
              />
              <p className="custom_question_list_container_item_text">
                {item.type}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const questionHeader = () => {
    return (
      <div className="question_header_container">
        <ModalIcon
          icon={ModalChevronIcon}
          width={14}
          padding={7}
          onClick={() => setQuestionType("")}
        />
        <ModalTitle title="Add Question" />
        <ModalIcon
          icon={CrossModalIcon}
          width={24}
          padding={4}
          onClick={onClose}
        />
      </div>
    );
  };

  const questionTopBody = (icon: string, title: string, text: string) => {
    return (
      <div
        style={{
          display: "flex",
          gap: 12,
          marginTop: 16,
          alignItems: "center",
        }}
      >
        <ModalIcon
          icon={icon}
          width={14}
          padding={7}
          onClick={() => setQuestionType("")}
          style={{ borderRadius: 8 }}
        />
        <div>
          <CustomQuestionTitle title={title} marginTop={0} />
          <p className="custom_question_top_body_text">{text}</p>
        </div>
      </div>
    );
  };

  const textQuestion = () => {
    return (
      <div>
        {questionHeader()}
        {questionTopBody(TextIcon, "Text", "Ask for a free form response")}
        <div
          className="guest_options_container_line"
          style={{ margin: "12px 0px" }}
        />
        <CustomQuestionTitle title="Question" marginTop={0} />
        <CustomQuestionInput
          placeholder="Enter your question here"
          marginTop={6}
        />
        <CustomQuestionTitle title={"Response Length"} marginTop={16} />
        <CustomQuestionOptions
          options={[
            { title: "Short", icon: TextIcon1, id: "short" },
            { title: "Multi-Line", icon: TextIcon1, id: "multi_line" },
          ]}
          marginTop={6}
          activeOption={activeTextOption}
          setActiveOption={setActiveTextOption}
        />
        <CustomQuestionRequiredBlock
          isRequired={isTextQuestionRequired}
          setIsRequired={setIsTextQuestionRequired}
          marginTop={24}
        />
        <ModalButtonPrimary
          title="Add Question"
          onClick={() => {}}
          marginTop={24}
        />
      </div>
    );
  };

  const optionQuestion = () => {
    return (
      <div>
        {questionHeader()}
        {questionTopBody(
          TextIcon,
          "Options",
          "Let the guest choose from a list of options"
        )}
        <div
          className="guest_options_container_line"
          style={{ margin: "12px 0px" }}
        />
        <CustomQuestionTitle title="Question" marginTop={0} />
        <CustomQuestionInput
          placeholder="Enter your question here"
          marginTop={6}
        />
        <CustomQuestionTitle title="Options" marginTop={16} />
        <CustomQuestionInput
          placeholder="Enter your question here"
          marginTop={6}
        />
        <p className="custom_question_top_body_text" style={{ marginTop: 8 }}>
          Press enter or tab key to add a new option
        </p>
        <CustomQuestionTitle title={"Response Length"} marginTop={16} />
        <CustomQuestionOptions
          options={[
            { title: "Single", icon: TextIcon1, id: "single" },
            { title: "Multiple", icon: TextIcon1, id: "multiple" },
          ]}
          marginTop={6}
          activeOption={activeOptionOption}
          setActiveOption={setActiveOptionOption}
        />
        <CustomQuestionRequiredBlock
          isRequired={isOptionQuestionRequired}
          setIsRequired={setIsOptionQuestionRequired}
          marginTop={24}
        />
        <ModalButtonPrimary
          title="Add Question"
          onClick={() => {}}
          marginTop={24}
        />
      </div>
    );
  };

  const socialProfileQuestion = () => {
    const socialProfileList = [
      { label: "Facebook", value: "facebook" },
      { label: "Instagram", value: "instagram" },
      { label: "Twitter", value: "twitter" },
    ];
    return (
      <div>
        {questionHeader()}
        {questionTopBody(
          TextIcon,
          "Social Profile",
          "Ask guests to share their social profile"
        )}
        <div
          className="guest_options_container_line"
          style={{ margin: "12px 0px" }}
        />
        <CustomQuestionTitle title="Platform" marginTop={0} />
        <ModalSelect
          options={socialProfileList}
          marginTop={6}
          props={{
            className: "modal_select_white_bg",
            selectClassName: "modal_select_white_font",
          }}
        />
        <CustomQuestionTitle title="Options" marginTop={16} />
        <CustomQuestionInput
          placeholder="Enter your question here"
          marginTop={6}
        />
        <p className="custom_question_top_body_text" style={{ marginTop: 8 }}>
          We'll automatically get this information from their profile if
          available.
        </p>
        <CustomQuestionRequiredBlock
          isRequired={isSocialProfileQuestionRequired}
          setIsRequired={setIsSocialProfileQuestionRequired}
          marginTop={24}
        />
        <ModalButtonPrimary
          title="Add Question"
          onClick={() => {}}
          marginTop={24}
        />
      </div>
    );
  };

  const companyQuestion = () => {
    return (
      <div>
        {questionHeader()}
        {questionTopBody(
          TextIcon,
          "Company",
          "Ask guests to share their company"
        )}
        <div
          className="guest_options_container_line"
          style={{ margin: "12px 0px" }}
        />
        <CustomQuestionTitle title="Platform" marginTop={0} />
        <CustomQuestionInput
          placeholder="What company do you work for?"
          marginTop={6}
          props={{
            value: "What company do you work for?",
          }}
        />
        <p className="custom_question_top_body_text" style={{ marginTop: 8 }}>
          We'll automatically get this information from their profile if
          available.
        </p>
        <CustomQuestionRequiredBlock
          isRequired={isSocialProfileQuestionRequired}
          setIsRequired={setIsSocialProfileQuestionRequired}
          marginTop={24}
        />
        <ModalButtonPrimary
          title="Add Question"
          onClick={() => {}}
          marginTop={24}
        />
      </div>
    );
  };

  const checkboxQuestion = () => {
    return (
      <div>
        {questionHeader()}
        {questionTopBody(TextIcon, "Checkbox", "Ask guests to check a box")}
        <div
          className="guest_options_container_line"
          style={{ margin: "12px 0px" }}
        />
        <CustomQuestionTitle title="Question" marginTop={0} />
        <CustomQuestionInput
          placeholder="Type your question here"
          marginTop={6}
        />

        <CustomQuestionRequiredBlock
          isRequired={isSocialProfileQuestionRequired}
          setIsRequired={setIsSocialProfileQuestionRequired}
          marginTop={24}
        />
        <p className="custom_question_top_body_text" style={{ marginTop: 8 }}>
          When set to Required, guests must tick the box to proceed.
        </p>
        <ModalButtonPrimary
          title="Add Question"
          onClick={() => {}}
          marginTop={24}
        />
      </div>
    );
  };

  const termsQuestion = () => {
    return (
      <div>
        {questionHeader()}
        {questionTopBody(
          TextIcon,
          "Terms",
          "Ask guests to accept terms and conditions"
        )}
        <div
          className="guest_options_container_line"
          style={{ margin: "12px 0px" }}
        />
        <CustomQuestionTitle title="Content Type" marginTop={0} />
        <CustomQuestionOptions
          options={[
            { title: "Text", icon: TextIcon1, id: "text" },
            { title: "Link", icon: TextIcon1, id: "link" },
          ]}
          marginTop={6}
          activeOption={activeTermsOption}
          setActiveOption={setActiveTermsOption}
        />
        <CustomQuestionTitle
          title={activeTermsOption === "text" ? "Terms Content" : "Terms Link"}
          marginTop={16}
        />
        {activeTermsOption === "text" && (
          <textarea
            className="custom_question_input"
            placeholder=""
            style={{ marginTop: 6, height: 100 }}
          />
        )}
        {activeTermsOption === "link" && (
          <CustomQuestionInput placeholder="" marginTop={6} />
        )}
        <div
          className="custom_question_required_block"
          style={{ marginTop: 24 }}
        >
          <div>
            <p className="custom_question_required_block_text">
              Collect Signature
            </p>
            <p
              className="custom_question_top_body_text"
              style={{ marginTop: 0, fontSize: 14 }}
            >
              Guests will type their signature to agree
            </p>
          </div>
          <div
            id="slidingToggle"
            className={`toggle-container ${
              isSignatureRequired ? "active" : ""
            }`}
            onClick={() => setIsSignatureRequired(!isSignatureRequired)}
          >
            <div className="slider"></div>
          </div>
        </div>
        <CustomQuestionRequiredBlock
          isRequired={isTermsQuestionRequired}
          setIsRequired={setIsTermsQuestionRequired}
          marginTop={24}
        />

        <ModalButtonPrimary
          title="Add Question"
          onClick={() => {}}
          marginTop={24}
        />
      </div>
    );
  };

  const websiteQuestion = () => {
    return (
      <div>
        {questionHeader()}
        {questionTopBody(
          TextIcon,
          "Website",
          "Ask guests to share their website URL"
        )}
        <div
          className="guest_options_container_line"
          style={{ margin: "12px 0px" }}
        />
        <CustomQuestionTitle title="Question" marginTop={0} />
        <CustomQuestionInput
          placeholder="Enter your question here"
          marginTop={6}
        />
        <CustomQuestionRequiredBlock
          isRequired={isTermsQuestionRequired}
          setIsRequired={setIsTermsQuestionRequired}
          marginTop={24}
        />

        <ModalButtonPrimary
          title="Add Question"
          onClick={() => {}}
          marginTop={24}
        />
      </div>
    );
  };

  return (
    <MaterialModal isOpen={isOpen} onClose={() => {}}>
      <div
        style={{
          padding: 16,
          width: isLaptop ? "auto" : "calc(100vw - 64px)",
          boxSizing: "border-box",
          background: "rgba(255, 255, 255, 0.867)",
        }}
      >
        {questionType === "" && questionList()}
        {questionType === "text" && textQuestion()}
        {questionType === "option" && optionQuestion()}
        {questionType === "social_profile" && socialProfileQuestion()}
        {questionType === "company" && companyQuestion()}
        {questionType === "checkbox" && checkboxQuestion()}
        {questionType === "terms" && termsQuestion()}
        {questionType === "website" && websiteQuestion()}
      </div>
    </MaterialModal>
  );
}
