import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

export default function TagsInput({
  value,
  onChange,
}: {
  value: string[];
  onChange: (value: string[]) => void;
}) {
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    console.log(inputValue);
  }, [inputValue]);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" ||
      event.code === "Space" ||
      event.key === "Tab"
    ) {
      event.preventDefault();
      const inputValue = (event.target as HTMLInputElement).value.trim();

      if (inputValue && !value.includes(inputValue)) {
        onChange([...value, inputValue]);
      }
      setInputValue("");
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]} // No predefined options
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      onChange={(_, newValue) => onChange(newValue)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option} key={index} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Type and press Enter, Space or Tab"
          onKeyDown={handleKeyDown}
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          id="multi-input"
          sx={{
            "& .MuiOutlinedInput-root": {
              border: "1px solid #ebecec",
              background: "var(--backgrounds-grouped-secondary, #fff)",
              borderRadius: "10px",
              minHeight: "37px",
              padding: "0px 14px",
              height: "auto",
              "& .MuiOutlinedInput-input": {
                padding: "8px 14px",
              },
            },
          }}
        />
      )}
    />
  );
}
