import { useState, useEffect, useMemo } from "react";

// Initialize scroll tracking variables outside React using a safer approach
interface CustomWindow extends Window {
  _lastScrollY: number;
  _ticking: boolean;
}
declare let window: CustomWindow;

window._lastScrollY = window.scrollY;
window._ticking = false;

const ScrollMenu = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Store current scroll position
      const currentScrollY = window.scrollY;

      // Only request animation frame if we're not already doing it
      if (!window._ticking) {
        window.requestAnimationFrame(() => {
          setIsVisible(window._lastScrollY > currentScrollY);
          window._lastScrollY = currentScrollY;
          window._ticking = false;
        });

        window._ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty dependency array since we're using window variables

  // Memoize the menu class string
  const menuClasses = useMemo(
    () =>
      `fixed top-0 left-0 w-full transition-transform duration-300 ease-in-out ${
        isVisible ? "translate-y-0" : "-translate-y-full"
      }`,
    [isVisible]
  );

  // Memoize static content
  const contentSections = useMemo(
    () =>
      Array.from({ length: 5 }, (_, i) => (
        <div key={i + 1} style={{ marginBottom: "4rem" }}>
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Section {i + 1}
          </h2>
          <div
            style={{
              backgroundColor: "white",
              padding: "1.5rem",
              borderRadius: "0.5rem",
              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
            }}
          >
            <p style={{ marginBottom: "1rem", color: "#4B5563" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <p style={{ marginBottom: "1rem", color: "#4B5563" }}>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
            <p style={{ color: "#4B5563" }}>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </p>
          </div>
        </div>
      )),
    []
  );

  const menuContent = useMemo(
    () => (
      <div
        style={{
          maxWidth: "80rem",
          margin: "0 auto",
          padding: "0 1rem",
          // "@media (min-width: 640px)": { padding: "0 1.5rem" },
          // "@media (min-width: 1024px)": { padding: "0 2rem" },
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "4rem",
          }}
        >
          <div style={{ flexShrink: 0 }}>
            <span style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              Logo
            </span>
          </div>

          <nav
            style={{
              display: "none",
              // "@media (min-width: 768px)": { display: "block" },
            }}
          >
            <ul style={{ display: "flex", gap: "2rem" }}>
              <li></li>
            </ul>
          </nav>
        </div>
      </div>
    ),
    []
  );

  return (
    <div style={{ minHeight: "100vh" }}>
      {/* Menu Bar */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
          transition: "transform 300ms ease-in-out",
          zIndex: 50,
          transform: isVisible ? "translateY(0)" : "translateY(-100%)",
        }}
      >
        {menuContent}
      </div>

      {/* Page Content */}
      <main
        style={{
          paddingTop: "5rem",
          maxWidth: "80rem",
          margin: "0 auto",
          padding: "0 1rem",
        }}
      >
        {/* Hero Section */}
        <div
          style={{
            padding: "3rem 0",
            backgroundColor: "#F9FAFB",
            borderRadius: "0.5rem",
            marginBottom: "2rem",
          }}
        >
          <h1
            style={{
              fontSize: "2.25rem",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Welcome to Our Website
          </h1>
          <p
            style={{
              fontSize: "1.25rem",
              textAlign: "center",
              color: "#4B5563",
            }}
          >
            Scroll down to see the menu behavior
          </p>
        </div>

        {contentSections}

        <footer
          style={{
            padding: "2rem 0",
            textAlign: "center",
            color: "#4B5563",
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <p>© 2025 Your Company. All rights reserved.</p>
        </footer>
      </main>
    </div>
  );
};

export default ScrollMenu;
