import React, { useRef, useState } from "react";
import "./CreateEvent.css";
import CircleIcon from "../../resources/images/create_event/circle_icon.svg";
import AlarmIcon from "../../resources/images/create_event/alarm_icon.svg";
import ExpandDiagonalIcon from "../../resources/images/create_event/expand_diagonal_icon.svg";
import LetsGoIcon from "../../resources/images/create_event/lets_go_icon.svg";
import CircleFilledCrossIcon from "../../resources/images/create_event/circle_filled_cross_icon.svg";
// import EditBlastIcon from "../../resources/images/create_event/edit_blast_icon.svg";
import AlarmClockIcon from "../../resources/images/create_event/alarm_clock_icon.svg";
import DarkEditIcon from "../../resources/images/create_event/dark_edit_icon.svg";
import "./CreateEventModal.css";
import TestIcon from "../../resources/images/create_event/test_icon.svg";
import { insightDivider } from "./Insights";
import MaterialModal from "./MaterialModal";
import UKProfile from "../../resources/images/create_event/uk_profile.jpeg";

export default function Blasts() {
  const sendBlastInputRef = useRef<HTMLInputElement>(null);
  const [showSendButton, setShowSendButton] = useState(false);
  const [eventBlastModalOpen, setEventBlastModalOpen] = useState(false);
  const [blastText, setBlastText] = useState("");
  const [manageRemindersOpen, setManageRemindersOpen] = useState(false);
  const [automaticReminders, setAutomaticReminders] = useState(false);
  const sendBlast = () => {
    return (
      <div className="send_blast_container">
        <div className="send_blast_input_container">
          <img src={CircleIcon} alt="circle_icon" />
          <input
            ref={sendBlastInputRef}
            className="send_blast_input send_blast_container_text"
            placeholder="Send an echo to your guests..."
            onFocus={() => setShowSendButton(true)}
            value={blastText}
            onChange={(e) => setBlastText(e.target.value)}
          />
        </div>
        {showSendButton && <div className="send_blast_input_divider" />}
        {showSendButton && (
          <div className="send_blast_button_container">
            <div className="send_blast_button_container_icon">
              <img src={ExpandDiagonalIcon} alt="expand_icon" />

              <p className="advanced_text">Advanced</p>
            </div>
            <button
              className={`send_blast_button ${
                blastText.length > 0
                  ? "send_blast_button_active"
                  : "send_blast_button_inactive"
              }`}
            >
              <img src={LetsGoIcon} alt="lets_go_icon" />
              Send
            </button>
          </div>
        )}
      </div>
    );
  };

  const sentBlast = () => {
    const sentArr = [
      {
        name: "Utkarsh",
        msg: "Hey, I'm coming to the event. Can't wait to see you there!",
        sentTo: "Going",
        time: new Date()
          .toLocaleString("en-US", {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
          .replace(",", ""),
      },
      {
        name: "Astha",
        msg: "Great to see you Astha, hope you have a great time at the event!",
        sentTo: "Going",
        time: "19 Dec, 7:13 PM",
      },
    ];

    const sentBlastCard = (
      item: {
        name: string;
        msg: string;
        sentTo: string;
        time: string;
      },
      index: number
    ) => {
      return (
        <div
          className="sent_blast_card"
          key={index + "sent_blast_card"}
          onClick={() => setEventBlastModalOpen(true)}
        >
          <img
            src={CircleIcon}
            alt="circle_icon"
            style={{ height: "fit-content" }}
          />
          <div className="sent_blast_card_content">
            <div className="sent_blast_card_content_top">
              <p className="sent_blast_card_content_top_name">{item.name}</p>
              <p className="sent_blast_card_content_top_time">{item.time}</p>
            </div>
            <p className="sent_blast_card_content_msg">{item.msg}</p>
            <p className="sent_blast_card_content_sentTo">{item.sentTo}</p>
          </div>
        </div>
      );
    };
    return (
      <div className="sent_blast_container">
        <p className="sent_blast_container_text">Sent</p>
        <div className="sent_blast_container_cards">
          {sentArr.map((item, index) => sentBlastCard(item, index))}
        </div>
      </div>
    );
  };

  const systemMessage = () => {
    const sysMsgArr = [
      {
        title: "Event Reminders",
        description:
          "Reminders are sent automatically via email, SMS, and push notification.",
        button_text: "Manage Reminders",
        onClick: () => setManageRemindersOpen(true),
      },
      {
        title: "Post-Event Feedback",
        description: "Schedule a feedback email to go out after the event.",
        button_text: "Schedule Email",
        onClick: () => setManageRemindersOpen(true),
      },
    ];

    const sysMsgCard = (
      item: {
        title: string;
        description: string;
        button_text: string;
        onClick: () => void;
      },
      index: number
    ) => {
      return (
        <div>
          <div
            className="system_message_card"
            key={index + "system_message_card"}
          >
            <div className="system_message_card_icon">
              <img src={AlarmIcon} alt="alarm_icon" />
            </div>
            <div className="system_message_card_content">
              <p className="system_message_card_content_title">{item.title}</p>
              <p className="system_message_card_content_description">
                {item.description}
              </p>
              <button
                className="system_message_card_content_button"
                onClick={item.onClick}
              >
                {item.button_text}
              </button>
            </div>
          </div>
          {index !== sysMsgArr.length - 1 && (
            <div className="system_message_card_divider" />
          )}
        </div>
      );
    };
    return (
      <div className="system_message">
        <p className="system_message_container_text">
          System message will be sent to all guests
        </p>
        <div className="system_message_container">
          {sysMsgArr.map((item, index) => sysMsgCard(item, index))}
        </div>
      </div>
    );
  };

  const modalNav = (heading: string, onClose: () => void) => {
    return (
      <div className="modal_nav">
        <p className="modal_nav_heading">{heading}</p>
        <img
          src={CircleFilledCrossIcon}
          alt="circle_filled_cross_icon"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </div>
    );
  };

  const editBlastModal = () => {
    return (
      <MaterialModal
        isOpen={true}
        onClose={() => setEventBlastModalOpen(false)}
        fullScreen={true}
      >
        {modalNav("Event Blast", () => setEventBlastModalOpen(false))}
        <div className="edit_blast_modal_container">
          <div className="particiapant_detail_list_container">
            <div className="particiapant_detail_list_container_item">
              <img
                src={UKProfile}
                alt="test_icon"
                className="particiapant_icon"
              />
              <div className="particiapant_detail_list_container_item_text">
                <p className="particiapant_name">Utkarsh</p>
                <p className="particiapant_time">
                  {new Date().toLocaleString("en-US", {
                    day: "2-digit",
                    month: "short",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </p>
              </div>
              <button className="participant_edit_button">
                <img src={DarkEditIcon} alt="edit_icon" />
                Edit
              </button>
            </div>
          </div>
          <p className="edit_blast_modal_container_text">
            Hey, I'm coming to the event. Can't wait to see you there!
          </p>
          {insightDivider(19, 14)}
          <div className="edit_blast_modal_container_text_container">
            <p className="edit_blast_modal_container_text_secondary">
              Sent to: Going . 1 Guest
            </p>
            <p className="edit_blast_modal_container_text_secondary">
              0% Email Open Rate
            </p>
          </div>
        </div>
      </MaterialModal>
    );
  };

  const manageRemindersModal = () => {
    const reminder_arr = [
      {
        date: "24 Dec",
      },
      {
        date: "25 Dec",
      },
    ];
    return (
      <MaterialModal
        isOpen={true}
        onClose={() => setManageRemindersOpen(false)}
        fullScreen={true}
      >
        {modalNav("Event Reminders", () => setManageRemindersOpen(false))}
        <div className="manage_reminders_modal_container">
          <div className="modal_heading_container">
            <p className="modal_heading_container_text">Automatic Reminders</p>
            <div
              id="slidingToggle"
              className={`toggle-container ${
                automaticReminders ? "active" : ""
              }`}
              onClick={() => setAutomaticReminders(!automaticReminders)}
            >
              <div className="slider"></div>
            </div>
          </div>
          <p className="reminder_subtext">
            Reminders are scheduled for 1 day and 1 hour before the event.
          </p>
          <div className="reminder_list_container">
            {reminder_arr.map((item, index) => (
              <div
                className="reminder_list_container_item"
                key={"reminder_list_container_item_" + index}
                style={{
                  borderBottom:
                    index === reminder_arr.length - 1
                      ? "none"
                      : "1px solid #E7E8E9",
                }}
              >
                <div className="reminder_list_container_item_top">
                  <img src={AlarmClockIcon} alt="alarm_clock_icon" />
                  <p className="reminder_list_container_item_title">
                    Spiti Overlanding is starting tomorrow
                  </p>
                </div>
                <div className="reminder_list_container_item_bottom">
                  <p className="reminder_list_container_item_to">To: Going</p>
                  <p className="reminder_list_container_item_time">
                    Sent: {item.date}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </MaterialModal>
    );
  };
  return (
    <div>
      {sendBlast()}
      {sentBlast()}
      {systemMessage()}
      {eventBlastModalOpen && editBlastModal()}
      {manageRemindersOpen && manageRemindersModal()}
    </div>
  );
}
