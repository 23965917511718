import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import "./Modal.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MaterialModal({
  isOpen,
  onClose,
  fullScreen,
  children,
  paperStyles,
  ...props
}: {
  isOpen: boolean;
  onClose: () => void;
  fullScreen?: boolean;
  children: React.ReactNode;
  props?: any;
  paperStyles?: React.CSSProperties;
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      keepMounted
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      {...props}
      PaperProps={{
        style: {
          borderRadius: fullScreen ? 0 : 20,
          maxHeight: fullScreen ? "auto" : "75vh",
          width: "100%",
          maxWidth: "450px",
          ...paperStyles,
        },
      }}
    >
      {children}
    </Dialog>
  );
}
