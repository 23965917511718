import React, { useState } from "react";
import Navbar from "../eventv2/Navbar";
import styles from "./Auth.module.css";
import ColorBg from "../../resources/images/eventv2/colorbg.png";
import {
  AuthButton,
  AuthHeading,
  AuthInput,
  AuthOrBlock,
} from "./AuthComponents";
import SocialLogin from "./SocialLogin";
import LoginModal from "./LoginModal";

export default function LoginCreator() {
  const [currentScreen, setCurrentScreen] = useState("login");
  const inputBoxes = () => {
    return (
      <div className={styles.auth_input_container}>
        <AuthInput label="Email" placeholder="Enter your phone/email" />
        <AuthInput label="Password" placeholder="Enter your password" />
      </div>
    );
  };

  const login = () => {
    return (
      <div style={{ paddingBottom: 54 }}>
        <AuthHeading
          heading="Welcome Back!"
          subheading="Explore best experiences with us !"
          subheading2="Need an account? "
          subheading3="Sign up"
        />
        {inputBoxes()}
        <p
          className={styles.forgot_password_text}
          onClick={() => {
            setCurrentScreen("forgotPassword");
          }}
        >
          Forgot Password?
        </p>
        <AuthButton
          label="Sign In"
          style={{ marginTop: 16 }}
          onClick={() => {}}
        />
        <AuthOrBlock />
        <SocialLogin />
      </div>
    );
  };

  const forgotPassword = () => {
    return (
      <div>
        <AuthHeading heading="Forgot Password?" />
        <div style={{ marginTop: 36 }}>
          <AuthInput label="Email" placeholder="Enter your phone/email" />
          <AuthButton label="Send OTP" style={{ marginTop: 16 }} />
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        background: `url(${ColorBg})`,
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      {/* <Navbar />
      <div className={styles.auth_container}>
        {currentScreen === "login" && login()}
        {currentScreen === "forgotPassword" && forgotPassword()}
      </div> */}
      <LoginModal
        isOpen={true}
        onClose={() => {}}
        title={"Login to continue"}
        text={"Please login to see your wishlist"}
        onLoginSuccess={() => {}}
      />
    </div>
  );
}
