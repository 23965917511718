import React, { useState } from "react";
import "./CreateEvent.css";
import PlusIcon from "../../resources/images/create_event/plus_icon.svg";
import TripleDotIcon from "../../resources/images/create_event/triple_dot_icon.svg";
import Check1Icon from "../../resources/images/create_event/check_1.svg";
import Check2Icon from "../../resources/images/create_event/check_2.svg";
import Check3Icon from "../../resources/images/create_event/check_3.svg";
import ContactIcon from "../../resources/images/create_event/contact_icon.svg";
import MailIcon from "../../resources/images/create_event/mail_icon.svg";
import PhoneIcon from "../../resources/images/create_event/mobile_icon.svg";
import PersonIcon from "../../resources/images/create_event/person_icon.svg";
import CustomIcon from "../../resources/images/create_event/custom_icon.svg";
import CustomQuestion from "./CustomQuestion";
import MaterialModal from "./MaterialModal";
import {
  CustomQuestionInput,
  CustomQuestionRequiredBlock,
  CustomQuestionTitle,
  ModalButtonPrimary,
  ModalIcon,
  ModalTextPrimary,
  ModalTitle,
} from "./ModalComponent";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import CopyIcon from "../../resources/images/create_event/copy_icon.svg";
import RegistrationOpenIcon from "../../resources/images/create_event/registration_open_icon.svg";
import EventCapacityIcon from "../../resources/images/create_event/event_capacity_icon.svg";
import GroupRegistrationIcon from "../../resources/images/create_event/group_registration_icon.svg";
import LockIcon from "../../resources/images/create_event/lock_icon.svg";
import { useMediaQuery } from "@mui/material";

export default function Registration() {
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);
  const [isRegistrationEmailOpen, setIsRegistrationEmailOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(0);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  const [isRegOpen, setIsRegOpen] = useState(true);
  const [isEventCapacityOpen, setIsEventCapacityOpen] = useState(false);
  const [isOverCapacityWaitingListOpen, setIsOverCapacityWaitingListOpen] =
    useState(false);
  const [isGroupRegistrationOpen, setIsGroupRegistrationOpen] = useState(false);
  const [isGroupRegistrationAllowed, setIsGroupRegistrationAllowed] =
    useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const isLaptop = useMediaQuery("(min-width: 600px)");

  const tickets = () => {
    const ticketType = [
      {
        id: "ticket-1",
        name: "Standard",
        type: "Rs 14,999",
        tag: "Required Approval",
        number_of_registrations: 1,
      },
    ];
    return (
      <div className="tickets_container">
        <div className="tickets_container_header">
          <p className="tickets_container_header_text">Tickets</p>
          <div
            className="tickets_container_header_button"
            onClick={() => {
              setIsTicketModalOpen(true);
            }}
          >
            <img src={PlusIcon} alt="plus_icon" />
            <p className="tickets_container_header_button_text">
              New Ticket Type
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            marginTop: "12px",
            marginBottom: "18px",
          }}
        >
          {ticketType.map((ticket) => {
            return (
              <div className="ticket_type_container">
                <div className="ticket_type_container_left">
                  <p className="ticket_type_container_name">{ticket.name}</p>
                  <p className="ticket_type_container_type">{ticket.type}</p>
                </div>
                {/* <div className="ticket_type_container_tag_container">
                  <p className="ticket_type_container_tag">{ticket.tag}</p>
                </div> */}
                <div className="ticket_type_container_left">
                  <p className="ticket_type_container_number_of_registrations">
                    {ticket.number_of_registrations} Registrations
                  </p>
                  <img src={TripleDotIcon} alt="triple_dot_icon" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const registrationEmail = () => {
    const registrationEmailCards = [
      {
        icon: Check1Icon,
        title: "Pending Approval/ Waiting List",
      },
      {
        icon: Check2Icon,
        title: "Going",
      },
      {
        icon: Check3Icon,
        title: "Declined",
      },
    ];
    return (
      <div className="registration_email_container">
        <p className="registration_email_container_header">
          Registration Email
        </p>
        <p className="registration_email_container_subheading">
          Customise the emails sent when a guest registers for the event and for
          when you approve or decline their registration.
        </p>
        <div className="registration_email_cards_container">
          {registrationEmailCards.map((card, index) => {
            return (
              <div
                className="registration_email_cards"
                onClick={() => {
                  setIsRegistrationEmailOpen(true);
                  setCurrentEmail(index);
                }}
              >
                <div className="registration_email_cards_top">
                  <img
                    src={card.icon}
                    alt="card_icon"
                    style={{ marginLeft: 6 }}
                  />
                  <div className="fat_line_1" />
                  <div className="fat_line_2" />
                </div>
                <div className="registration_email_cards_bottom">
                  <p className="registration_email_cards_title">{card.title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const registrationQueries = () => {
    const questions = [
      {
        icon: PersonIcon,
        title: "Name",
        tag: "Required",
      },
      {
        icon: MailIcon,
        title: "Email",
        tag: "Required",
      },
      {
        icon: PhoneIcon,
        title: "Phone Number",
        tag: "",
        type: "dropdown",
      },
    ];
    return (
      <div className="registration_queries_container">
        <p className="registration_email_container_header">
          Registration Questions
        </p>
        <p className="registration_email_container_subheading">
          We will ask guests the following questions when they register for the
          event.
        </p>
        <div className="registration_queries_personal_container">
          <img src={ContactIcon} alt="contact_icon" />
          <p className="registration_queries_personal_container_text">
            Personal Information
          </p>
        </div>
        <div className="registration_queries_questions_container">
          {questions.map((question, index) => {
            return (
              <div className="registration_queries_questions">
                <img src={question.icon} alt="question_icon" />
                <div
                  className="registration_queries_questions_right"
                  style={{
                    borderBottom:
                      index === questions.length - 1
                        ? "none"
                        : "0.333px solid var(--Separators-Non-opaque, rgba(84, 84, 86, 0.34))",
                  }}
                >
                  <p className="registration_queries_questions_title">
                    {question.title}
                  </p>
                  <p className="registration_queries_questions_tag">
                    {question.tag}
                  </p>
                  {question.type === "dropdown" && (
                    <select className="registration_queries_questions_dropdown registration_queries_questions_dropdown_option">
                      <option className="registration_queries_questions_dropdown_option">
                        On
                      </option>
                      <option className="registration_queries_questions_dropdown_option">
                        Off
                      </option>
                    </select>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const customQuestions = () => {
    return (
      <div>
        <div className="registration_queries_personal_container">
          <img src={CustomIcon} alt="custom_icon" />
          <p className="registration_queries_personal_container_text">
            Custom Questions
          </p>
        </div>
        <div
          className="tickets_container_header_button"
          style={{ marginTop: 15, cursor: "pointer" }}
          onClick={() => setIsQuestionOpen(true)}
        >
          <img src={PlusIcon} alt="plus_icon" />
          <p className="tickets_container_header_button_text">Add Question</p>
        </div>
      </div>
    );
  };
  const registrationEmails = () => {
    const emailTypes = [
      {
        title: "Pending Approval/ Waiting List Email",
        text: "This email is sent when a guest registers for the event, notifying them that their registration is pending approval or that they are on the waiting list.",
        subject: "Your registration is pending approval for Founder's Meetup",
      },
      {
        title: "Confirmation Email",
        text: "This email is sent when a guest registers or when you approve a guest who is pending approval.",
        subject: "Your registration is confirmed for Founder's Meetup",
      },
      {
        title: "Declined Email",
        text: "This email is sent when you decline a guest's registration.",
        subject: "Your registration is declined for Founder's Meetup",
      },
    ];
    return (
      <div>
        <MaterialModal
          isOpen={isRegistrationEmailOpen}
          onClose={() => {
            setIsRegistrationEmailOpen(false);
          }}
          fullScreen={true}
        >
          <div>
            <div className="registration_email_modal_header">
              <ModalIcon
                icon={CrossModalIcon}
                width={20}
                padding={4}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsRegistrationEmailOpen(false);
                }}
              />
            </div>
            <div style={{ padding: "16px 20px" }}>
              <ModalTitle
                title={emailTypes[currentEmail].title}
                marginTop={0}
              />
              <ModalTextPrimary
                text={emailTypes[currentEmail].text}
                marginTop={8}
              />
              <div
                className="registration_email_modal_input_container"
                style={{ marginTop: 24 }}
              >
                <ModalTitle
                  title={`${emailTypes[currentEmail].subject}`}
                  marginTop={0}
                />
                <textarea
                  className="custom_question_input"
                  placeholder="Add your email content here"
                  style={{ marginTop: 16, height: 100 }}
                />
              </div>
            </div>
          </div>
        </MaterialModal>
      </div>
    );
  };

  const registrationOpenModal = () => {
    return (
      <div>
        <MaterialModal
          isOpen={isRegistrationOpen}
          onClose={() => {
            setIsRegistrationOpen(false);
          }}
        >
          <div style={{ padding: "16px 20px" }}>
            <ModalIcon
              icon={CopyIcon}
              width={32}
              padding={12}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsRegistrationOpen(false);
              }}
            />
            <ModalTitle title="Registration" marginTop={12} />
            <ModalTextPrimary
              text="Close registration to stop accepting new guests, including anyone who may have been invited."
              marginTop={8}
            />
            <ModalTextPrimary
              text="Please note that capacity and availability settings apply when registration is open."
              marginTop={8}
            />
            <CustomQuestionRequiredBlock
              isRequired={isRegOpen}
              setIsRequired={setIsRegOpen}
              marginTop={16}
              text="Accept Registration"
            />
            <ModalButtonPrimary
              title="Confirm"
              onClick={() => {
                setIsRegistrationOpen(false);
              }}
              marginTop={16}
            />
          </div>
        </MaterialModal>
      </div>
    );
  };

  const guestOptions = () => {
    const opts = [
      {
        title: "Registration",
        icon: RegistrationOpenIcon,
        backgroundColor: "#3cbd2c22",
        onClick: () => {
          setIsRegistrationOpen(true);
        },
      },
      {
        title: "Event Capacity",
        icon: EventCapacityIcon,
        backgroundColor: "#d9632622",
        onClick: () => {
          setIsEventCapacityOpen(true);
        },
      },
      {
        title: "Group Registration",
        icon: GroupRegistrationIcon,
        backgroundColor: "#ab46dd22",
        onClick: () => {
          setIsGroupRegistrationOpen(true);
        },
      },
    ];
    return (
      <div className="guest_options_container">
        {opts.map((opt, index) => (
          <div className="guest_options_buttons" onClick={opt.onClick}>
            <div
              style={{
                backgroundColor: opt.backgroundColor,
                padding: 6,
                borderRadius: 4,
                height: 32,
                width: 32,
                boxSizing: "border-box",
              }}
            >
              <img
                src={opt.icon}
                alt="invite_icon"
                style={{ width: 20, height: 20 }}
              />
            </div>
            <p className="guest_options_buttons_text">{opt.title}</p>
          </div>
        ))}
      </div>
    );
  };

  const eventCapacityModal = () => {
    return (
      <div>
        <MaterialModal
          isOpen={isEventCapacityOpen}
          onClose={() => {
            setIsEventCapacityOpen(false);
          }}
        >
          <div style={{ padding: "16px 20px" }}>
            <ModalIcon icon={CopyIcon} width={32} padding={12} />
            <ModalTitle title="Max capacity" marginTop={12} />
            <ModalTextPrimary
              text="Auto-close registration when the capacity is reached. Only approved guests count towards the cap."
              marginTop={8}
            />
            <CustomQuestionTitle title={"Capacity"} marginTop={16} />
            <CustomQuestionInput
              placeholder=""
              marginTop={6}
              props={{ value: 15 }}
            />
            <CustomQuestionRequiredBlock
              isRequired={isOverCapacityWaitingListOpen}
              setIsRequired={setIsOverCapacityWaitingListOpen}
              marginTop={16}
              text="Over-Capacity Waiting List"
            />
            <div className="event_capacity_modal_button_container">
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  setIsEventCapacityOpen(false);
                }}
              >
                <ModalButtonPrimary title="Set Limit" />
              </div>
              <div
                className="modal_button_secondary"
                onClick={() => {
                  setIsEventCapacityOpen(false);
                }}
              >
                Remove Limit
              </div>
            </div>
          </div>
        </MaterialModal>
      </div>
    );
  };

  const groupRegistrationModal = () => {
    return (
      <div>
        <MaterialModal
          isOpen={isGroupRegistrationOpen}
          onClose={() => {
            setIsGroupRegistrationOpen(false);
          }}
        >
          <div style={{ padding: "16px 20px" }}>
            <ModalIcon icon={CopyIcon} width={32} padding={12} />
            <ModalTitle title="Group Registration" marginTop={12} />
            <ModalTextPrimary
              text="If turned on, guests will be able to get multiple tickets at once."
              marginTop={8}
            />
            <ModalTextPrimary
              text="Learn more about group registration"
              marginTop={8}
              style={{
                color: "rgba(19, 21, 23, 0.36)",
                cursor: "pointer",
                fontSize: 14,
                lineHeight: "16px",
              }}
            />
            <CustomQuestionRequiredBlock
              isRequired={isGroupRegistrationAllowed}
              setIsRequired={setIsGroupRegistrationAllowed}
              marginTop={16}
              text="Group Registration"
            />
            <ModalButtonPrimary
              title="Confirm"
              onClick={() => {
                setIsGroupRegistrationOpen(false);
              }}
              marginTop={16}
            />
          </div>
        </MaterialModal>
      </div>
    );
  };
  const [isDescription, setIsDescription] = useState(false);
  const [isApprovalRequired, setIsApprovalRequired] = useState(false);
  const [isCapacityApplied, setIsCapacityApplied] = useState(false);
  const [isSaleStartApplied, setIsSaleStartApplied] = useState(false);
  const [isSaleEndApplied, setIsSaleEndApplied] = useState(false);
  const ticketModal = () => {
    return (
      <div>
        <MaterialModal
          isOpen={isTicketModalOpen}
          onClose={() => {
            setIsTicketModalOpen(false);
          }}
        >
          <div
            style={{
              padding: "16px 20px",
              width: isLaptop ? "auto" : "calc(100vw - 64px)",
              boxSizing: "border-box",
              background: "rgba(255, 255, 255, 0.867)",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ModalIcon icon={CopyIcon} width={32} padding={12} />

              <ModalIcon
                icon={CrossModalIcon}
                width={20}
                padding={4}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsTicketModalOpen(false);
                }}
              />
            </div>
            <ModalTitle title="New Ticket Type" marginTop={12} />

            <CustomQuestionTitle title={"Ticket Name"} marginTop={16} />
            <CustomQuestionInput
              placeholder="Friends and Family"
              marginTop={6}
            />
            {!isDescription && (
              <p
                className="modal_text_secondary"
                style={{ cursor: "pointer", marginTop: 8 }}
                onClick={() => setIsDescription(true)}
              >
                + Add Description
              </p>
            )}
            {isDescription && (
              <div>
                <CustomQuestionTitle title={"Description"} marginTop={8} />
                <CustomQuestionInput
                  placeholder="Exclusively for Friends and Family"
                  marginTop={6}
                />
              </div>
            )}
            <CustomQuestionRequiredBlock
              isRequired={isApprovalRequired}
              setIsRequired={setIsApprovalRequired}
              marginTop={16}
              text="Approval Required"
            />
            {!isCapacityApplied && (
              <div
                className="ticket_restriction_text_container"
                onClick={() => {
                  setIsCapacityApplied(true);
                }}
              >
                <img
                  src={LockIcon}
                  alt="lock_icon"
                  style={{ cursor: "pointer", width: 12.73, height: 12.73 }}
                />
                <p
                  className="modal_text_secondary"
                  onClick={() => setIsDescription(true)}
                  style={{ marginTop: 0 }}
                >
                  Restrict Dates / Capacity
                </p>
              </div>
            )}
            {isCapacityApplied && (
              <div>
                <CustomQuestionRequiredBlock
                  isRequired={isSaleStartApplied}
                  setIsRequired={setIsSaleStartApplied}
                  marginTop={28}
                  text="Sale Start"
                />
                {isSaleStartApplied && (
                  <CustomQuestionInput placeholder="" marginTop={12} />
                )}
                <CustomQuestionRequiredBlock
                  isRequired={isSaleEndApplied}
                  setIsRequired={setIsSaleEndApplied}
                  marginTop={16}
                  text="Sale End"
                />
                {isSaleEndApplied && (
                  <CustomQuestionInput placeholder="" marginTop={12} />
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 12,
                  }}
                >
                  <p className="custom_question_required_block_text">
                    Total Tickets
                  </p>
                  <CustomQuestionInput
                    placeholder=""
                    props={{ style: { width: 80 } }}
                  />
                </div>
              </div>
            )}
            <ModalButtonPrimary title="Create Ticket Type" marginTop={16} />
          </div>
        </MaterialModal>
      </div>
    );
  };

  return (
    <div>
      {guestOptions()}
      {tickets()}
      <div className="guest_options_container_line" />
      {registrationEmail()}
      <div className="guest_options_container_line" style={{ marginTop: 32 }} />
      {registrationQueries()}
      {customQuestions()}
      <CustomQuestion
        isOpen={isQuestionOpen}
        onClose={() => {
          setIsQuestionOpen(false);
        }}
      />
      {registrationEmails()}
      {registrationOpenModal()}
      {eventCapacityModal()}
      {groupRegistrationModal()}
      {ticketModal()}
    </div>
  );
}
