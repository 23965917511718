// import EventHome from "./screens/event_page/EventHome";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import EventDetail from "./screens/event_detail_page/EventDetail";
// import Event from "./screens/event_page_inside/Event";
// import Creator from "./screens/creator/Creator";
import Thanks from "./screens/thanks/Thanks";
// import HomeV2 from "./screens/home_v2/HomeV2";
import Discover from "./screens/discover/Discover";
// import Event1 from "./screens/event/Event";
import ExploreCity from "./screens/explore_city/ExploreCity";
import CreatorV2 from "./screens/creator/CreatorV2";
import SignupCreator from "./screens/auth/SignupCreator";
import SignupExplorer from "./screens/auth/SignupExplorer";
import LoginCreator from "./screens/auth/LoginCreator";
import LoginExplorer from "./screens/auth/LoginExplorer";
import PrivacyPolicy from "./screens/policy/PrivacyPolicy";
import Tnc from "./screens/policy/Tnc";
import DataDeletionPolicy from "./screens/policy/DataDeletionPolicy";
import { init } from "@amplitude/analytics-browser";
import { useEffect, useState } from "react";
import EventV2 from "./screens/eventv2/EventV2";
import CreateEvent from "./screens/CreateEvent/CreateEvent";
import Listing from "./screens/listing/Listing";
import EventV3 from "./screens/eventv2/EventV3";
import Login from "./screens/authv2/Login";
import Create from "./screens/CreateEvent/Create";
import BookingConfirmed from "./screens/eventv2/BookingConfirmed";
import ScrollMenu from "./screens/eventv2/scroll-menu-more-optimised";
import ColorBg from "./resources/images/eventv2/colorbg.png";
import CreatorEvents from "./screens/CreatorEvents/CreatorEvents";

function App() {
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    init("7746cf291a15cca4af42adbd5c67baf5");
    const token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("user_id");
    console.log("Auth state:", { token, userId }); // Debug log
    setToken(token);
    setUserId(userId);
  }, []);

  // Add a check for both token and user_id
  const isAuthenticated = () => {
    const token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("user_id");
    const isAuth = Boolean(token && userId);
    console.log("isAuthenticated check:", { token, userId, isAuth }); // Debug log
    return isAuth;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${ColorBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Listing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/tnc" element={<Tnc />} />
          <Route
            path="/data-deletion-policy"
            element={<DataDeletionPolicy />}
          />

          <Route path="/listing" element={<Listing />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/discover/:id" element={<ExploreCity />} />
          <Route
            path="/booking_confirmed/:booking_id"
            element={<BookingConfirmed />}
          />
          {/* <Route path="/event/:id" element={<Event1 />} /> */}
          {/* <Route path="/creator/:id" element={<CreatorV2 />} /> */}
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/signup-creator" element={<SignupCreator />} />
          <Route path="/signup-explorer" element={<SignupExplorer />} />
          <Route path="/login-creator" element={<LoginCreator />} />
          <Route path="/login-explorer" element={<LoginExplorer />} />
          <Route path="/eventv2" element={<EventV2 />} />
          <Route path="/event/:event_slug" element={<EventV3 />} />
          <Route path="/create-event-1" element={<CreateEvent />} />
          <Route path="/create" element={<Create />} />
          <Route path="/event/manage/:eventId" element={<CreateEvent />} />
          <Route
            path="/login"
            element={token ? <Navigate to="/" replace /> : <Login />}
          />
          <Route path="/scroll-menu" element={<ScrollMenu />} />
          <Route
            path="/creator/events"
            element={
              isAuthenticated() ? (
                <CreatorEvents />
              ) : (
                <Navigate
                  to="/login"
                  replace
                  state={{ from: "/creator/events" }}
                />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
