import React from "react";
import Navbar from "./Navbar";
import "./EventV2.css";
import RoundedCorner from "../../resources/images/eventv2/rounded_corner.svg";
import Tape from "../../resources/images/eventv2/tape.svg";
import Star from "../../resources/images/eventv2/stars.svg";
import Bookmark from "../../resources/images/eventv2/bookmark.svg";
import ArrowRight from "../../resources/images/eventv2/arrow_right.svg";
import Instagram from "../../resources/images/eventv2/insta.svg";
import Youtube from "../../resources/images/eventv2/youtube.svg";
import Test1 from "../../resources/images/eventv2/test-1.svg";
import Test2 from "../../resources/images/eventv2/test-2.svg";
import Test3 from "../../resources/images/eventv2/test-3.svg";
import TestImg1 from "../../resources/images/eventv2/test_1.svg";
import TestImg3 from "../../resources/images/eventv2/test_3.svg";
import TestImg4 from "../../resources/images/eventv2/test_4.svg";
// import Bg from "../../resources/images/eventv2/bg.svg";
import TestImg from "../../resources/images/eventv2/test_img.svg";
import ColorBg from "../../resources/images/eventv2/colorbg.png";
import HeartIcon from "../../resources/images/eventv2/heart_icon.svg";
import StayIcon from "../../resources/images/eventv2/stay_icon.svg";
import TestHotelImg from "../../resources/images/eventv2/test_hotel_img.svg";
import MealIcon from "../../resources/images/eventv2/meal_icon.svg";
import TestActivityImg from "../../resources/images/eventv2/test_activity_img.svg";
import TestActivityImg2 from "../../resources/images/eventv2/test_activity_img_2.svg";

export default function EventV2() {
  const heroSection = () => {
    return (
      <div className="hero_section_container">
        <div className="hero_section_top">
          <div className="hero_section_top_left">
            <p className="participant_count">4D 3N</p>
            <div className="pipe_icon" />
            <p className="participant_count">25 Participants</p>
            <img src={Star} alt="star" style={{ marginTop: 6 }} />
          </div>
          <div className="hero_section_top_right">
            <img src={Tape} alt="tape" className="tape_icon" />
            <img src={TestImg1} alt="hero_img" className="hero_img" />
            <p className="hero_section_top_right_text">1/20</p>
            <img
              src={RoundedCorner}
              alt="rounded_corner"
              className="rounded_corner_icon"
            />
          </div>
          <div className="creator_img_container">
            <img src={TestImg4} alt="creator_img" className="creator_img_1" />
            <div style={{ marginTop: 45 }}>
              <p className="creator_name">@wandering_kamya</p>
              <p className="location_text">in Jaisalmer</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const eventDescription = () => {
    return (
      <div className="event_description_container">
        <div className="event_description_top">
          <p className="event_description_top_text">Desert Safari with Kamya</p>
          <img src={Bookmark} alt="bookmark" className="bookmark_icon" />
        </div>
        <div className="event_dates_container">
          <p className="event_dates">18 Jan</p>
          <img
            src={ArrowRight}
            alt="arrow_right"
            className="arrow_right_icon"
          />
          <p className="event_dates">21 Jan</p>
        </div>
        <p className="event_description_bottom_text">
          Join Wandering Kamya for an unforgettable 3-day desert safari in
          Jaisalmer! Explore majestic forts, vibrant bazaars, and golden dunes.
          Enjoy camel rides, starlit bonfires, traditional Rajasthani cuisine,
          and cultural performances.
        </p>
      </div>
    );
  };

  const eventItinerary = () => {
    const iti = [
      {
        img: TestImg1,
        day: "Day One",
        date: "18 January, Monday",
        description:
          "Arrive in Jaisalmer and settle into your stay. Visit the stunning Jaisalmer Fort and explore vibrant local markets. In the evening, enjoy a camel safari and watch a magical desert sunset. End the day with a traditional Rajasthani dinner under the stars.",
      },
      {
        img: TestImg3,
        day: "Day two",
        date: "19 January, Tuesday",
        description:
          "Arrive in Jaisalmer and settle into your stay. Visit the stunning Jaisalmer Fort and explore vibrant local markets. In the evening, enjoy a camel safari and watch a magical desert sunset. End the day with a traditional Rajasthani dinner under the stars.",
      },
      {
        img: TestImg3,
        day: "Day three",
        date: "20 January, Wednesday",
        description:
          "Arrive in Jaisalmer and settle into your stay. Visit the stunning Jaisalmer Fort and explore vibrant local markets. In the evening, enjoy a camel safari and watch a magical desert sunset. End the day with a traditional Rajasthani dinner under the stars.",
      },
    ];

    const cardCss = [
      {
        paddingTop: 150,
        itinerary_cards_container: {
          background:
            "linear-gradient(180deg, #FFF 0%, #EFE5D6 7.46%, #E3E3CB 100%)",
          padding: "24px 20px 14px",
        },
        itinerary_cards_top: {
          transform: "rotate(0.491deg)",
        },
        itinerary_day_text: {
          transform: "rotate(0.983deg)",
        },
        itinerary_img: {
          transform: "rotate(0.52deg)",
        },
        itinerary_description_text: {
          transform: "rotate(0.99deg)",
        },
      },
      {
        paddingTop: 210,
        itinerary_cards_container: {
          background: "#FFB2A5",
          padding: "22px 20px 24px",
          filter: "drop-shadow(0px -4px 5px rgba(144, 144, 144, 0.25))",
        },
        itinerary_cards_top: {
          transform: "rotate(-1deg)",
        },
        itinerary_day_text: {
          transform: "rotate(-0.983deg)",
        },
        itinerary_img: {
          transform: "rotate(-1deg)",
        },
        itinerary_description_text: {
          transform: "rotate(-0.99deg)",
        },
      },
      {
        paddingTop: 260,
        itinerary_cards_container: {
          background:
            "linear-gradient(180deg, #EFCFBA 0%, #EFCFBA 91.37%, #FFF 100%)",
          padding: "14px 20px 64px",
          filter: "drop-shadow(0px -4px 5px rgba(144, 144, 144, 0.25))",
        },
        itinerary_cards_top: {},
        itinerary_day_text: {},
        itinerary_img: {},
        itinerary_description_text: {},
      },
    ];

    const itineraryCards = (
      item: {
        img?: string;
        day: any;
        date?: string;
        description?: string;
      },
      index: number
    ) => {
      return (
        <div
          style={{
            position: "sticky",
            top: 20 + (index + 1) * 80,
          }}
        >
          <div
            className="itinerary_cards_container"
            key={"itn" + item.day}
            style={{
              ...cardCss[index % 3].itinerary_cards_container,
            }}
          >
            <div
              className="itinerary_cards_top"
              style={cardCss[index % 3].itinerary_cards_top}
            >
              <p
                className="itinerary_day_text"
                style={cardCss[index % 3].itinerary_day_text}
              >
                {item.day}
              </p>
              <div className="itn_pipe" />
              <p
                className="itinerary_date_text"
                style={cardCss[index % 3].itinerary_day_text}
              >
                {item.date}
              </p>
            </div>
            <img
              src={item.img}
              alt="itinerary_img"
              className="itinerary_img"
              style={cardCss[index % 3].itinerary_img}
            />
            <p
              className="itinerary_description_text"
              style={cardCss[index % 3].itinerary_description_text}
            >
              {item.description}
            </p>
          </div>
        </div>
      );
    };

    return (
      <div className="event_itinerary_container">
        <p
          className="event_itinerary_heading"
          style={{ marginBottom: 20, position: "sticky", top: 60 }}
        >
          Itinerary
        </p>
        <div>
          {iti.map((item, index) => {
            return itineraryCards(item, index);
          })}
        </div>
      </div>
    );
  };

  const creatorSection = () => {
    return (
      <div className="creator_section_container">
        <div className="creator_section_top">
          <p className="creator_section_top_text">
            “Hi I am Kamya, a proud backpacker for the past decade and a creator
            with a 140K-strong following on Instagram. Join me as I curate
            unique experiences in the deserts of India.”
          </p>
          <img src={TestImg4} alt="creator_img" className="creator_img" />
        </div>
        <div className="creator_section_bottom">
          <div style={{ flex: 1, marginTop: 4 }}>
            <p className="creator_section_bottom_text_1">Backpacker</p>
            <p className="creator_section_bottom_text_2">Wandering Kamya</p>
          </div>
          <div className="creator_section_bottom_right">
            <img src={Instagram} alt="instagram" />
            <img src={Youtube} alt="youtube" />
            <p className="creator_section_bottom_right_text">2M+</p>
          </div>
        </div>
      </div>
    );
  };

  const testimonials = () => {
    const testimonial = [
      {
        icon: Test1,
        name: "BY SHREY RAWAL",
        description:
          "Camel rides, starry skies, and golden sands—perfect Jaisalmer adventure!",
      },
      {
        icon: Test2,
        name: "BY SHREY RAWAL",
        description:
          "Camel rides, starry skies, and golden sands—perfect Jaisalmer adventure!",
      },
      {
        icon: Test3,
        name: "BY SHREY RAWAL",
        description:
          "Camel rides, starry skies, and golden sands—perfect Jaisalmer adventure!",
      },
    ];

    const testimonialCard = (
      item: {
        icon: string;
        name: string;
        description: string;
      },
      index: number
    ) => {
      return (
        <div className="testimonial_card" key={"testimonial" + index}>
          <img src={item.icon} alt="testimonial_icon" />
          <p className="testimonial_description">{item.description}</p>
          <p className="testimonial_name">{item.name}</p>
        </div>
      );
    };
    return (
      <div className="testimonials_container">
        {testimonial.map((item, index) => {
          return testimonialCard(item, index);
        })}
      </div>
    );
  };

  const footer = () => {
    return (
      <div className="footer_container">
        <p className="footer_text">Explore Pricing</p>
        <button className="footer_button">Let’s go!</button>
      </div>
    );
  };

  return (
    //style={{ background: `url(${Bg})` }}
    <div style={{ background: `url(${ColorBg})`, backgroundSize: "cover" }}>
      <Navbar />
      <div style={{ maxWidth: "800px", margin: "0px auto" }}>
        {heroSection()}
        {eventDescription()}
        {eventItinerary()}
        {creatorSection()}
        {testimonials()}
      </div>
      {footer()}
    </div>
  );
}
