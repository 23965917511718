import React, { useEffect, useRef, useState } from "react";
import styles from "./Listing.module.css";
import Navbar from "../eventv2/Navbar";
import { ReactComponent as EventIcon } from "../../resources/images/listing/event_icon.svg";
import { ReactComponent as ForYouIcon } from "../../resources/images/listing/for_you_icon.svg";
import { ReactComponent as MountainIcon } from "../../resources/images/listing/mountain_icon.svg";
import { ReactComponent as WishlistIcon } from "../../resources/images/listing/wishlist_icon.svg";
import SearchIcon from "../../resources/images/listing/search_icon.svg";
import ArrowDown from "../../resources/images/listing/arrow_down.svg";
import FilterIcon from "../../resources/images/listing/filter_icon.svg";
import EventCard from "./EventCard";
import SwipingCarousel from "./SwipingCarousel";
import axios from "axios";
import { apiurl } from "../../commons/ApiHelper";
import { EventInterface } from "../../commons/Interfaces";
import { ModalIcon } from "../CreateEvent/ModalComponent";
import CrossModalIcon from "../../resources/images/create_event/cross_modal_icon.svg";
import LoginModal from "../authv2/LoginModal";
import { authenticatedRequest } from "../../commons/AuthenticatedRequest";
import { useMediaQuery } from "@mui/material";
import Menu from "../CreateEvent/Menu";

enum EventTheme {
  Adventure = "Adventure",
  Lifestyle = "Lifestyle",
  Food = "Food",
  Workshop = "Workshop",
}

export default function Listing() {
  const isLaptop = useMediaQuery("(min-width: 600px)");
  const [allEvents, setAllEvents] = useState<EventInterface[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventInterface[]>([]);
  const [bottomFilter, setBottomFilter] = useState<string[]>([]);
  const [bottomFilterEvents, setBottomFilterEvents] = useState<
    EventInterface[]
  >([]);
  const [isReversing, setIsReversing] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [wishlistEvents, setWishlistEvents] = useState<EventInterface[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<EventInterface[]>([]);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const handleFilterClick = (title: string) => {
    if (bottomFilter.includes(title)) {
      setBottomFilter(bottomFilter.filter((filter) => filter !== title));
      setAnchorEl(null);
    } else {
      setBottomFilter([...bottomFilter, title]);
      setAnchorEl(null);
    }
  };
  const filterOptions = [
    {
      title: EventTheme.Adventure,
      onClick: () => handleFilterClick(EventTheme.Adventure),
    },
    {
      title: EventTheme.Lifestyle,
      onClick: () => handleFilterClick(EventTheme.Lifestyle),
    },
    {
      title: EventTheme.Food,
      onClick: () => handleFilterClick(EventTheme.Food),
    },
    {
      title: EventTheme.Workshop,
      onClick: () => handleFilterClick(EventTheme.Workshop),
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    axios
      .post(apiurl + "events/fetch_all_events", {
        user_id: user_id,
      })
      .then((res) => {
        setAllEvents(res.data.all_events);
        setFilteredEvents(res.data.all_events);
        setBottomFilterEvents(res.data.all_events);
        console.log(res.data.all_events);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [activeFeaturedFilter, setActiveFeaturedFilter] = useState(
    "featured_filter_option_1"
  );
  useEffect(() => {
    console.log(bottomFilter);
    setBottomFilterEvents(
      allEvents.filter((event) => {
        if (bottomFilter.length === 0) {
          return true;
        }
        if (activeFeaturedFilter === "featured_filter_option_1") {
          return bottomFilter.includes(event.theme);
        } else if (activeFeaturedFilter === "featured_filter_option_2") {
          return event.event_type === "Experience";
        } else if (activeFeaturedFilter === "featured_filter_option_3") {
          return event.event_type === "Event";
        }
        return true;
      })
    );
  }, [bottomFilter, allEvents, activeFeaturedFilter]);

  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = 10;
      let scrollPercent = scrollTop / docHeight;
      setScrollProgress(Math.min(scrollPercent, 2));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    console.log(activeFeaturedFilter);
  }, [activeFeaturedFilter, allEvents]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setSearchResults(
      allEvents.filter(
        (event) =>
          event.title.toLowerCase().includes(query.toLowerCase()) ||
          event.host.name.toLowerCase().includes(query.toLowerCase()) ||
          event.city.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  useEffect(() => {
    console.log({ bottomFilterEvents });
  }, [bottomFilterEvents]);
  const searchBar = () => {
    return (
      <div className={styles.search_bar_container}>
        <img src={SearchIcon} alt="search_icon" />
        <input
          type="text"
          placeholder="Search"
          className={styles.search_bar_input}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
    );
  };

  useEffect(() => {
    setIsReversing(true);
    setTimeout(() => {
      let newFilteredEvents = [...allEvents];

      switch (activeFeaturedFilter) {
        case "featured_filter_option_1": // For You
          // Keep all events
          break;
        case "featured_filter_option_2": // Experiences
          newFilteredEvents = allEvents.filter(
            (event) => event.event_type === "Experience"
          );
          break;
        case "featured_filter_option_3": // Events
          newFilteredEvents = allEvents.filter(
            (event) => event.event_type === "Event"
          );
          break;
        default:
          break;
      }

      setFilteredEvents(newFilteredEvents.reverse());
      setIsReversing(false);
    }, 300);
  }, [activeFeaturedFilter, allEvents]);

  const handleFeaturedFilterClick = (id: string) => {
    if (id === "wishlist") {
      // setIsLoginModalOpen(true);
      authenticatedRequest("events/wishlist", {
        method: "GET",
      })
        .then((res: any) => {
          setActiveFeaturedFilter(id);
          console.log(res);
          setWishlistEvents(res);
        })
        .catch((err) => {
          console.log(err);
          setIsLoginModalOpen(true);
        });
    } else {
      setActiveFeaturedFilter(id);
    }
  };

  const handleRemoveWishlist = (eventId: string) => {
    setWishlistEvents(
      wishlistEvents.filter((event) => event.eventId !== eventId)
    );
  };
  const [isSingleWishlistEvent, setIsSingleWishlistEvent] = useState(false);

  const handleLoginSuccess = () => {
    if (!isSingleWishlistEvent) {
      handleFeaturedFilterClick("wishlist");
    } else {
      setIsSingleWishlistEvent(false);
    }
  };

  const handleWishlistEvent = (e: React.MouseEvent, event: EventInterface) => {
    e.stopPropagation();
    console.log(event);
    const url = event?.is_wishlisted
      ? "events/wishlist/remove"
      : "events/wishlist/add";
    authenticatedRequest(url, {
      method: "POST",
      data: {
        eventId: event.eventId,
      },
    })
      .then((res) => {
        console.log(res);
        handleRemoveWishlist(event.eventId);
        setAllEvents(
          allEvents.map((e) => {
            if (e.eventId === event.eventId) {
              return { ...e, is_wishlisted: !event.is_wishlisted };
            }
            return e;
          })
        );
      })
      .catch((err) => {
        setIsLoginModalOpen(true);
        setIsSingleWishlistEvent(true);
      });
  };

  const featuredFlterOptions = () => {
    const options = [
      {
        id: "featured_filter_option_1",
        icon: ForYouIcon,
        title: "For You",
        filter: "for_you",
      },
      {
        id: "featured_filter_option_2",
        icon: MountainIcon,
        title: "Experiences",
        filter: "experience",
      },
      {
        id: "featured_filter_option_3",
        icon: EventIcon,
        title: "Events",
        filter: "event",
      },
      {
        id: "wishlist",
        icon: WishlistIcon,
        title: "Wishlist",
        filter: "wishlist",
      },
    ];
    return (
      <div className={styles.featured_filter_options_container}>
        {options.map((option, index) => (
          <div
            className={`${styles.featured_filter_options_item} ${
              activeFeaturedFilter === option.id
                ? styles.featured_filter_options_item_active
                : ""
            }`}
            key={index + option.title}
            onClick={() => handleFeaturedFilterClick(option.id)}
            style={
              {
                ["--scroll-progress" as string]: scrollProgress,
                ["--gap" as string]: scrollProgress === 2 ? 0 : 4,
                marginBottom: 24,
              } as React.CSSProperties
            }
          >
            <option.icon
              className={`${styles.featured_filter_options} ${
                activeFeaturedFilter === option.id
                  ? styles.featured_filter_options_item_icon_active
                  : styles.featured_filter_options_item_icon
              }`}
            />
            <span
              className={`${styles.featured_filter_options_item_title} ${
                activeFeaturedFilter === option.id
                  ? styles.featured_filter_options_item_title_active
                  : ""
              }`}
            >
              {option.title}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const titleComponent = (title: string) => {
    return <p className={styles.title}>{title}</p>;
  };

  const featuredExprience = () => {
    return (
      <div className={styles.featured_experience_container}>
        <div style={{ paddingTop: 36 }}>
          {titleComponent(
            activeFeaturedFilter === "featured_filter_option_1"
              ? "Handpicked"
              : activeFeaturedFilter === "featured_filter_option_2"
              ? "Featured"
              : "Featured "
          )}
        </div>
        <div
          className={`${isReversing ? styles.reversing : ""}`}
          style={{
            marginTop: 24,
            marginLeft: -24,
            marginRight: -24,
            transition: "opacity 0.3s ease-out",
          }}
        >
          <SwipingCarousel
            events={filteredEvents}
            handleWishlistEvent={handleWishlistEvent}
          />
        </div>
      </div>
    );
  };

  const allExprience = () => {
    const handleBottomFilter = (id: string) => {
      if (bottomFilter.includes(id)) {
        setBottomFilter(bottomFilter.filter((filter) => filter !== id));
      } else {
        setBottomFilter([...bottomFilter, id]);
      }
    };
    const allExperienceOptions = () => {
      return (
        <div
          style={{
            display: "flex",
            gap: 10,
            overflow: "auto",
            padding: "24px 0px",
          }}
        >
          <div
            className={styles.all_experience_option}
            ref={filterButtonRef}
            onClick={() => setAnchorEl(filterButtonRef.current)}
          >
            <img src={FilterIcon} alt="filter_icon" />
            Filter
            <img src={ArrowDown} alt="arrow_down" />
          </div>
          {filterOptions.map((option, index) => (
            <div
              key={index + option.title}
              className={`${styles.all_experience_option} ${
                bottomFilter.includes(option.title)
                  ? styles.all_experience_option_active
                  : ""
              }`}
              onClick={() => handleBottomFilter(option.title)}
            >
              {bottomFilter.includes(option.title) && (
                <ModalIcon
                  icon={CrossModalIcon}
                  width={8}
                  padding={4}
                  style={{ position: "absolute", top: -6, right: -6 }}
                  iconStyles={{ width: 14, height: 14 }}
                />
              )}
              {option.title}
            </div>
          ))}
        </div>
      );
    };
    return (
      <div className={styles.all_experience_container}>
        {titleComponent(
          activeFeaturedFilter === "featured_filter_option_1"
            ? "All Experiences"
            : activeFeaturedFilter === "featured_filter_option_2"
            ? "All Experiences"
            : "All Events"
        )}
        {allExperienceOptions()}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={`${styles.all_experience_cards_container} `}>
            {bottomFilterEvents.map((event, index) => (
              <EventCard
                key={index}
                event={event}
                handleWishlistEvent={handleWishlistEvent}
                style={{
                  height: isLaptop ? "calc(100vh - 176px)" : "",
                  width: isLaptop ? "calc((100vh - 176px) * (358/534))" : "",
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const wishListComponent = () => {
    return (
      <div className={styles.all_experience_container}>
        {titleComponent("Wishlist")}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={`${styles.all_experience_cards_container}`}>
            {wishlistEvents.map((event, index) => (
              <EventCard
                key={index}
                event={event}
                handleWishlistEvent={handleWishlistEvent}
                style={{
                  height: isLaptop ? "calc(100vh - 176px)" : "",
                  width: isLaptop ? "calc((100vh - 176px) * (358/534))" : "",
                }}
              />
            ))}
          </div>
        </div>
        {wishlistEvents.length === 0 && (
          <div className={styles.wishlist_empty_container}>
            <p>No events in your wishlist</p>
          </div>
        )}
      </div>
    );
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  const searchResultsComponent = () => {
    return (
      <div className={styles.all_experience_container}>
        {/* {titleComponent(`Search results for: ${searchQuery}`)} */}
        <p className={styles.search_results_title}>
          Search results for: {searchQuery}
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={`${styles.all_experience_cards_container}`}>
            {searchResults.map((event, index) => (
              <EventCard
                key={index}
                event={event}
                handleWishlistEvent={handleWishlistEvent}
                style={{
                  height: isLaptop ? "calc(100vh - 176px)" : "",
                  width: isLaptop ? "calc((100vh - 176px) * (358/534))" : "",
                }}
              />
            ))}
          </div>
        </div>
        {searchResults.length === 0 && (
          <div className={styles.wishlist_empty_container}>
            <p>No results found</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Navbar isCreate={true} />
      <div
        style={{
          padding: searchQuery === "" ? "24px 24px 0px" : "24px",
          position: "sticky",
          top: "var(--navbar-offset)",
          transition: "top 0.3s ease-in-out",
          zIndex: 48,
          background: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(4px)",
          backgroundSize: "cover",
          margin: "0px auto",
        }}
      >
        <div style={{ margin: "0px auto", maxWidth: 800 }}>
          {searchBar()}

          {searchQuery === "" && featuredFlterOptions()}
        </div>
      </div>
      {searchQuery === "" && (
        <div style={{ padding: 24, maxWidth: "800px", margin: "0px auto" }}>
          {activeFeaturedFilter !== "wishlist" && featuredExprience()}
          {activeFeaturedFilter !== "wishlist" && allExprience()}
          {activeFeaturedFilter === "wishlist" && wishListComponent()}
          <LoginModal
            isOpen={isLoginModalOpen}
            onClose={handleLoginModalClose}
            title={"Login to continue"}
            text={"Please login to see your wishlist"}
            onLoginSuccess={handleLoginSuccess}
          />
        </div>
      )}
      {searchQuery !== "" && (
        <div
          style={{
            padding: 24,
            maxWidth: "800px",
            margin: "0px auto",
            marginTop: 24,
          }}
        >
          {searchResultsComponent()}
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        menuItems={filterOptions}
        selectedItems={bottomFilter}
      />
    </div>
  );
}
