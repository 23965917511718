import React from "react";
import "./CreateEvent.css";
import ClockIcon from "../../resources/images/create_event/clock_icon.svg";
import ArrowDownIcon from "../../resources/images/create_event/chevron_down.svg";
import ArrowTitled from "../../resources/images/create_event/arrow_tilted.svg";
import NoReff from "../../resources/images/create_event/no_reff.svg";
import NoMail from "../../resources/images/create_event/no_mail.svg";
import { LineChart } from "@mui/x-charts/LineChart";
import { Box } from "@mui/material";

export default function Insights() {
  const insightHeader = () => {
    return (
      <div className="insight_header">
        <div className="insight_header_container">
          <p className="insight_header_text">Page Views</p>
          <button className="insight_header_button">
            <img src={ClockIcon} alt="clock_icon" />
            Past 7 Days
            <img src={ArrowDownIcon} alt="arrow_down_icon" />
          </button>
        </div>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          See recent page views of the event page.
        </p>
      </div>
    );
  };

  const insightChart = () => {
    const chartInfoSources = (left: string, right: string) => {
      return (
        <div className="chart_info_additional_container">
          <p className="chart_info_additional_text">{left}</p>
          <p className="chart_info_additional_text_2">{right}</p>
        </div>
      );
    };

    const data = [
      { x: "Jan 1", y: 30 },
      { x: "Jan 2", y: 45 },
      { x: "Jan 3", y: 60 },
      { x: "Jan 4", y: 80 },
    ];
    return (
      <div style={{ marginTop: 17 }}>
        {/* <div className="chart_div" /> */}
        <Box sx={{ width: "100%", height: 300 }}>
          <LineChart
            xAxis={[
              {
                scaleType: "band", // Sets the x-axis to categorical
                label: "Months",
                data: data.map((item) => item.x),
              },
            ]}
            series={[
              {
                data: data.map((item) => item.y),
              },
            ]}
            // width="100%"
            // height={300}
            className="chart_div"
          />
        </Box>
        <div className="chart_info_container">
          <p className="chart_info_heading">Page Views</p>
          <div className="chart_info_label_container">
            <div>
              <p className="chart_info_label_text">24 hours</p>
              <p className="chart_info_label_value">4 </p>
            </div>
            <div>
              <p className="chart_info_label_text">7 days</p>
              <p className="chart_info_label_value">34</p>
            </div>
            <div>
              <p className="chart_info_label_text">30 days</p>
              <p className="chart_info_label_value">34</p>
            </div>
          </div>
          <p className="chart_info_heading" style={{ marginTop: 15 }}>
            Sources
          </p>
          {chartInfoSources("Thrivia", "9%")}
          <p className="chart_info_heading" style={{ marginTop: 15 }}>
            Locations
          </p>
          {chartInfoSources("Delhi, IN", "79%")}
          {chartInfoSources("Udaipur, IN", "21%")}
          <p className="chart_info_heading" style={{ marginTop: 15 }}>
            UTM Sources
          </p>
          <p className="add_utm_text">
            Set up a tracking link by adding ?utm_source=your-link-name to your
            URL.{" "}
          </p>
        </div>
      </div>
    );
  };

  const registrationInsights = () => {
    return (
      <div>
        <div className="insight_header_container">
          <p className="insight_header_text">Registration Referrals</p>
          <button className="insight_header_button_2">
            Learn More
            <img src={ArrowTitled} alt="arrow_icon" />
          </button>
        </div>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Each guest receives a unique referral link to invite friends. See who
          has referred the most guests,
        </p>
        <div className="no_mail_container">
          <img src={NoReff} alt="no_reff" className="no_reff_image" />
        </div>
        <p className="no_reff_heading_text" style={{ marginTop: 19 }}>
          No Referrals
        </p>
        <p className="no_reff_content_text" style={{ marginTop: 9 }}>
          Referrals will start showing up here once guests start inviting their
          friends
        </p>
      </div>
    );
  };

  const mailInsights = () => {
    return (
      <div>
        <div className="insight_header_container">
          <p className="insight_header_text">Post Mail Feedback</p>
        </div>
        <p className="insight_header_subtext" style={{ marginTop: 5 }}>
          Each guest receives a unique referral link to invite friends. See who
          has referred the most guests,
        </p>
        <div className="no_mail_container">
          <img
            src={NoMail}
            alt="no_mail"
            className="no_reff_image"
            style={{ marginTop: 31 }}
          />
        </div>
        <p className="no_reff_heading_text" style={{ marginTop: -10 }}>
          No Post-Event Email Scheduled
        </p>
        <p className="no_reff_content_text" style={{ marginTop: 9 }}>
          To collect feedback, schedule a post-event thank you email. We will
          take care of the rest!
        </p>
        <div className="no_mail_button_container">
          <button className="insight_header_button">
            <img src={ClockIcon} alt="clock_icon" />
            Schedule Feedback Email
          </button>
        </div>
      </div>
    );
  };
  return (
    <div>
      {insightHeader()}
      {insightChart()}
      {insightDivider(24, 0)}
      {registrationInsights()}
      {insightDivider(24, 0)}
      {mailInsights()}
    </div>
  );
}

export const insightDivider = (top: number, bottom: number) => {
  return (
    <div
      style={{
        marginTop: top,
        marginBottom: bottom,
        width: "100%",
        background: " #E7E8E9",
        height: 1,
      }}
    />
  );
};
