import React, { useState, useEffect, useRef, useCallback } from "react";
import "./SwipingCarousel.css";
import EventCard from "./EventCard";
import { EventInterface } from "../../commons/Interfaces";
import { useMediaQuery } from "@mui/material";

const SwipingCarousel = ({
  events,
  handleWishlistEvent,
}: {
  events: EventInterface[];
  handleWishlistEvent: (e: React.MouseEvent, event: EventInterface) => void;
}) => {
  const isLaptop = useMediaQuery("(min-width: 600px)");
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const carouselRef = useRef(null);

  // const carouselItems = events.filter((event) => event.is_featured);
  const carouselItems = events;

  const moveToNextSlide = useCallback(() => {
    setActiveIndex((prev) => (prev < carouselItems.length - 1 ? prev + 1 : 0));
  }, [carouselItems.length]);

  const moveToPrevSlide = useCallback(() => {
    setActiveIndex((prev) => (prev > 0 ? prev - 1 : carouselItems.length - 1));
  }, [carouselItems.length]);

  const handleTouchStart = (e: any) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e: any) => {
    console.log("38", e);
    console.log("39", touchStart, touchEnd);
    const swipeThreshold = 50;
    if (touchEnd === 0) return;
    if (touchStart - e.changedTouches[0].clientX > swipeThreshold) {
      moveToNextSlide();
    }
    if (touchStart - e.changedTouches[0].clientX < -swipeThreshold) {
      moveToPrevSlide();
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;

    const handleMouseDown = (e: any) => {
      setTouchStart(e.clientX);
    };

    const handleMouseUp = (e: any) => {
      const diff = touchStart - e.clientX;
      const swipeThreshold = 50;
      if (diff > swipeThreshold) moveToNextSlide();
      if (diff < -swipeThreshold) moveToPrevSlide();
    };

    const handleKeyDown = (e: { key: string }) => {
      if (e.key === "ArrowRight") moveToNextSlide();
      if (e.key === "ArrowLeft") moveToPrevSlide();
    };
    if (carousel) {
      (carousel as HTMLElement).addEventListener("mousedown", handleMouseDown);
      (carousel as HTMLElement).addEventListener("mouseup", handleMouseUp);
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (carousel) {
        (carousel as HTMLElement).removeEventListener(
          "mousedown",
          handleMouseDown
        );
        (carousel as HTMLElement).removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [touchStart, carouselItems.length, moveToNextSlide, moveToPrevSlide]);

  const handleSwipe = (e: any) => {
    if (e.deltaX > 0) {
      moveToNextSlide();
    } else {
      moveToPrevSlide();
    }
  };

  return (
    <div
      ref={carouselRef}
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onScroll={handleSwipe}
      tabIndex={0}
    >
      {carouselItems.map((item, index) => {
        let className = "carousel-item";
        if (index === activeIndex) className += " active";
        if (index === activeIndex - 1) className += " prev";
        if (index === activeIndex + 1) className += " next";
        if (activeIndex === 0 && index === carouselItems.length - 1)
          className += " prev";
        if (activeIndex === carouselItems.length - 1 && index === 0)
          className += " next";

        return (
          <div
            key={index}
            className={className}
            style={{ ["--index" as string]: index - activeIndex }}
            onClick={() => setActiveIndex(index)}
          >
            <EventCard
              event={item}
              // width={!isLaptop ? "calc(100vw - 60px)" : "358px"}
              style={{
                height: isLaptop ? "var(--swiping-carousel-height)" : "",
                width: isLaptop
                  ? "var(--swiping-carousel-width)"
                  : "calc(100vw - 60px)",
              }}
              handleWishlistEvent={handleWishlistEvent}
              disableOnClick={activeIndex !== index}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SwipingCarousel;
