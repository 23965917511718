import axios from "axios";
import { apiurl } from "../commons/ApiHelper";
import { v4 as uuidv4 } from "uuid";

class AnalyticsService {
  private static sessionId: string | null = null;
  private static readonly CLIENT_ID_KEY = "thrivia_client_id";

  private static getOrCreateClientId(): string {
    let clientId = localStorage.getItem(this.CLIENT_ID_KEY);
    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem(this.CLIENT_ID_KEY, clientId);
    }
    return clientId;
  }

  private static isNewUser(): boolean {
    const lastVisit = localStorage.getItem("thrivia_last_visit");
    const now = new Date().toISOString();
    localStorage.setItem("thrivia_last_visit", now);
    return !lastVisit;
  }

  static async createSession(
    userId: string | null,
    url: string,
    device: string,
    newUser?: boolean
  ) {
    try {
      // Get or create client ID for anonymous tracking
      const clientId = this.getOrCreateClientId();

      // If userId is not provided (user not logged in), use clientId
      const effectiveUserId = userId || `anon_${clientId}`;

      // Determine if this is a new user
      const isFirstVisit = newUser ?? this.isNewUser();

      const response = await axios.post(`${apiurl}analytics/session`, {
        user_id: effectiveUserId,
        url,
        device,
        new_user: isFirstVisit,
        is_anonymous: !userId, // Add flag to indicate if this is an anonymous user
      });

      if (response.data.success) {
        this.sessionId = response.data.data._id;
        return response.data.data;
      }
    } catch (error) {
      console.error("Error creating analytics session:", error);
    }
  }

  static async trackEvent(
    url: string,
    eventType: string,
    elementId?: string,
    metadata?: any
  ) {
    if (!this.sessionId) {
      console.warn("No active analytics session");
      return;
    }

    console.log("metadata", metadata, elementId, eventType);
    try {
      await axios.post(`${apiurl}analytics/event`, {
        session_id: this.sessionId,
        url,
        event_type: eventType,
        element_id: elementId,
        metadata: {
          ...metadata,
          client_id: this.getOrCreateClientId(), // Include client ID in event metadata
          timestamp: new Date().toISOString(),
        },
      });
    } catch (error) {
      console.error("Error tracking analytics event:", error);
    }
  }

  static async updateSessionActiveTime(activeTime: number) {
    if (!this.sessionId) {
      console.warn("No active analytics session");
      return;
    }

    try {
      await axios.patch(
        `${apiurl}analytics/session/${this.sessionId}/active-time`,
        {
          active_time: activeTime,
        }
      );
    } catch (error) {
      console.error("Error updating session active time:", error);
    }
  }
}

export default AnalyticsService;
