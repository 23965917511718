import React from "react";
import styles from "./EventCard.module.css";

import HeartIcon from "../../resources/images/eventv2/heart_icon.png";
import { EventInterface } from "../../commons/Interfaces";
import HeartIconFilled from "../../resources/images/eventv2/heart_icon_filled.svg";

export const dateComponent = (month: string, day: string) => {
  return (
    <div className={styles.event_card_date_container}>
      <p className={styles.event_card_date_container_month}>{month}</p>
      <p className={styles.event_card_date_container_day}>{day}</p>
    </div>
  );
};

export default function EventCard({
  event,
  width,
  isNotLink,
  handleWishlistEvent,
  style,
  disableOnClick,
  removeWishlistEvent,
  url,
}: {
  isNotLink?: boolean;
  width?: string;
  event: EventInterface;
  handleWishlistEvent?: (e: React.MouseEvent, event: EventInterface) => void;
  style?: React.CSSProperties;
  disableOnClick?: boolean;
  removeWishlistEvent?: boolean;
  url?: string;
}) {
  const days = Math.ceil(
    (new Date(event?.end_date).getTime() -
      new Date(event?.start_date).getTime()) /
      (1000 * 60 * 60 * 24)
  );
  const nights = days - 1;
  return (
    <div
      className={styles.event_card_container}
      style={{
        backgroundSize: "cover",
        width: width,
        cursor: isNotLink ? "default" : "pointer",
        backgroundImage: `url(${event?.cover_photo})`,
        ...style,
      }}
      onClick={() => {
        if (isNotLink || disableOnClick) return;
        window.location.href = url || `/event/${event.event_slug}`;
      }}
    >
      <div className={styles.event_card_top_section}>
        {event?.start_date &&
          dateComponent(
            new Date(event?.start_date).toLocaleString("default", {
              month: "short",
            }),
            new Date(event?.start_date).toLocaleString("default", {
              day: "numeric",
            })
          )}
        {!removeWishlistEvent && (
          <img
            src={event?.is_wishlisted ? HeartIconFilled : HeartIcon}
            alt="heart_icon"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleWishlistEvent?.(e, event);
            }}
          />
        )}
      </div>
      <div className={styles.event_card_bottom_section}>
        <img
          src={event?.host?.profile_picture}
          alt="creator_img"
          className={styles.creator_img}
        />
        <div>
          <p className={styles.text_primary}>
            {event?.event_type === "Experience" && days > 1
              ? `${days} Days ${nights} Nights`
              : "1 Day"}
          </p>
          <p className={styles.text_primary}>@{event?.host?.name}</p>
          <p className={styles.text_secondary} style={{ marginTop: -2 }}>
            in {event?.city}
          </p>
          <p className={styles.text_large}>{event?.title}</p>
        </div>
      </div>
    </div>
  );
}
